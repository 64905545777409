export function normalize(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function isEmail(str) {
  return /\S+@\S+\.\S+/.test(str);
}

export function upper(value) {
  if (!value) return '';
  return value.toUpperCase();
}

export function lower(value) {
  if (!value) return '';
  return value.toLowerCase();
}

export function capitalize(value) {
  if (!value) return '';
  value = value.toString().trimStart();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function initialize(value) {
  if (!value) return '';
  value = value.toString().replace(/([^a-zA-Z0-9 ]+)/g, '');
  const parts = value.split(' ');
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase();
  }
  return parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase();
}
export function shorten(value, start = 0, length = 8, ending = '') {
  ending = value.toString().length <= length ? '' : ending;
  return value.toString().substr(start, length) + ending;
}

export function partialEmphasis(haystack, needle) {
  const normalizedHaystack = normalize(haystack).toLocaleLowerCase();
  const normalizedNeedle = normalize(needle).toLocaleLowerCase();

  const indices = [];

  if (normalizedNeedle.length !== 0) {
    let startIndex = 0;
    let index;

    while ((index = normalizedHaystack.indexOf(normalizedNeedle, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + normalizedNeedle.length;
    }
  }

  for (const indice of indices.reverse()) {
    const substr = haystack.substring(indice, indice + needle.length);

    haystack = haystack.substring(0, indice) + substr.bold() + haystack.substring(indice + needle.length);
  }

  return haystack;
}

export function containsString(needle, haystack) {
  return normalize(haystack).toLocaleLowerCase().includes(normalize(needle).toLocaleLowerCase());
}

export function uuid(value) {
  const number = !value || isNaN(Number(value))
    ? Math.random()
    : value;

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = number * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export async function copyToClipboard(text) {
  await navigator.clipboard.writeText(text);
  document.querySelectorAll('.copy-content').forEach((elem) => (elem.textContent = 'Copié'));
}

export default {
  normalize,
  isEmail,
  upper,
  lower,
  capitalize,
  initialize,
  shorten,
  containsString,
  partialEmphasis,
  uuid,
  copyToClipboard,
};
