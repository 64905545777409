export default function(axios, merchantId) {
  return {
    list() {
      return axios.get(`/merchants/${merchantId}/clients`)
        .then((response) => response.data);
    },
    post(clientData) {
      return axios.post(`/merchants/${merchantId}/clients`, clientData)
        .then((response) => response.data);
    },
    delete(clientId) {
      return axios.delete(`/merchants/${merchantId}/clients/${clientId}`)
        .then((response) => response.data);
    },
  };
}
