import type { PaginationParams } from '@/types/models';

export default function(axios, userId) {
  return {
    list: (params: PaginationParams) => {
      return axios.get(`/users/${userId}/events`, { params })
        .then((response) => response.data);
    },
    listMerchants: (eventId) => {
      return axios.get(`/users/${userId}/events/${eventId}/merchants`)
        .then((response) => response.data);
    },
  };
}
