export default function(axios, clientId) {
  return {
    listPermissions() {
      return axios.get(`/clients/${clientId}/users/permissions`)
        .then((response) => response.data);
    },
    link(userData) {
      return axios.post(`/clients/${clientId}/users`, userData)
        .then((response) => response.data);
    },
    deletePermission(permissionId) {
      return axios.delete(`/clients/${clientId}/users/permissions/${permissionId}`)
        .then((response) => response.data);
    },
  };
}
