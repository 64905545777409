import itemModelsApi from './item-models';
import illustrationsApi from './illustrations';
import composedItemsApi from './composed-items';

export default function(axios) {
  return {
    import: (merchantId, itemsData) => {
      return axios.post(`/merchants/${merchantId}/catalog/import`, itemsData)
        .then((response) => response.data);
    },
    itemModels: itemModelsApi(axios),
    illustrations: illustrationsApi(axios),
    composedItems: composedItemsApi(axios),
  };
}
