import Vue from 'vue';

export default {
  SET_TIMESPAN(state, payload) {
    Vue.set(state, 'timespan', payload);
  },

  RESET_DATA(state) {
    Vue.set(state, 'timespan', {
      min: 0,
      max: 0,
      chartId: undefined,
    });
  },
};
