import modelsApi from './models';
import transactionsApi from './transactions';
import terminalAffectationsApi from './terminal-affectations';
import configApi from './config';

export default function(axios, eventId) {
  return {
    list: () => {
      return axios.get(`/events/${eventId}/pos?limit=-1`)
        .then((response) => response.data);
    },
    get: (posId) => {
      return axios.get(`/events/${eventId}/pos/${posId}`)
        .then((response) => response.data);
    },
    history: (posId) => {
      return axios.get(`/events/${eventId}/pos/${posId}/history`)
        .then((response) => response.data);
    },
    post: (posData) => {
      return axios.post(`/events/${eventId}/pos`, posData)
        .then((response) => response.data);
    },
    duplicate: (posData) => {
      return axios.post(`/events/${eventId}/pos/duplicate`, posData)
        .then((response) => response.data);
    },
    duplicateOne: (posId, posData) => {
      return axios.post(`/events/${eventId}/pos/${posId}/duplicate`, posData)
        .then((response) => response.data);
    },
    createPosItems: (posId, data) => {
      return axios.post(`/events/${eventId}/pos/${posId}/pos-items`, data)
        .then((response) => response.data);
    },
    deletePosItems: (posId, data) => {
      return axios.delete(`/events/${eventId}/pos/${posId}/pos-items`, { data })
        .then((response) => response.data);
    },
    linkComposedItems: (posId, data) => {
      return axios.post(`/events/${eventId}/pos/${posId}/composed-items`, data)
        .then((response) => response.data);
    },
    unlinkComposedItems: (posId, data) => {
      return axios.delete(`/events/${eventId}/pos/${posId}/composed-items`, { data })
        .then((response) => response.data);
    },
    listPosItems(posId) {
      return axios.get(`/events/${eventId}/pos/${posId}/pos-items`)
        .then((response) => response.data);
    },
    showPosItem(posId, itemId) {
      return axios.get(`/events/${eventId}/pos/${posId}/pos-items/${itemId}`)
        .then((response) => response.data);
    },
    editPosItem(posId, itemId, itemData) {
      return axios.put(`/events/${eventId}/pos/${posId}/pos-items/${itemId}`, itemData)
        .then((response) => response.data);
    },
    put: (posId, posData) => {
      return axios.put(`/events/${eventId}/pos/${posId}`, posData)
        .then((response) => response.data);
    },
    delete: (posId) => {
      return axios.delete(`/events/${eventId}/pos/${posId}`)
        .then((response) => response.data);
    },
    modelsOf(posId) {
      return modelsApi(axios, eventId, posId);
    },
    transactionsOf(posId) {
      return transactionsApi(axios, eventId, posId);
    },
    terminalAffectationsOf(posId) {
      return terminalAffectationsApi(axios, eventId, posId);
    },
    configOf(posId) {
      return configApi(axios, eventId, posId);
    },
  };
}
