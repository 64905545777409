<template>
  <v-chip
    :key="keyText"
    :class="classes"
    :color="`${color} lighten-1`"
    outlined
    label
    x-small
    autofocus
  >
    <b :class="`${color}--text darken-4`">{{ keyText }}</b>
  </v-chip>
</template>

<script>
export default {
  name: 'Key',
  props: {
    keyText: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'grey',
    },
    pressed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'spot-key': true,
        pressed: this.pressed,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.spot-key {
  box-shadow: 0 1.25px 0 currentColor;
  line-height: 1;
  padding: 0 8px;
  height: 20px;
  margin-right: 0.25rem;
  transition: all 0.12s ease-out;
  &.pressed {
    box-shadow: 0 -1.25px 0 currentColor;
    background-color: white;
    margin-top: 2px
  }
}
</style>
