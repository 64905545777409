import { computed } from 'vue';
import store from '@/vuex/store';

export default function useAccessControl() {
  const isAuthenticated = computed(() => store.getters['authModule/authenticated']);
  const hasAdminSession = computed(() => store.getters['authModule/hasAdminSession']);
  const isMerchant = computed(() => (store.getters['authModule/roles'] || []).includes('ROLE_MERCHANT'));

  const isClient = computed(() => (store.getters['authModule/roles'] || []).includes('ROLE_CLIENT'));
  const isFleetManager = computed(() => (store.getters['authModule/roles'] || []).includes('ROLE_FLEET_MANAGER'));
  const isDataViewer = computed(() => (store.getters['authModule/roles'] || []).includes('ROLE_DATA_VIEWER'));

  function isAdminGuard(to, from, next) {
    if (store.getters['authModule/roles'].includes('ROLE_SUPER_ADMIN')) {
      return next();
    }
    next(from);
  }

  function isMerchantGuard(to, from, next) {
    if (
      store.getters['authModule/roles'].includes('ROLE_MERCHANT') ||
      store.getters['authModule/roles'].includes('ROLE_SUPER_ADMIN')
    ) {
      return next();
    }
    next(from);
  }

  function isClientGuard(to, from, next) {
    if (store.getters['authModule/roles'].includes('ROLE_CLIENT')) {
      return next();
    }
    next(from);
  }

  function isFleetManagerGuard(to, from, next) {
    if (store.getters['authModule/roles'].includes('ROLE_FLEET_MANAGER')) {
      return next();
    }
    next(from);
  }

  function isDataViewerGuard(to, from, next) {
    if (store.getters['authModule/roles'].includes('ROLE_DATA_VIEWER')) {
      return next();
    }
    next(from);
  }

  function impersonateIfAdmin(to, from, next) {
    try {
      if (
        (store.getters['authModule/roles'] || []).includes('ROLE_SUPER_ADMIN') &&
        from.name !== 'event-impersonate' &&
        to.name !== 'event-impersonate'
      ) {
        return next({
          name: 'event-impersonate',
          params: {
            code: to.params.code,
            edition: to.params.edition,
          },
        });
      } else next();
    } catch (e) {
      console.error(e);
    }
  }

  function eventHasFeatureFlag(flag) {
    const configNames = store.getters.getCurrentEventConfigs?.map((c) => c.name) ?? [];
    return configNames.includes(flag);
  }

  return {
    isAuthenticated,
    hasAdminSession,
    isMerchant,
    isClient,
    isFleetManager,
    isDataViewer,
    isAdminGuard,
    isMerchantGuard,
    isClientGuard,
    isFleetManagerGuard,
    isDataViewerGuard,
    impersonateIfAdmin,
    eventHasFeatureFlag,
  };
}
