export default function(axios, eventId) {
  return {
    list: () => {
      return axios.get(`/cashless/event/${eventId}/invoices`)
        .then((response) => response.data);
    },
    generate: (invoiceData) => {
      return axios.post(`/cashless/event/${eventId}/invoices`, invoiceData, { responseType: 'blob' })
        .then((response) => response.data);
    },
    regenerate: (invoiceId) => {
      return axios.put(`/cashless/event/${eventId}/invoices/${invoiceId}`, null, { responseType: 'blob' })
        .then((response) => response.data);
    },
  };
}
