import type { PaginationParams } from '@/types/models';
import serviceAccountApi from './events/service-accounts';
import itemsApi from './events/items';
import merchantsApi from './events/merchants';
import stocksApi from './events/stock';
import posApi from './events/pos';
import statsApi from './events/stats';
import transactionsApi from './events/transactions';
import terminalAffectationsApi from './events/terminal-affectations';
import configApi from '@/api/endpoints/events/config';
import refundsApi from './events/refunds';
import ccApi from './events/click-and-collect';

export default function(axios) {
  return {
    list: (params: PaginationParams) => {
      return axios.get('/events', { params })
        .then((response) => response.data);
    },
    refs: (params: PaginationParams) => {
      return axios.get('/events/references', { params })
        .then((response) => response.data);
    },
    get: (eventId) => {
      return axios.get(`/events/${eventId}`)
        .then((response) => response.data);
    },
    getBySlug: (code: string, edition: string) => {
      return axios.get(`/events/by-slug/${code}/${edition}`)
        .then((response) => response.data);
    },
    getItemModels: (eventId, offset = 0, limit = -1) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('Items offset & limit parameters should be integers.'));
      }

      return axios.get(`/events/${eventId}/item-models`, {
        params: {
          offset,
          limit: limit || -1,
        },
      }).then((response) => response.data);
    },
    getItemModel: (eventId, itemId) => {
      return axios.get(`/events/${eventId}/item-models/${itemId}`)
        .then((response) => response.data);
    },
    getItemModelSales: (eventId, itemModelId) => {
      return axios.get(`/events/${eventId}/item-models/${itemModelId}/sales`)
        .then((response) => response.data);
    },
    put: (eventId, eventData) => {
      return axios.put(`/events/${eventId}`, eventData)
        .then((response) => response.data);
    },
    putDatesAndName: (eventId, eventData) => {
      return axios.put(`/events/${eventId}/dates-name`, eventData)
        .then((response) => response.data);
    },
    post: (eventData) => {
      return axios.post('/events', eventData)
        .then((response) => response.data);
    },
    duplicate: (duplicatedEventId, eventData) => {
      return axios.post(`/events/${duplicatedEventId}/duplicate`, eventData)
        .then((response) => response.data);
    },
    serviceAccountsOf(eventId) {
      return serviceAccountApi(axios, eventId);
    },
    itemsOf(eventId) {
      return itemsApi(axios, eventId);
    },
    merchantsOf(eventId) {
      return merchantsApi(axios, eventId);
    },
    stocksOf(eventId) {
      return stocksApi(axios, eventId);
    },
    posOf(eventId) {
      return posApi(axios, eventId);
    },
    statsOf(eventId) {
      return statsApi(axios, eventId);
    },
    transactionsOf(eventId) {
      return transactionsApi(axios, eventId);
    },
    terminalAffectationsOf(eventId) {
      return terminalAffectationsApi(axios, eventId);
    },
    configOf(eventId) {
      return configApi(axios, eventId);
    },
    refundsOf() {
      return refundsApi(axios);
    },
    history: (eventId, offset = 0, limit = -1, sort, filters) => {
      return axios.get(`/events/${eventId}/history`, {
        params: {
          offset,
          limit,
          sortBy: sort,
          filters,
        },
      })
        .then((response) => response.data);
    },
    getCreationParams() {
      return axios.get('/events/creation-params')
        .then((response) => response.data);
    },
    clickAndCollectOf(eventId) {
      return ccApi(axios, eventId);
    },
  };
}
