<template>
  <VRow :class="{'pl-1': !isMobile, 'pl-4': isMobile}">
    <VAutocomplete
      v-model="currentEvent"
      class="elevation-0 mr-2 toolbar-selector col-sm-6 col-md-4"
      :loading="eventsList.loading || adminEventsList.loading"
      hide-details
      :label="capitalize(t('form.event.self', 1))"
      :items="eventsListWithCurrent"
      :no-data-text="t('general.select_event')"
      :error-messages="eventsList.errors"
      return-object
      :search-input.sync="searchEvent"
      item-text="name"
      item-value="id"
      no-filter
      outlined
      dense
    >
      <template v-for="slotName in ['selection', 'item']" #[slotName]="{item}">
        <template v-if="item.header">
          <VListItemContent :key="`avatar_${slotName}`">
            <span>{{ item.header }}</span>
          </VListItemContent>
        </template>
        <template v-else>
          <VListItemAvatar :key="`avatar_${slotName}`" :class="{'my-0': slotName === 'selection', 'mr-2': true}">
            <VAvatar :color="getColor(item.id)" size="24px">
              <span class="caption">{{ initialize(item.name) }}</span>
            </VAvatar>
          </VListItemAvatar>
          <VListItemContent :key="`content_${slotName}`" :class="{'py-0': slotName === 'selection'}">
            <VListItemTitle class="subtitle-2">
              {{ item.name }}
            </VListItemTitle>
            <VListItemSubtitle v-if="slotName === 'item'" class="grey--text lighten-4 font-weight-regular">
              {{ item.client.name }}
            </VListItemSubtitle>
          </VListItemContent>
        </template>
      </template>
    </VAutocomplete>
    <VDivider v-if="selectMerchant" vertical />
    <VAutocomplete
      v-if="selectMerchant"
      v-model="merchant"
      :loading="merchantsList.loading"
      class="merchant-selector toolbar-selector ml-2 col-sm-6 col-md-4"
      :disabled="merchantsList.loading"
      hide-details
      :label="capitalize(t('form.merchant.self', 1))"
      :items="merchantsListWithCurrent"
      no-data-text="Select a merchant ..."
      return-object
      persistent-placeholder
      item-text="name"
      item-value="id"
      :error="merchantsErrors"
      outlined
      dense
    >
      <template #no-data>
        <VListItem>
          <VListItemContent>
            <VListItemTitle class="subtitle-2 error--text">
              Le chargement de la liste a échoué
            </VListItemTitle>
          </VListItemContent>
          <VListItemAction>
            <VBtn
              icon
              outlined
              x-small
              @click="fetchMerchants"
            >
              <VIcon>mdi-sync</VIcon>
            </VBtn>
          </VListItemAction>
        </VListItem>
      </template>

      <template v-for="slotName in ['selection', 'item']" #[slotName]="data">
        <VListItemAvatar :key="`avatar_${slotName}`" :class="{'my-0': slotName === 'selection', 'mr-2': true}">
          <VAvatar :color="getColor(data.item.id)" size="24px">
            <VIcon color="white" small>
              izp-merchant
            </VIcon>
          </VAvatar>
        </VListItemAvatar>
        <VListItemContent :key="`content_${slotName}`" :class="{'py-0': slotName === 'selection'}">
          <VListItemTitle class="subtitle-2">
            {{ data.item.name }}
          </VListItemTitle>
          <VListItemSubtitle v-if="slotName === 'item'" class="grey--text lighten-4 font-weight-regular">
            {{ data.item.organization.vat_number }}
          </VListItemSubtitle>
        </VListItemContent>
      </template>
    </VAutocomplete>
  </VRow>
</template>

<script lang="ts" setup>
import { computed, ref, unref, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { capitalize, initialize } from '@/util/Strings';
import { useI18n } from 'vue-i18n-bridge';
import useColor from '@/composables/colors';
import useScreen from '@/composables/useScreen';
import useMerchantStore from '@/stores/merchantStore';
import useEventStore from '@/stores/eventStore';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router/composables';
import vuexStore from '@/vuex/store';
import AccessControl from '@/security/AccessControl';
import useSecurityStore from '@/stores/securityStore';
import _head from 'lodash/head';
import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';

interface Props {
  code: string,
  edition: string,
  merchantId?: string|number|null,
}

const props = withDefaults(defineProps<Props>(), {
  merchantId: null,
});

const route = useRoute();
const router = useRouter();

const selectMerchant = ref(false);
const merchantStore = useMerchantStore();
const { getColor } = useColor();

const { isMobile } = useScreen();
const { merchantsList, merchant } = storeToRefs(merchantStore);
const { fetchListForEvent: fetchMerchants, setCurrentMerchant } = merchantStore;

const eventStore = useEventStore();
const { event, eventsList, adminEventsList, search: searchEvent } = storeToRefs(eventStore);
const { fetchList: fetchEvents, setCurrentEvent } = eventStore;
const { impersonateForEvent } = useSecurityStore();
const { t } = useI18n();

const debouncedEventFetch = _debounce(fetchEvents, 500);

const currentUser = computed(() => vuexStore.getters['authModule/user']);

const merchantsErrors = computed<boolean>(() => merchantsList.value.errors ? merchantsList.value.errors.length > 0 : false);

const currentEvent = computed({
  get: () => event.value,
  set: async(v) => {
    if (!v) {
      await setCurrentEvent('', '');
      return;
    }

    await impersonateForEvent(unref(v));
  },
});

// Ensure currentEvent is in autocomplete list
const eventsListWithCurrent = computed(
  () => _uniqBy([...eventsList.value.list, ...([event.value] ?? [])], 'id'),
);

// Ensure currentMerchant is in autocomplete list
const merchantsListWithCurrent = computed(
  () => _uniqBy([...merchantsList.value.list, ...([merchant.value] ?? [])], 'id'),
);

watch(currentUser, (newVal, oldVal) => {
  if (!newVal || newVal?.id === oldVal?.id) {
    return;
  }

  setCurrentMerchant(null);
  fetchEvents();
});

watch(route, (val, oldVal) => {
  if (val.params.code !== currentEvent.value?.code || val.params.edition !== currentEvent.value?.edition) {
    setCurrentEvent(val.params.code, val.params.edition);
  }

  selectMerchant.value = val.meta?.selectMerchant === true;
  if (val.name === 'event-stock' && merchant.value?.id) {
    router.replace({
      name: 'stock-list',
      params: { merchantId: merchant.value.id.toString() },
    });
  }

  if (!oldVal && props.merchantId && (!merchant.value || merchant.value.id !== props.merchantId)) {
    setCurrentMerchant(props.merchantId);
  }
}, { deep: true, immediate: true });

watch([selectMerchant, currentEvent], async([shouldSelect, event]) => {
  if (!shouldSelect || !event) {
    return;
  }

  await fetchMerchants(event);
  if (merchantsList.value.list.length === 0) {
    return;
  }

  if (merchant.value === null) {
    const firstMerchantId = _head(merchantsList.value.list)?.id;
    await setCurrentMerchant(firstMerchantId);
  }
});

watch(merchant, async(newVal, oldVal) => {
  await vuexStore.dispatch('preferencesModule/setAppBarExtensionSettings', { tabsVisible: selectMerchant.value });

  if (newVal && merchantsList.value.errors) {
    merchantsList.value.errors = null;
  }

  if (selectMerchant.value && newVal && newVal.id !== oldVal?.id) {
    router.replace({
      name: 'stock-list',
      params: { merchantId: newVal.id.toString() },
    })
      .catch((_) => {
        // silenced.
      });
  }
}, { immediate: true });

watch(searchEvent, () => debouncedEventFetch());

watch(merchantsList, (value) => {
  if (route.name !== 'stock-list') return;

  const propsInList = value.list.some((l) => l.id.toString() === props.merchantId);

  if (!propsInList && value.list.length > 0) {
    setCurrentMerchant(value.list[0].id);
  }
});

onMounted(() => fetchEvents());

onBeforeRouteUpdate((to, from, next) => AccessControl.impersonateIfAdmin(to, from, next));

</script>

<style scoped>
  .toolbar-selector {
    min-width: 25vw;
    max-width: 32vw;
  }
</style>
