import { prepareAbortableRequest } from '@/api/cancellation';
import { hash } from '@/api/helpers/hash';
import userEventsApi from './events';
import userMerchantsApi from './merchants';

export default function(axios) {
  return {
    list: () => {
      return axios.get('/users')
        .then((response) => response.data);
    },
    get: (userId) => {
      return axios.get(`/users/${userId}`, {
        bypassLock: true,
      })
        .then((response) => response.data);
    },
    getProfile: (userId) => {
      return axios.get(`/users/${userId}/profile`)
        .then((response) => response.data);
    },
    getPermissions: (userId) => {
      return axios.get(`/users/${userId}/permissions`)
        .then((response) => response.data);
    },
    me: () => {
      return axios.get('/users/me')
        .then((response) => response.data);
    },
    history: () => {
      return axios.get('/users/me/history')
        .then((response) => response.data);
    },
    liveEvents: (duration, search) => {
      const requestHash = hash('get_live_events').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get('/users/me/events/live', {
        signal,
        params: {
          duration,
          search,
        },
      })
        .then((response) => response.data);
    },
    omnibar: (search, offset = 0, limit = 5) => {
      return axios.get('/users/me/omnibar', {
        params: {
          search,
          offset,
          limit,
        },
      })
        .then((response) => response.data);
    },
    liveActivity: (startDate, endDate, events) => {
      const requestHash = hash('get_live_activity').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get('/users/me/events/live/activity', {
        signal,
        params: {
          startDate,
          endDate,
          events,
        },
      })
        .then((response) => response.data);
    },
    post: (userData) => {
      return axios.post('/users', userData)
        .then((response) => response.data);
    },
    put: (userId, userData) => {
      return axios.put(`/users/${userId}`, userData)
        .then((response) => response.data);
    },
    putProfile: (userId, userData) => {
      return axios.put(`/users/${userId}/profile`, userData)
        .then((response) => response.data);
    },
    createPermission: (userId, permissionData) => {
      return axios.post(`/admin/users/${userId}/permissions`, permissionData)
        .then((response) => response.data);
    },
    editPermission: (userId, permissionId, permissionData) => {
      return axios.put(`/admin/users/${userId}/permissions/${permissionId}`, permissionData)
        .then((response) => response.data);
    },
    deletePermission: (userId, permissionId) => {
      return axios.delete(`/admin/users/${userId}/permissions/${permissionId}`)
        .then((response) => response.data);
    },
    /** @deprecated */
    putPermissions: (userId, userData) => {
      return axios.put(`/users/${userId}/permissions`, userData)
        .then((response) => response.data);
    },
    getConfirmationToken: (userId) => {
      return axios.get(`/users/${userId}/confirmation-token`)
        .then((response) => response.data);
    },
    createConfirmationToken: (userId) => {
      return axios.post(`/users/${userId}/confirmation-token`)
        .then((response) => response.data);
    },
    emailConfirmationToken: (userId) => {
      return axios.post(`/users/${userId}/email-confirmation-token`)
        .then((response) => response.data);
    },
    enable: (userId) => {
      return axios.post(`/users/${userId}/enable`)
        .then((response) => response.data);
    },
    disable: (userId) => {
      return axios.post(`/users/${userId}/disable`)
        .then((response) => response.data);
    },
    forEvent: (eventId, permissionType = 'client') => {
      return axios.get(`/users/for-event/${eventId}/${permissionType}`)
        .then((response) => response.data);
    },
    merchantsOf: (userId) => {
      return userMerchantsApi(axios, userId);
    },
    organizers: (userId) => {
      return axios.get(`/users/${userId}/organizers`)
        .then((response) => response.data);
    },
    eventsOf: (userId) => {
      return userEventsApi(axios, userId);
    },
    fleets: (userId) => axios.get(`/users/${userId}/fleets`)
      .then((response) => response.data),
  };
}
