export default function(axios, eventId) {
  return {
    list: (offset = 0, limit = -1, sort = 'ASC', filters = []) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(new Error('Transactions offset & limit parameters should be integers.'));
      }
      return axios.get(`/events/${eventId}/terminal-affectations`, {
        params: {
          offset,
          limit,
          sortBy: sort,
          filters,
        },
      })
        .then((response) => response.data);
    },
    get: (terminalAffectationsId) => {
      return axios.get(`/events/${eventId}/terminal-affectations/${terminalAffectationsId}`)
        .then((response) => response.data);
    },
    post: (taData) => {
      return axios.post(`/events/${eventId}/terminal-affectations`, taData)
        .then((response) => response.data);
    },
    put: (terminalAffectationsId, taData) => {
      return axios.put(`/events/${eventId}/terminal-affectations/${terminalAffectationsId}`, taData)
        .then((response) => response.data);
    },
    move: (moveForm) => {
      return axios.put(`/events/${eventId}/terminal-affectations`, moveForm)
        .then((response) => response.data);
    },
    delete: (terminalAffectationId) => {
      return axios.delete(`/events/${eventId}/terminal-affectations/${terminalAffectationId}`)
        .then((response) => response.data);
    },
    alias: (terminalAffectationId, aliasData) => {
      return axios.put(`/events/${eventId}/terminal-affectations/${terminalAffectationId}/alias`, aliasData)
        .then((response) => response.data);
    },
    deleteAlias: (terminalAffectationId) => {
      return axios.delete(`/events/${eventId}/terminal-affectations/${terminalAffectationId}/alias`)
        .then((response) => response.data);
    },
  };
}
