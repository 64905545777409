export default {
  isMobile: (state) => state.isMobile,
  isMenuPinned: (state) => state.menu.pinned,
  isMenuVisible: (state) => state.menu.visible,
  extensionHasLeftCol: (state) => state.appBarExtensionLeftCol,
  isPosPaymentMethodSettingsAlertOpened: (state) => state.isPosPaymentMethodSettingsAlertOpened,
  getTablePropertyById: (state) => (id, property) => {
    const table = state.tables[id];

    if (table === null || table === undefined) {
      return null;
    }

    const prop = table[property];

    if (prop === null || prop === undefined) {
      return null;
    }

    return prop;
  },
  getRecentActivityDuration: (state) => state.recentActivity.duration,
  getRecentActivityDurationChoices: (state) => state.recentActivity.durationChoices,
};
