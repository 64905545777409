import _get from 'lodash/get';
import _findKey from 'lodash/findKey';

const PAYMENT_METHODS = 0x00;
const TRANSACTION_STATUS = 0x01;
const PAYMENT_STATUS = 0x02;

const DATA_MAPS = {
  [PAYMENT_METHODS]: {
    0x00: {
      label: 'form.transaction.choices.payment_method.test',
      icon: 'simple',
      payment_type: 'test',
    },
    0x01: {
      label: 'form.transaction.choices.payment_method.emv',
      icon: 'cb',
      payment_type: 'emv',
    },
    0x02: {
      label: 'form.transaction.choices.payment_method.izly',
      icon: 'izly',
      payment_type: 'izly',
    },
    0x03: {
      label: 'form.transaction.choices.payment_method.lyfpay',
      icon: 'lyfpay',
      payment_type: 'lyfpay',
    },
    0x04: {
      label: 'form.transaction.choices.payment_method.izypay',
      icon: 'contactless-card',
      payment_type: 'money_wallet',
    },
    0x05: {
      label: 'form.transaction.choices.payment_method.cash',
      icon: 'cash-2',
      payment_type: 'cash',
    },
    0x06: {
      label: 'form.transaction.choices.payment_method.transfer',
      icon: 'transfer',
      payment_type: 'transfer',
    },
    0x07: {
      label: 'form.transaction.choices.payment_method.local_cashless',
      icon: 'local-cashless',
      payment_type: 'local_cashless',
    },
    0x08: {
      label: 'form.transaction.choices.payment_method.stripe',
      icon: 'stripe',
      payment_type: 'stripe',
    },
    0x09: {
      label: 'form.transaction.choices.payment_method.payplug',
      icon: 'payplug',
      payment_type: 'payplug',
    },
  },
  [TRANSACTION_STATUS]: {
    0x01: {
      label: 'form.transaction.choices.status.pending',
      icon: 'mdi-timer-sand',
      color: 'primary',
    },
    0x02: {
      label: 'form.transaction.choices.status.canceled',
      icon: 'mdi-close-circle',
      color: 'grey',
    },
    0x03: {
      label: 'form.transaction.choices.status.validated',
      icon: 'mdi-checkbox-marked-circle-outline',
      color: 'success',
    },
  },
  [PAYMENT_STATUS]: {
    0x01: {
      label: 'form.payment.choices.status.pending',
      icon: 'mdi-timer-sand',
      color: 'primary',
    },
    0x02: {
      label: 'form.payment.choices.status.canceled',
      icon: 'mdi-close-circle',
      color: 'grey',
    },
    0x03: {
      label: 'form.payment.choices.status.declined',
      icon: 'mdi-close-circle',
      color: 'error',
    },
    0x04: {
      label: 'form.payment.choices.status.approved',
      icon: 'mdi-checkbox-marked-circle-outline',
      color: 'success',
    },
  },
};

export default {
  paymentMethodLabel(value) {
    return _get(DATA_MAPS, [PAYMENT_METHODS, value, 'label'], '?');
  },
  paymentMethodIcon(value) {
    return _get(DATA_MAPS, [PAYMENT_METHODS, value, 'icon'], '');
  },
  paymentMethodType(value) {
    return _get(DATA_MAPS, [PAYMENT_METHODS, value, 'payment_type'], '');
  },
  paymentCodeFromType(type) {
    return _findKey(DATA_MAPS[PAYMENT_METHODS], (p) => p.payment_type === type);
  },
  transactionStatusLabel(value) {
    return _get(DATA_MAPS, [TRANSACTION_STATUS, value, 'label'], '?');
  },
  transactionStatusIcon(value) {
    return _get(DATA_MAPS, [TRANSACTION_STATUS, value, 'icon'], '');
  },
  transactionStatusColor(value) {
    return _get(DATA_MAPS, [TRANSACTION_STATUS, value, 'color'], 'grey');
  },
  paymentStatusLabel(value) {
    return _get(DATA_MAPS, [PAYMENT_STATUS, value, 'label'], '?');
  },
  paymentStatusIcon(value) {
    return _get(DATA_MAPS, [PAYMENT_STATUS, value, 'icon'], '');
  },
  paymentStatusColor(value) {
    return _get(DATA_MAPS, [PAYMENT_STATUS, value, 'color'], 'grey');
  },
};
