<!-- Init template -->
<template>
  <v-app :class="{'simple-layout': isSimpleLayout()}" light>
    <router-view />
    <izp-message-bus />
    <ConfirmDialog />
    <SessionDebugger v-if="isDevelopment" />
  </v-app>
</template>
<script>
import MessageBus from '@/components/layout/MessageBus.vue';
import { mapActions, mapGetters } from 'vuex';
import globalMixins from '@/mixins';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import IzpSimpleLayout from '@/components/layout/IzpSimpleLayout.vue';
import SessionDebugger from '@/components/tools/SessionDebugger.vue';

// TODO: import loading operations from custom file.
export default {
  name: 'App',
  components: {
    SessionDebugger,
    ConfirmDialog,
    'izp-message-bus': MessageBus,
  },
  mixins: [globalMixins],
  data: () => ({
    locale: 'fr',
    flag: 'fr',
    FLAG_MAP: {
      fr: 'fr',
      en: 'gb',
    },
  }),
  computed: {
    isDevelopment() {
      return import.meta.env.NODE_ENV === 'development' && import.meta.env.VITE_SESSION_DEBUGGER === 'true';
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile, { passive: true });
    window.dispatchEvent(new Event('resize'));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    ...mapActions('preferencesModule', ['updateMobileState']),
    ...mapGetters(['getCurrentEvent', 'authModule']),
    isSimpleLayout() {
      return this.$route.meta.layout === IzpSimpleLayout;
    },
    doLogout() {
      this.logout().then(() => this.$router.push({ name: 'auth-login' }));
    },
    localeSelected(locale) {
      this.langSwitcher(locale);
      this.flag = this.FLAG_MAP[locale];
    },
    checkMobile(e) {
      this.updateMobileState({ screenWidth: e.target.innerWidth });
    },
  },
};
</script>

<style lang="scss" scoped>
  .simple-layout{
    background-color: transparent !important;
    :deep(.simple-layout-brand){
      flex: 0 1 auto;
    }
  }
</style>
