import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Vue from 'vue';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

Vue.use(PiniaVuePlugin);

export default pinia;
