const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const AdminBatchIndex = () => import('@/views/admin/batch/BatchIndex.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'batches',
    name: 'admin-batches',
    props: {
      default: true,
      'admin-menu': false,
    },
    components: {
      default: AdminBatchIndex,
      'admin-menu': AdminMenu,
    },
    meta: {
      h2: 'form.user.self.2',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
  },
];
