export default function(axios, eventId) {
  return {
    listPos: () => {
      return axios.get(`/events/${eventId}/click-and-collect/pos`)
        .then((response) => response.data);
    },
    getPos: (posId) => {
      return axios.get(`/events/${eventId}/click-and-collect/pos/${posId}`)
        .then((response) => response.data);
    },
    createPos: (data) => {
      return axios.post(`/events/${eventId}/click-and-collect/pos`, data)
        .then((response) => response.data);
    },
    duplicatePos: (posId, data) => {
      return axios.post(`/events/${eventId}/click-and-collect/pos/${posId}/duplicate`, data)
        .then((response) => response.data);
    },
    editPos: (posId, data) => {
      return axios.put(`/events/${eventId}/click-and-collect/pos/${posId}`, data)
        .then((response) => response.data);
    },
    deletePos: (posId) => {
      return axios.delete(`/events/${eventId}/click-and-collect/pos/${posId}`)
        .then((response) => response.data);
    },
    createSession: (posId, data) => {
      return axios.post(`/events/${eventId}/click-and-collect/pos/${posId}/session`, data)
        .then((response) => response.data);
    },
    editSession: (posId, sessionId, data) => {
      return axios.put(`/events/${eventId}/click-and-collect/pos/${posId}/session/${sessionId}`, data)
        .then((response) => response.data);
    },
    deleteSession: (posId, sessionId) => {
      return axios.delete(`/events/${eventId}/click-and-collect/pos/${posId}/session/${sessionId}`)
        .then((response) => response.data);
    },
  };
}
