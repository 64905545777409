import { ref, onMounted, onUnmounted, computed } from 'vue';

export default function useScreen() {
// state encapsulated and managed by the composable
  const screenWidth = ref<number>(0);
  const isMobile = computed<boolean>(() => screenWidth.value < 600);
  const isDesktop = computed<boolean>(() => screenWidth.value > 960);
  const smAndDown = computed<boolean>(() => screenWidth.value <= 960);
  const smAndUp = computed<boolean>(() => screenWidth.value >= 960);

  function checkMobile(e) {
    screenWidth.value = e.target.innerWidth;
  }

  onMounted(() => {
    window.addEventListener('resize', checkMobile, { passive: true });
    window.dispatchEvent(new Event('resize'));
  });

  onUnmounted(() => window.removeEventListener('resize', checkMobile));

  // expose managed state as return value
  return {
    screenWidth,
    isMobile,
    isDesktop,
    smAndDown,
    smAndUp,
  };
}
