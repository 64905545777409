export default class Lock {
  constructor() {
    this._resolve = null;
    this._process = new Promise((resolve) => {
      this._resolve = resolve;
    });
  }

  wait() {
    return this._process;
  }

  unlock() {
    this._resolve();
  }
}
