// const UserIndex = () => import('@/views/admin/user/UserIndex.vue');
const UsersNav = () => import('@/views/admin/user/UsersNav.vue');
const RolesList = () => import('@/views/admin/user/RolesList.vue');
const AdminMenu = () => import('@/components/menu/AdminMenu.vue');
const UserList = () => import('@/views/admin/user/UserList.vue');
const UserView = () => import('@/views/admin/user/UserView.vue');

export default [
  {
    path: 'users',
    name: 'users',
    components: {
      default: UsersNav,
      'admin-menu': AdminMenu,
    },
    redirect: { name: 'users-list' },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'roles',
        name: 'roles-list',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: RolesList,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':userId',
        name: 'user-view',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: UserView,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '',
        name: 'users-list',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: UserList,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
