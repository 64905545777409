const DialogSchema = {
  title: 'Alert',
  message: null,
  tone: 'info',
  callback: () => {},
};

const MessageSchema = {
  message: null,
  tone: 'info',
};

export default {
  PUSH_MESSAGE(state, { message, tone }) {
    const m = { ...MessageSchema };
    m.message = message;
    m.tone = tone;
    state.stack = [
      ...state.stack,
      m,
    ];
  },

  PUSH_DIALOG(state, { title, message, tone, callback }) {
    const d = { ...DialogSchema };
    d.title = title;
    d.message = message;
    d.tone = tone;
    d.callback = typeof callback === 'function' ? callback : d.callback;
    state.dialog = d;
  },

  REMOVE_HEAD(state) {
    if (state.stack.length === 0) {
      return null;
    }

    state.stack = [
      ...state.stack.slice(1),
    ];

    return state.stack[0];
  },

  CLEAR_DIALOG(state) {
    state.dialog = null;
  },

  CLEAR_ALL(state) {
    state.stack = [];
    state.dialog = null;
  },
};
