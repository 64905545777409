import type { PlatformType } from '@/util/DeviceType/platforms';

type TerminalQueryParams = {
  fleetId: number;
  limit?: number;
  offset?: number;
  search?: string | null;
  sortBy?: string | null;
  sortDesc?: boolean;
  hideAffected?: boolean;
  hideDisabled?: boolean;
  platformFilters?: PlatformType[];
  validOn?: string;
}

export default function(axios) {
  return {
    get: (fleetId) => axios.get(`/fleets/${fleetId}`)
      .then((response) => response.data),
    list: () => axios.get('/fleets')
      .then((response) => response.data),
    listTerminals: (params: TerminalQueryParams) => {
      const {
        fleetId,
        limit = 20,
        offset = 0,
        search = null,
        sortBy = null,
        sortDesc = false,
        hideAffected = false,
        hideDisabled = true,
        platformFilters = [],
        validOn,
      } = params;

      return axios.get(`/fleets/${fleetId}/terminals`, {
        params: {
          limit,
          offset,
          search,
          sortBy,
          sortDesc,
          hideAffected,
          hideDisabled,
          platforms: platformFilters,
          validOn,
        },
      })
        .then((response) => response.data);
    },
    listAffectations: (fleetId) => axios.get(`/fleets/${fleetId}/affectations`)
      .then((response) => response.data),
    create: (fleetData) => axios.post('/fleets', fleetData)
      .then((response) => response.data),
    edit: (fleetId, fleetData) => axios.put(`/fleets/${fleetId}`, fleetData)
      .then((response) => response.data),
    removeTerminal: (fleetId, serial) => axios.delete(`/fleets/${fleetId}/terminals/${serial}`)
      .then((response) => response.data),
    addTerminals: (fleetId, terminalSerials) => axios.put(`/fleets/${fleetId}/terminals`, terminalSerials)
      .then((response) => response.data),
    affectTerminals: (fleetId, payload) => axios.post(`/fleets/${fleetId}/terminals/affect`, payload)
      .then((response) => response.data),
    loadTags: (fleetId) => axios.get(`/fleets/${fleetId}/terminals/tags`)
      .then((response) => response.data),
    addTags: (fleetId, payload) => axios.post(`/fleets/${fleetId}/terminals/tag`, payload)
      .then((response) => response.data),
    detachTag: (fleetId, tag, terminalId) => axios.delete(`/fleets/${fleetId}/terminals/${terminalId}/tags/${tag}`)
      .then((response) => response.data),
  };
}
