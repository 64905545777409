<template>
  <div>
    <slot name="drawer">
      <VNavigationDrawer
        v-model="isMenuVisible"
        color="primary"
        class="izp_bg"
        :permanent="!isMobile"
        :mini-variant="isDrawerMini"
        :touchless="!isMobile"
        fixed
        app
        dark
      >
        <IzpLayoutSidebar :key="$route.fullPath" :is-drawer-mini="isDrawerMini" @collapse="toggleDrawerMini()" />
      </VNavigationDrawer>
    </slot>

    <VAppBar app fixed light tabs elevate-on-scroll :extension-height="appBarExtensionHeight">
      <VAppBarNavIcon v-if="isMobile" @click="toggleMenu()">
        <VIcon>mdi-menu</VIcon>
      </VAppBarNavIcon>

      <slot name="layout_title">
        <RouterView name="toolbar" />
      </slot>

      <VSpacer />
      <ExpandableOmnibar v-if="!isDashboardRoute" />

      <VMenu transition="slide-y-transition" bottom left>
        <template #activator="{ on }">
          <VBtn dark icon rounded v-on="on">
            <VAvatar size="24">
              <img :src="flag" :alt="locale|upper" :title="locale|upper">
            </VAvatar>
          </VBtn>
        </template>
        <VList dense>
          <VListItem :value="getCurrentLang() === 'fr'" @click="localeSelected('fr')">
            <VListItemAvatar size="24">
              <img :src="FrFlagUrl" alt="FR">
            </VListItemAvatar>
            <VListItemTitle>
              <span class="md-layout-item">Français</span>&nbsp;
            </VListItemTitle>
          </VListItem>
          <VListItem :value="getCurrentLang() === 'gb'" @click="localeSelected('en')">
            <VListItemAvatar size="24">
              <img :src="EnFlagUrl" alt="EN">
            </VListItemAvatar>
            <VListItemTitle>
              <span class="md-layout-item">English</span>&nbsp;
            </VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
      <template v-if="tabsVisible" #extension>
        <PortalTarget class="full-width" name="layout-tabs-portal" @change="onTabsChange" />
      </template>
    </VAppBar>
    <VMain>
      <slot name="content">
        <RouterView />
      </slot>
    </VMain>
  </div>
</template>

<script>
import IzpLayoutSidebar from '@/components/layout/IzpLayoutSidebar.vue';
import '@/sass/functions.scss';
import store from '@/vuex/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import globalMixins from '@/mixins';
import moment from 'moment-timezone';
import { PortalTarget } from 'portal-vue';
import FrFlagUrl from '@/assets/images/flags/png/fr.png';
import EnFlagUrl from '@/assets/images/flags/png/gb.png';

export default {
  name: 'IzpLayout',
  components: {
    IzpLayoutSidebar,
    PortalTarget,
  },
  mixins: [globalMixins],
  props: {
    prominentToolbar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentLoader: null,
    backButton: false,
    locale: 'fr',
    flag: FrFlagUrl,
    FLAG_MAP: {
      fr: FrFlagUrl,
      en: EnFlagUrl,
    },
    miniDrawer: false,
    showUserMenu: false,
    expand: false,
    FrFlagUrl,
    EnFlagUrl,
  }),
  computed: {
    ...mapGetters('preferencesModule', {
      isMobile: 'isMobile',
      extensionHasLeftCol: 'extensionHasLeftCol',
      _isMenuVisible: 'isMenuVisible',
    }),
    ...mapState('preferencesModule', ['tabsVisible']),
    isMenuVisible: {
      get() {
        return this._isMenuVisible;
      },
      set(value) {
        if (!value) {
          this.toggleMenuVisible(false);
        }
      },
    },
    isDrawerMini: {
      get() {
        if (this.isMobile) {
          return false;
        }
        return this.miniDrawer || this.$vuetify.breakpoint.smAndDown;
      },
    },
    appBarExtensionHeight() {
      return this.isMobile && this.extensionHasLeftCol ? 92 : 48;
    },
    isDashboardRoute() {
      return this.$route.matched.some(({ name }) => name === 'dashboard');
    },
  },
  created() {
    this.setAppBarExtensionSettings({ tabsVisible: false });
    const storedLocale = localStorage.getItem('hubLocale') ?? 'fr';
    this.flag = this.FLAG_MAP[storedLocale];
  },
  async destroyed() {
  },
  methods: {
    ...mapActions('preferencesModule', ['toggleMenuPinned', 'toggleMenuVisible', 'setAppBarExtensionSettings']),
    toggleMenu() {
      this.toggleMenuVisible();
    },
    toggleDrawerMini() {
      this.miniDrawer = !(this.miniDrawer);
    },
    doLogout() {
      store.dispatch('authModule/logout').then(() => this.$router.push({ name: 'auth-login' }));
    },
    localeSelected(locale) {
      this.langSwitcher(locale);
      this.flag = this.FLAG_MAP[locale];
    },
    onTabsChange(ev) {
      this.setAppBarExtensionSettings({ tabsVisible: ev, extensionHasLeftCol: this.extensionHasLeftCol });
    },
  },
};
</script>

<style lang="scss" scoped>
  a {
      text-decoration: none;
  }

  #user-menu-menu .v-menu__content {
    right: 14px !important;
    left: auto !important;
  }

  :deep(.data-view-headline) {
    margin-bottom: 2em;
  }
</style>
