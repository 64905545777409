const Numbers = {

  /**
   *
   * @param stringNumber
   * @param thousandSeparator Optional, if omitted, will be guessed from Locale.
   * @param decimalSeparator Optional, if omitted, will be guessed from Locale.
   * @returns {number}
   */
  parseLocaleNumber: (stringNumber, thousandSeparator = null, decimalSeparator = null) => {
    const num = 123456.789;
    const localeFormat = new Intl.NumberFormat();
    const localePars = localeFormat.formatToParts(num);

    const thousands = thousandSeparator ?? localePars.find((part) => part.type === 'group').value;
    const decimal = decimalSeparator ?? localePars.find((part) => part.type === 'decimal').value;

    const number = stringNumber
      .replace(new RegExp(`\\${thousands}`, 'g'), '')
      .replace(new RegExp(`\\${decimal}`), '.')
    ;

    return parseFloat(number);
  },

  /**
   *
   * @param stringPercent
   * @param thousandSeparator Optional, if omitted, will be guessed from Locale.
   * @param decimalSeparator Optional, if omitted, will be guessed from Locale.
   * @returns {number|*}
   */
  parsePercentNumber: (stringPercent, thousandSeparator = null, decimalSeparator = null) => {
    const hasPercentSign = stringPercent.includes('%');
    if (!hasPercentSign) {
      return Numbers.parseLocaleNumber(stringPercent, thousandSeparator, decimalSeparator);
    }
    const numberValue = Numbers.parseLocaleNumber(stringPercent.replace('%', ''), thousandSeparator, decimalSeparator);
    if (isNaN(numberValue)) {
      return stringPercent;
    } else {
      return numberValue / 100;
    }
  },
};

export default Numbers;
