export default function(axios, eventId, merchantId) {
  return {
    list: (offset = 0, limit = -1) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('Items offset & limit parameters should be integers.'));
      }
      return axios.get(`/events/${eventId}/merchants/${merchantId}/items?limit=${limit}&offset=${offset}`)
        .then((response) => response.data);
    },
    get: (itemId) => axios.get(`/events/${eventId}/merchants/${merchantId}/items/${itemId}`),
    post(itemData) {
      return axios.post(`/events/${eventId}/merchants/${merchantId}/items`, itemData)
        .then((response) => response.data);
    },
    put(itemId, itemData) {
      return axios.put(`/events/${eventId}/merchants/${merchantId}/items/${itemId}`, itemData)
        .then((response) => response.data);
    },
    delete(itemId) {
      return axios.delete(`/events/${eventId}/merchants/${merchantId}/items/${itemId}`)
        .then((response) => response.data);
    },
  };
}
