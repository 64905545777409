// import { TextEncoder } from '@/util/Polyfill/TextEncoder';

export function randomString(length) {
  const bytes = new Uint8Array(length);
  const result = [];
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

  const cryptoObj =
    window.crypto || window.msCrypto;
  if (!cryptoObj) {
    return null;
  }

  const random = cryptoObj.getRandomValues(bytes);

  for (let a = 0; a < random.length; a++) {
    result.push(charset[random[a] % charset.length]);
  }

  return result.join('');
}

export async function sha256(message) {
  const cryptoObj =
    window.crypto || window.msCrypto;
  if (!cryptoObj) {
    return Promise.reject(new Error('No crypto'));
  }

  const cryptoSubtle = cryptoObj.subtle || cryptoObj.webkitSubtle;
  if (!cryptoSubtle) {
    return Promise.reject(new Error('No crypto.subtle'));
  }

  const digestFn = cryptoSubtle.digest({ name: 'SHA-256' }, new TextEncoder().encode(message));

  // IE11 polyfill
  // if (window.msCrypto) {
  //   return new Promise((resolve, reject) => {
  //     digestFn.oncomplete = (e) => {
  //       resolve(e.target.result);
  //     };
  //
  //     digestFn.onerror = (e) => {
  //       reject(e.error);
  //     };
  //
  //     digestFn.onabort = () => {
  //       reject(new Error('Aborted'));
  //     };
  //   });
  // }

  return await digestFn;
}

export function base64urlEncode(message) {
  return message.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
