import wallets from '@/api/endpoints/cashless/wallets';
import identifiers from '@/api/endpoints/cashless/identifiers';
import holders from './holders';
import stats from '@/api/endpoints/cashless/stats';
import models from '@/api/endpoints/cashless/models';
import formulas from '@/api/endpoints/cashless/formulas';
import vouchers from '@/api/endpoints/cashless/vouchers';
import codesApi from '@/api/endpoints/cashless/codes';
import invoices from '@/api/endpoints/cashless/invoices';

export default function(axios) {
  return {
    walletsOf: (eventId) => wallets(axios, eventId),
    identifiersOf: (eventId) => identifiers(axios, eventId),
    holdersOf: (eventId) => holders(axios, eventId),
    modelsOf: (eventId) => models(axios, eventId),
    formulasOf: (eventId) => formulas(axios, eventId),
    vouchersOf: (eventId) => vouchers(axios, eventId),
    codes: codesApi(axios),
    invoicesOf: (eventId) => invoices(axios, eventId),
    blacklistIdentifier: (identifierId) => {
      return axios.post(`/cashless/identifier/${identifierId}/blacklist`)
        .then((response) => response.data);
    },
    unblacklistIdentifier: (identifierId) => {
      return axios.post(`/cashless/identifier/${identifierId}/unblacklist`)
        .then((response) => response.data);
    },
    deleteIdentifier: (identifierId) => {
      return axios.delete(`/cashless/identifier/${identifierId}`);
    },
    statsOf: (eventId) => stats(axios, eventId),
    deleteCoupon: (couponId) => {
      return axios.delete(`/cashless/coupons/${couponId}`)
        .then((response) => response.data);
    },
    findOperationForPayment: (paymentId) => {
      return axios.get(`/cashless/operations/for-payment/${paymentId}`)
        .then((response) => response.data);
    },
  };
}
