import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const StatsPrefSchema = {
  event: null,
};

// Menu schema.
const MenuSchema = {
  sidebar: {
    events: null,
  },
  visible: true,
  pinned: true,
};

const state = {
  stats: [],
  menu: MenuSchema,
  preferences: StatsPrefSchema,
  isMobile: false,
  isPosPaymentMethodSettingsAlertOpened: true,
  tabsVisible: false,
  appBarExtensionLeftCol: false,
  tables: {},
  recentActivity: {
    durationChoices: [7, 14, 21, 28],
    duration: 21,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
