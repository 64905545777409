import translations from '@/translations';
import Vue from 'vue';

// https://vue-i18n.intlify.dev/guide/migration/vue2.html
import VueI18n from 'vue-i18n';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';

Vue.use(VueI18n, { bridge: true });

// `createI18n` options is almost same vue-i18n-next (vue-i18n@v9.x) API
const i18n = castToVueI18n(
  createI18n({
    locale: window.localStorage.getItem('hubLocale') || 'fr',
    fallbackLocale: 'fr',
    messages: translations,
    legacy: false,
  }, VueI18n),
);

Vue.use(i18n); // you must install `i18n` instance which is created by `createI18n`

export default i18n;
