export default function(axios) {
  return {
    list: () => {
      return axios.get('/roles')
        .then((response) => response.data);
    },
    get: (roleId) => {
      return axios.get(`/roles/${roleId}`)
        .then((response) => response.data);
    },
    create: (roleData) => {
      return axios.post('/roles', roleData);
    },
    edit: (roleId, roleData) => {
      return axios.put(`/roles/${roleId}`, roleData);
    },
  };
}
