import vuetifyColors from 'vuetify/es5/util/colors';
import _camelCase from 'lodash/camelCase';

const COLOR_CLASS_NAMES = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'blue-grey',
];

const COLOR_VARIANTS = [
  'base',
  'lighten-4',
  'lighten-3',
  'lighten-2',
  'lighten-1',
  'darken-1',
  'darken-2',
  'accent-1',
  'accent-2',
  'accent-3',
  'accent-4',
];

export default function useColors() {
  const getColor = (id: string | number) => {
    // Either extracts all numbers from the uuid, or multiplies the id by itself.
    const numbers = typeof id === 'string'
      ? Number(id.replace(/\D+/g, ''))
      : id * id;

    const color = COLOR_CLASS_NAMES[numbers % COLOR_CLASS_NAMES.length];
    const variant = COLOR_VARIANTS[numbers % COLOR_VARIANTS.length];
    const classes = `${color} ${variant}`;
    const textColor = getTextColorFromClasses(classes);

    return `${classes} ${textColor}`;
  };

  const getHexaFromClasses = (className: string) => {
    const [color, variant] = className.split(' ');
    // Retrieves classes equivalants to Vuetify's colors.
    const vuetifyHexa = vuetifyColors[_camelCase(color)][_camelCase(variant)] || '#999999';
    return vuetifyHexa;
  };

  const getTextColorFromClasses = (className: string) => {
    const hexColor = getHexaFromClasses(className);
    const hexNumbers = hexColor.replace('#', '');

    // Transforms the hexa code to rgb.
    const r = parseInt(hexNumbers.substring(0, 2), 16);
    const g = parseInt(hexNumbers.substring(2, 4), 16);
    const b = parseInt(hexNumbers.substring(4, 6), 16);

    // Decides a contrast based on the RGB.
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Compute contracted text color to match with a background color.
    return (yiq >= 128) ? 'black--text' : 'white--text';
  };

  return {
    getColor,
    getHexaFromClasses,
    getTextColorFromClasses,
  };
}
