import api from '@/api';
// import jwt from 'jsonwebtoken';
import { decodeJwt } from 'jose';
import OAuthClient from '@/util/OAuth';
import { randomString } from '@/util/Random';
import Meta from '@/Meta';

const oauthClient = new OAuthClient({
  domain: Meta.env.VITE_OAUTH_DOMAIN,
  client_id: Meta.env.VITE_OAUTH_CLIENT_ID,
});

export default {

  async initLogin({ commit }, { redirectUri }) {
    const state = randomString(32);
    const codeVerifier = randomString(50);
    commit('STORE_OAUTH_CSRF_STATE', { oauthCsrfState: state });
    commit('STORE_OAUTH_PKCE', { oauthCodeVerifier: codeVerifier });

    const oauthUri = await oauthClient.generateAuthorizeUrl({ redirectUri, state, codeVerifier });
    window.location.assign(oauthUri);
  },

  async login({ dispatch, state, commit }, { redirectUri }) {
    if (state.oauthCsrfState === null) {
      throw new Error('Missing csrf state');
    }

    if (state.oauthCodeChallenge === null) {
      throw new Error('Missing code challenge');
    }

    const params = (new URL(window.location)).searchParams;

    // Remove the querystring
    const url = new URL(window.location);
    url.search = '';
    window.history.pushState('', document.title, url);

    if (params.get('state') !== state.oauthCsrfState) {
      throw new Error('Bad state');
    }

    const token = await oauthClient.getToken({ redirectUri, code: params.get('code'), codeVerifier: state.oauthCodeVerifier });

    commit('STORE_OAUTH_CSRF_STATE', { oauthCsrfState: null });
    commit('STORE_OAUTH_PKCE', { oauthCodeVerifier: null });

    return dispatch('checkToken', {
      accessToken: token.access_token,
      refreshToken: token.refresh_token,
    });
  },

  async refresh({ dispatch }, { refreshToken }) {
    const token = await oauthClient.getTokenFromRefreshToken({ refreshToken });

    return dispatch('checkToken', {
      accessToken: token.access_token,
      refreshToken: token.refresh_token,
    });
  },

  async checkToken({ commit, dispatch }, { accessToken, refreshToken }) {
    const decodedToken = decodeJwt(accessToken);
    await commit('STORE_SESSION', {
      decodedToken,
      token: accessToken,
      refreshToken,
    });

    return dispatch('loadCurrentUser');
  },

  async loadCurrentUser({ commit, getters, dispatch }) {
    const user = await api.users.get(getters.session.id);
    await commit('STORE_USER', { user });

    return user;
  },

  async impersonate({ dispatch }, { user }) {
    const userToken = await api.security.impersonate(user);
    await dispatch('clearAll', null, { root: true });
    const tokenObject = {
      accessToken: userToken.access_token,
    };

    return dispatch('checkToken', tokenObject);
  },

  isExpired({ commit }, { session }) {
    return Date.now() >= session.exp * 1000;
  },

  async isUnavailable({ commit, dispatch }, session) {
    return session.user === undefined || dispatch('isExpired', { session });
  },

  isPrimary({ commit }, session) {
    return session.refreshToken !== undefined;
  },

  async switchToSession({ commit, dispatch }, { session, first }) {
    if (first === true) {
      await commit('SWITCH_TO_FIRST_SESSION');
    } else {
      await commit('SWITCH_SESSION', { session });
    }

    return dispatch('loadCurrentUser');
  },

  deleteSession({ commit }, { session }) {
    commit('DELETE_SESSION', { session });
  },

  logout({ commit, dispatch }) {
    commit('CLEAR_TOKENS');
    return dispatch('clearAll', null, { root: true });
  },

  clear: {
    root: true,
    handler({ commit }) {
      // clear is handled by logout directly
    },
  },
};
