import posApi from './pos';
import itemsApi from './items';
import terminalAffectationsApi from './terminal-affectations';

export default function(axios, eventId) {
  return {
    list: () => {
      return axios.get(`/events/${eventId}/merchants`)
        .then((response) => response.data);
    },
    get: (merchantId) => {
      return axios.get(`/events/${eventId}/merchants/${merchantId}`)
        .then((response) => response.data);
    },
    getPos: (merchantId) => {
      return axios.get(`/events/${eventId}/merchants/${merchantId}/pos`)
        .then((response) => response.data);
    },
    getStocks: (merchantId) => {
      return axios.get(`/events/${eventId}/merchants/${merchantId}/stocks`)
        .then((response) => response.data);
    },
    posOf: (merchantId) => {
      return posApi(axios, eventId, merchantId);
    },
    itemsOf(merchantId) {
      return itemsApi(axios, eventId, merchantId);
    },
    terminalAffectationsOf(merchantId) {
      return terminalAffectationsApi(axios, eventId, merchantId);
    },
  };
}
