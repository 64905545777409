<template>
  <div>
    <IzpLogo />
    <v-container class="fill-height" fluid>
      <v-row class="justify-center">
        <v-col cols="12" sm="8" class="text-center">
          <slot name="content" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import globalMixins from '@/mixins';
import IzpLogo from '@/components/izp/Logo.vue';

export default {
  name: 'IzpSimpleLayout',
  components: { IzpLogo },
  mixins: [globalMixins],
  data: () => ({}),
};
</script>
