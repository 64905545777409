import AccessControl from '@/security/AccessControl';

const MarketingNav = () => import('@/views/organizer/marketing/MarketingNav.vue');
const Discounts = () => import('@/views/organizer/marketing/discount/DiscountIndex.vue');
const DiscountNav = () => import('@/views/organizer/marketing/discount/DiscountNav.vue');
const DiscountOverview = () => import('@/views/organizer/marketing/discount/DiscountOverview.vue');
const DiscountSettings = () => import('@/views/organizer/marketing/discount/DiscountSettings.vue');
const Offers = () => import('@/views/organizer/marketing/Offers.vue');
const DistributeDiscountBatchList = () => import('@/views/organizer/marketing/batch/DistributeDiscountBatchList.vue');

export default [
  {
    path: '/marketing',
    name: 'marketing',
    props: true,
    redirect: { name: 'marketing-discounts' },
    component: MarketingNav,
    beforeEnter: AccessControl.isClientGuard,
    meta: {
      requiresAuth: true,
      visible: () => (AccessControl.isClient() && AccessControl.eventHasFeatureFlag('discount.allow')),
    },
    children: [
      {
        path: 'discounts',
        name: 'marketing-discounts',
        props: true,
        component: Discounts,
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'discounts/:discountId',
        name: 'marketing-discounts-nav',
        props: {
          default: true,
        },
        redirect: { name: 'marketing-discounts-view' },
        components: {
          default: DiscountNav,
        },
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'sales',
            name: 'marketing-discounts-view',
            props: {
              default: true,
            },
            components: {
              default: DiscountOverview,
            },
            beforeEnter: AccessControl.isClientGuard,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'settings',
            name: 'marketing-discounts-setting',
            props: {
              default: true,
            },
            components: {
              default: DiscountSettings,
            },
            beforeEnter: AccessControl.isClientGuard,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'offers',
        name: 'marketing-offers',
        props: true,
        component: Offers,
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'batches',
        name: 'marketing-batches',
        props: true,
        component: DistributeDiscountBatchList,
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
