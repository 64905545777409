<template>
  <v-list nav dense>
    <v-skeleton-loader
      class="transparent-loader"
      :loading="currentUser === null"
      type="list-item-avatar-two-line"
      height="60"
    >
      <v-menu
        v-model="showUserMenu"
        min-width="400px"
        max-width="100%"
        transition="slide-y-transition"
        z-index="1000"
        :close-on-content-click="false"
        bottom
        right
      >
        <template #activator="{ on }">
          <v-list-item class="pl-0" link nav dense v-on="on">
            <v-badge v-if="isImpersonating" color="white" overlap offset-x="15px" offset-y="15px" title="Vous endossez l'identité de cet utilisateur">
              <template #badge>
                <v-icon color="accent">
                  mdi-account-switch
                </v-icon>
              </template>
              <v-avatar size="40" :color="getColor(currentSession.user)">
                <span class="white--text subtitle-2">{{ currentSessionName|initialize }}</span>
              </v-avatar>
            </v-badge>
            <v-avatar v-else size="40" :color="getColor(currentSession.user)">
              <span class="white--text subtitle-2">{{ currentSessionName|initialize }}</span>
            </v-avatar>
            <v-list-item-content class="ml-2">
              <v-list-item-title class="subtitle-2">
                {{ currentSessionName }}
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ currentSessionEmail }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <UserDropdown v-model="showUserMenu" />
      </v-menu>
    </v-skeleton-loader>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import UserDropdown from '@/components/menu/UserDropdown.vue';
import displayHelpers from '@/mixins/displayHelpers';
import AccessControl from '@/security/AccessControl';

export default {
  name: 'UserMenu',
  components: {
    UserDropdown,
  },
  mixins: [displayHelpers],
  props: {
    code: {
      type: String,
      default: '',
    },
    edition: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showUserMenu: false,
  }),
  computed: {
    ...mapGetters('authModule', {
      authenticated: 'authenticated',
      currentSession: 'session',
      currentUser: 'user',
    }),
    currentSessionName() {
      return (this.currentUser && this.currentUser.name) || '?';
    },
    currentSessionEmail() {
      return (this.currentUser && this.currentUser.email) || '?';
    },
    currentSessionPhoto() {
      return (this.currentUser && this.currentUser.profile && this.currentUser.profile.photo && this.currentUser.profile.photo.url['150']) || null;
    },
    isImpersonating() {
      return AccessControl.hasAdminSession() && !this.currentUser.is_super_user;
    },
  },
};
</script>

<style scoped>
  .transparent-loader :deep(> div) {
    padding: 0 !important;
    background: transparent !important;
  }
</style>
