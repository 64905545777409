const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const AdminClientIndex = () => import('@/views/admin/client/ClientIndex.vue');
const AdminClientView = () => import('@/views/admin/client/ClientView.vue');
const ClientNav = () => import('@/views/admin/client/ClientNav.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'clients',
    name: 'admin-clients',
    props: {
      default: true,
      'admin-menu': false,
    },
    components: {
      default: AdminClientIndex,
      'admin-menu': AdminMenu,
    },
    meta: {
      h2: 'form.event.self',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
  },
  {
    path: 'clients/:clientId',
    name: 'admin-client-nav',
    props: {
      default: true,
      'admin-menu': false,
    },
    redirect: { name: 'admin-client-view' },
    components: {
      default: ClientNav,
      'admin-menu': AdminMenu,
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'admin-client-view',
        props: {
          default: false,
          'admin-menu': false,
        },
        components: {
          default: AdminClientView,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.event.self',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
    ],
  },
];
