export default function(axios, eventId) {
  return {
    put(name, value) {
      return axios.put(`/events/${eventId}/config`, { name, value })
        .then((response) => response.data);
    },
    putCashlessConfig(groupValue, fundFeeValue) {
      return axios.put(`/events/${eventId}/cashless_config`, { groupValue, fundFeeValue })
        .then((response) => response.data);
    },
    delete(name) {
      return axios.delete(`/events/${eventId}/config`, { data: { name } })
        .then((response) => response.data);
    },
  };
}
