export default function(axios, clientId, discountId) {
  return {
    list: () => {
      return axios.get(`/clients/${clientId}/discounts/${discountId}/validity-rules`)
        .then((response) => response.data);
    },
    post: (validityRuleData) => {
      return axios.post(`/clients/${clientId}/discounts/${discountId}/validity-rules`, validityRuleData)
        .then((response) => response.data);
    },
    put: (validityRuleId, validityRuleData) => {
      return axios.put(`/clients/${clientId}/discounts/${discountId}/validity-rules/${validityRuleId}`, validityRuleData)
        .then((response) => response.data);
    },
    delete: (validityRuleId) => {
      return axios.delete(`/clients/${clientId}/discounts/${discountId}/validity-rules/${validityRuleId}`)
        .then((response) => response.data);
    },
  };
}
