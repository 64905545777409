export default function(axios, eventId, merchantId) {
  return {
    list: (offset, limit) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('TerminalAffectations offset & limit parameters should be integers.'));
      }
      return axios.get(`/events/${eventId}/merchants/${merchantId}/terminal-affectations?limit=-1`)
        .then((response) => response.data);
    },
    get: (terminalAffectationId) => axios.get(`/events/${eventId}/merchants/${merchantId}/terminal-affectations/${terminalAffectationId}`),
    post(terminalAffectationData) {
      return axios.post(`/events/${eventId}/merchants/${merchantId}/terminal-affectations`, terminalAffectationData)
        .then((response) => response.data);
    },
    put(terminalAffectationId, terminalAffectationData) {
      return axios.put(`/events/${eventId}/merchants/${merchantId}/terminal-affectations/${terminalAffectationId}`, terminalAffectationData)
        .then((response) => response.data);
    },
    delete(terminalAffectationId) {
      return axios.delete(`/events/${eventId}/merchants/${merchantId}/terminal-affectations/${terminalAffectationId}`)
        .then((response) => response.data);
    },
  };
}
