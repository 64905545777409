import Qs from 'qs';

export default function(axios, eventId) {
  return {
    list: (search = null, limit = 10) => {
      return axios.get(`/cashless/event/${eventId}/identifiers`, {
        params: {
          search,
          limit,
        },
      })
        .then((response) => response.data);
    },
    getRefundRequest: (code) => {
      return axios.get(`/refund/event/${eventId}/identifiers/${code}/refund-request`)
        .then((response) => response.data);
    },
  };
}
