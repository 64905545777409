const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const AdminEventIndex = () => import('@/views/admin/event/EventIndex.vue');
const AdminEventView = () => import('@/views/admin/event/EventView.vue');
const AdminEventSettings = () => import('@/views/admin/event/EventSettings.vue');
const AdminEventNav = () => import('@/views/admin/event/AdminEventNav.vue');
const EventNav = () => import('@/views/admin/event/EventNav.vue');
const EventTemplates = () => import('@/views/admin/event/EventTemplates.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'events',
    name: 'admin-events',
    props: {
      default: true,
      'admin-menu': false,
    },
    components: {
      default: AdminEventNav,
      'admin-menu': AdminMenu,
    },
    redirect: { name: 'admin-events-list' },
    meta: {
      h2: 'form.event.self',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
    children: [
      {
        path: '',
        name: 'admin-events-list',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminEventIndex,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.event.self',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
      {
        path: 'events/:eventId',
        name: 'admin-event-nav',
        props: {
          default: true,
          'admin-menu': false,
        },
        redirect: { name: 'admin-event-view' },
        components: {
          default: EventNav,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'admin-event-view',
            props: {
              default: false,
              'admin-menu': false,
            },
            components: {
              default: AdminEventView,
              'admin-menu': AdminMenu,
            },
            meta: {
              h2: 'form.event.self',
              title: izypayTitlePrefix,
              requiresAuth: true,
              layout: 'AdminManager',
            },
          },
          {
            path: 'settings',
            name: 'admin-event-settings',
            props: {
              default: false,
              'admin-menu': false,
            },
            components: {
              default: AdminEventSettings,
              'admin-menu': AdminMenu,
            },
            meta: {
              h2: 'form.event.self',
              title: izypayTitlePrefix,
              requiresAuth: true,
              layout: 'AdminManager',
            },
          },
        ],
      },
      {
        path: 'templates',
        name: 'admin-event-templates',
        props: {
          default: false,
          'admin-menu': false,
        },
        components: {
          default: EventTemplates,
          'admin-menu': AdminMenu,
        },
      },
    ],
  },
];
