import Vue from 'vue';

import * as Sentry from '@sentry/vue';

import Vuex from 'vuex';

import VueColor from 'vue-color';
import VueSync from 'vue-sync';
import VueHotkey from 'v-hotkey';

import App from './App.vue';
import router from './router';
import store from './vuex/store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';
import pinia from './plugins/pinia';
import globalMixins from './mixins';
import filters from './mixins/filters';
import VueCurrencyFilter from 'vue-currency-filter';
import izpAvatar from './components/izp/IzpAvatar.vue';
import './plugins/vee-validate';

import '../src/sass/main.scss';
import IzpLayout from './components/layout/IzpLayout.vue';
import IzpSimpleLayout from './components/layout/IzpSimpleLayout.vue';

import Meta from './Meta';

import moment from 'moment-timezone';
import fr from '@/util/moment-fr-translation';

moment.updateLocale('fr', fr);
moment.locale(window.localStorage.getItem('hubLocale') ?? 'fr');

Vue.config.productionTip = false;

// Early initialization of Sentry
Sentry.init({
  Vue,
  logErrors: !import.meta.env.PROD,
  dsn: Meta.env.VITE_SENTRY_DSN ?? 'noop://localhost',
  environment: Meta.env.VITE_SENTRY_ENV ?? 'local',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
  ],
  tracesSampleRate: parseFloat(Meta.env.VITE_SENTRY_SAMPLE_RATE) ?? 1.0,
  release: Meta.version,
  autoSessionTracking: true,
  ignoreErrors: [],
  tracingOptions: {
    trackComponents: true,
  },
});
// END - Sentry initialization

Vue.component('IzpAvatar', izpAvatar);

// Use required dependencies.
Vue.use(Vuex);
Vue.use(VueColor);
Vue.use(VueSync);
Vue.use(VueHotkey, {
  '/': 47, // This is for AZERTY keyboards
  ':': 58, // This is for AZERTY keyboards
});

// Vue use dependencies.
Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: ' ',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'end',
  symbolSpacing: true,
});

Vue.filter('upper', filters.methods.upper);
Vue.filter('capitalize', filters.methods.capitalize);
Vue.filter('lower', filters.methods.lower);
Vue.filter('initialize', filters.methods.initialize);
Vue.filter('defined', filters.methods.defined);
Vue.filter('shorten', filters.methods.shorten);
Vue.filter('date', filters.methods.date);
Vue.filter('paymentmethod', filters.methods.humanReadablePaymentMethod);
Vue.filter('transstatus', filters.methods.humanReadableTransactionStatus);
Vue.filter('percentage', filters.methods.percentage);

Vue.component('IzpLayout', IzpLayout);
Vue.component('IzpSimpleLayout', IzpSimpleLayout);

const vue = new Vue({
  el: '#izp',
  vuetify,
  mixins: [globalMixins, filters],
  render: (h) => h(App),
  router,
  i18n,
  store,
  pinia,
});

export default vue;
