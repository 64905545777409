export default function(axios, eventId, holderId) {
  return {
    list: () => {
      return axios.get(`/cashless/event/${eventId}/holders/tags`)
        .then((response) => response.data);
    },
    post: (tagData) => {
      return axios.post(`/cashless/event/${eventId}/holders/${holderId}/tags`, tagData)
        .then((response) => response.data);
    },
    delete: (tagId) => {
      return axios.delete(`/cashless/event/${eventId}/holders/${holderId}/tags/${tagId}`)
        .then((response) => response.data);
    },
  };
}
