
import colors from 'vuetify/es5/util/colors';
import camelCase from 'lodash/camelCase';

class ColorHelper {
  static COLOR_CLASS_NAMES = [
    'red',
    'pink',
    'purple',
    'deep-purple',
    'indigo',
    'blue',
    'light-blue',
    'cyan',
    'teal',
    'green',
    'light-green',
    'lime',
    'yellow',
    'amber',
    'orange',
    'deep-orange',
    'brown',
    'blue-grey',
  ];

  static COLOR_VARIANTS = [
    'base',
    'lighten-4',
    'lighten-3',
    'lighten-2',
    'lighten-1',
    'darken-1',
    'darken-2',
    'accent-1',
    'accent-2',
    'accent-3',
    'accent-4',
  ];

  /**
   * Retrieves hexColor from color classes generated by ColorHelper.randomColor
   * @param className
   * @returns {*}
   */
  static classNameToHexString(className) {
    if (typeof className !== 'string') {
      return '#999999';
    }
    const classes = className.split(' ');
    if (!colors[camelCase(classes[0])][camelCase(classes[1])]) {
      return '#999999';
    }
    return colors[camelCase(classes[0])][camelCase(classes[1])] || '#999999';
  }

  /**
   * Transforms an hexColor string to a RGB Object Ex: ({r: 0, b: 0, b: 0} for black)
   * @param hexColor
   * @returns {{r: number, g: number, b: number}}
   */
  static hexToRGB(hexColor) {
    if (hexColor.length !== 7) {
      return { r: 20, g: 20, b: 20 };
    }
    const r = parseInt(hexColor.replace('#', '').substring(0, 2), 16);
    const g = parseInt(hexColor.replace('#', '').substring(2, 4), 16);
    const b = parseInt(hexColor.replace('#', '').substring(4, 6), 16);

    return { r, g, b };
  }

  /**
   * Compute contracted text color to match with a background color
   * https://harthur.github.io/brain/
   * @param hexColor
   * @returns {string} ['white--text'|'black--text']
   */
  static getContrastedTextColor(hexColor) {
    const rgbColor = this.hexToRGB(hexColor);
    const yiq = (rgbColor.r * 299 + rgbColor.g * 587 + rgbColor.b * 114) / 1000;
    return (yiq >= 128) ? 'black--text' : 'white--text';
  }

  /**
   * Get pseudo-random color classes composed of '$color $variant $textColor'
   * @param index (optionnal) an index used to loop over colors array. Same index gives same color
   * @returns {string}
   */
  static randomColor(index) {
    index = index || Math.random();
    const color = ColorHelper.COLOR_CLASS_NAMES[index % ColorHelper.COLOR_CLASS_NAMES.length];
    const variant = ColorHelper.COLOR_VARIANTS[index * index % ColorHelper.COLOR_VARIANTS.length];
    const classes = `${color} ${variant}`;
    const hexColor = this.classNameToHexString(classes);
    const textColor = ColorHelper.getContrastedTextColor(hexColor);
    return `${classes} ${textColor}`;
  }
}

export default ColorHelper;
