import type { PaginationParams } from '@/types/models';
import { ref, computed, watch, onMounted } from 'vue';
import _debounce from 'lodash/debounce';

interface Configuration {
  sortBy: string,
  sortDesc: boolean,
  itemsPerPage: number,
  page: number,
  fetchFn?: () => any,
  updateQuery: boolean,
  minSearchLength: number,
  remoteColumnMap: Record<string, string>,
}

const defaultOptions: Configuration = {
  sortBy: 'updated_at',
  sortDesc: true,
  itemsPerPage: 10,
  page: 1,
  updateQuery: false,
  minSearchLength: 3,
  remoteColumnMap: {
    updated_at: 'updatedAt',
  },
};

export default function usePagination(userOptions?: Partial<Configuration>) {
  const options = { ...Object.assign({}, defaultOptions), ...userOptions };

  // TODO: change VDataTable's pagination settings for the vue3-migration branch.
  const pagination = ref({
    sortBy: [options.sortBy],
    sortDesc: [options.sortDesc],
    itemsPerPage: options.itemsPerPage,
    page: options.page,
  });

  const search = ref<string>();

  const mappedColumn = (columnName) => options.remoteColumnMap[columnName] ?? columnName;

  const paginationParams = computed<PaginationParams>(() => {
    const sortBy = mappedColumn(pagination.value.sortBy[0] ?? options.sortBy);
    const sortDesc = pagination.value.sortDesc[0] ?? options.sortDesc;

    const params: PaginationParams = {
      limit: pagination.value.itemsPerPage,
      offset: pagination.value.itemsPerPage * (pagination.value.page - 1),
      sortBy: {
        [sortBy]: sortDesc ? 'DESC' : 'ASC',
      },
    };

    if (search.value && search.value.length >= options.minSearchLength) {
      params.search = search.value;
    }

    return params;
  });

  const updateQuery = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('page', pagination.value.page.toString());
    url.searchParams.set('itemsPerPage', pagination.value.itemsPerPage.toString());

    if ((search.value?.length ?? 0) >= options.minSearchLength) {
      url.searchParams.set('search', search.value ?? '');
    } else {
      url.searchParams.delete('search');
    }

    history.pushState({}, '', url);
  };

  const initQuery = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pageQuery = urlParams.get('page');
    const searchQuery = urlParams.get('search');

    if (pageQuery) pagination.value.page = Number(pageQuery);
    if (searchQuery) search.value = searchQuery;
  };

  const handleParametersChange = () => options.fetchFn?.();
  const debounceParamsChange = _debounce(handleParametersChange, 500);

  watch([search, paginationParams], ([newSearch], [oldSearch]) => {
    if (newSearch !== oldSearch) {
      pagination.value.page = 1;
    }

    if (options.updateQuery) {
      updateQuery();
    }

    debounceParamsChange();
  });

  onMounted(() => {
    if (options.updateQuery) {
      initQuery();
    }
  });

  return {
    debouncedFn: debounceParamsChange,
    pagination,
    paginationParams,
    search,
  };
}
