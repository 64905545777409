export default function(axios) {
  return {
    get: (transactionId) => {
      return axios.get(`/transactions/${transactionId}`)
        .then((response) => response.data);
    },
    getEntries: (transactionId) => {
      return axios.get(`/transactions/${transactionId}/entries`)
        .then((response) => response.data);
    },
    getRefundEntries: (transactionId) => {
      return axios.get(`/transactions/${transactionId}/refund-entries`)
        .then((response) => response.data);
    },
    getPayments: (transactionId) => {
      return axios.get(`/transactions/${transactionId}/payments`)
        .then((response) => response.data);
    },
    withPendingPayment: (offset = 0, limit = 10, sortBy, filters, startDate, endDate) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(new Error('Transactions offset & limit parameters should be integers.'));
      }

      return axios.get('/transactions/with-pending-payment', {
        params: {
          startDate,
          endDate,
          offset,
          limit,
          sortBy,
          filters,
          types: ['item_transaction', 'money_wallet_fund_transaction', 'money_wallet_refund_transaction', 'item_refund_transaction'],
        },
      })
        .then((response) => response.data);
    },
  };
}
