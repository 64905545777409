<template>
  <div class="snack-container">
    <v-snackbar
      v-model="snackbarDisplay"
      :timeout="5000"
      :bottom="true"
    >
      <template v-if="currentMessage">
        {{ currentMessage.message }}
      </template>
      <template v-if="currentMessage" #action="{ attrs }">
        <v-btn
          :color="currentMessage.tone"
          text
          v-bind="attrs"
          @click.prevent="closeMessage"
        >
          {{ $t('form.action.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog :value="showDialog" :persistent="true" max-width="500px">
      <v-card>
        <v-card-title class="headline" primary-title>
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text>{{ dialogMessage }}</v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            elevation="0"
            @click.stop="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            :color="dialogTone"
            elevation="0"
            @click.stop="back"
          >
            <v-icon name="chevron-left" />
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MessageBus',
  data: () => ({
    snackbarDisplay: false,
    currentMessage: null,
  }),
  computed: {
    ...mapGetters('snackbarModule', ['hasMessages', 'stackLength', 'head', 'dialog']),
    showDialog() {
      return this.dialog !== null;
    },
    dialogTitle() {
      return this.dialog ? this.dialog.title : '';
    },
    dialogMessage() {
      return this.dialog ? this.dialog.message : '';
    },
    dialogTone() {
      return this.dialog ? this.dialog.tone : '';
    },
  },
  watch: {
    stackLength(newValue) {
      if (newValue > 1) {
        console.warn('stackLength > 1 but only one message can be visible at a time !');
      } else if (newValue > 0) {
        this.nextMessage();
      }
    },
    snackbarDisplay(newValue, oldValue) {
      // on close
      if (oldValue && !newValue) {
        this.closeMessage();
      }
    },
  },
  mounted() {
    if (this.hasMessages) {
      this.nextMessage();
    }
  },
  methods: {
    ...mapActions('snackbarModule', ['removeHead', 'clearDialog']),
    async closeMessage() {
      this.snackbarDisplay = false;
      this.currentMessage = null;
      await this.removeHead();
      this.nextMessage();
    },
    nextMessage() {
      const nextMessage = this.head;
      if (nextMessage !== null) {
        this.currentMessage = nextMessage;
        this.snackbarDisplay = true;
      }
    },
    async closeDialog() {
      if (this.dialog && typeof this.dialog.callback === 'function') {
        this.dialog.callback.call();
      }

      await this.clearDialog();
    },
    async back() {
      this.$router.back();
      await this.clearDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
 .snack{
    .warning--text {
       color: lighten(#FFC107, 25%);
    }
    .info--text {
       color: lighten(#2196F3, 25%);
    }
    .success--text {
       color: lighten(#4CAF50, 25%);
    }
    .error--text {
       color: lighten(#FF5252, 25%);
    }
 }
</style>
