import store from '@/vuex/store';
import Lock from '@/util/Lock/Lock';
import merge from 'lodash/merge';
import router from '@/router';

export class AuthenticationError extends Error {
}

export default function(axiosInstance) {
  let refreshLock: Lock|null = null;

  axiosInstance.interceptors.response.use(
    (response) => response,
    async(error) => {
      if (!error.config || !error.response) {
        throw error;
      }

      const originalRequest = error.config;

      if (error.response.status !== 401) {
        throw error;
      }

      if (originalRequest.isRetry) {
        throw error;
      }

      const currentSessionIndex = store.getters['authModule/currentSessionIndex'];
      const user = store.getters['authModule/session'].user;
      const username = user && (user.full_name || user.email || 'anonyme');
      const refreshToken = store.getters['authModule/refreshToken'];
      const hasRefreshToken = refreshToken !== null && refreshToken !== undefined;

      if (originalRequest.sec.sessionIndex !== currentSessionIndex) {
        throw new Error('Request cancelled');
      }

      if (currentSessionIndex !== 0) {
        await store.dispatch('snackbarModule/pushMessage', {
          message: `Vous avez été déconnecté du compte "${username}"`,
          tone: 'info',
        });
        await store.dispatch('authModule/switchToSession', { first: true });
        await router.push({ name: 'dashboard', query: { t: Date.now().toString() } });

        throw new AuthenticationError();
      }

      if (!hasRefreshToken) {
        await store.dispatch('authModule/logout');
        await router.push({ name: 'auth-login' });

        throw new AuthenticationError();
      }

      originalRequest.isRetry = true;
      if (refreshLock !== null && originalRequest.bypassLock !== true) {
        await refreshLock.wait();
        return axiosInstance(originalRequest);
      }

      refreshLock = new Lock();
      try {
        await store.dispatch('authModule/refresh', { refreshToken });
      } catch (err) {
        await store.dispatch('authModule/logout');
        await router.push({ name: 'auth-login' });

        throw new AuthenticationError();
      } finally {
        refreshLock.unlock();
        refreshLock = null;
      }

      return axiosInstance(originalRequest);
    },
  );

  axiosInstance.interceptors.request.use(async(config) => {
    if (config.noAuth) {
      return config;
    }

    // If we are processing a refresh request, wait
    if (refreshLock !== null && config.bypassLock !== true) {
      await refreshLock.wait();
    }

    if (store.getters['authModule/token'] === null) {
      return config;
    }

    const currentSessionIndex = store.getters['authModule/currentSessionIndex'];

    return merge({}, config, {
      sec: {
        sessionIndex: currentSessionIndex,
      },
      headers: {
        Authorization: store.getters['authModule/httpAuthorizationHeader'],
      },
    });
  });
}
