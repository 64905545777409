import { hash } from '@/api/helpers/hash';
import { prepareAbortableRequest } from '@/api/cancellation';

export default function(axios, eventId) {
  return {
    get: (by = 'interval', startDate, endDate, interval, options) => {
      const requestHash = hash(`get_stats_by_${by}`).toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/events/${eventId}/stats/revenue/by/${by}`, {
        signal,
        params: {
          startDate,
          endDate,
          interval: interval || null,
          groupBy: options.groupBy || [],
          orderBy: options.orderBy || {},
          filters: options.filters || {},
          maxResults: options.maxResults || null,
          type: (options.type === 'horizontalBar' ? 'bar' : options.type) || null,
        },
      }).then((response) => response.data);
    },
    getRevenueSerie: (startDate, endDate, interval, options) => {
      const uniqueness = JSON.stringify({
        groupBy: options.groupBy,
        type: options.type,
      });
      const requestHash = hash(`get_revenue_serie_${uniqueness}`).toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/series/revenue`, {
        signal,
        params: {
          startDate,
          endDate,
          interval: interval || null,
          groupBy: options.groupBy || [],
          orderBy: options.orderBy || {},
          filters: options.filters || {},
          maxResults: options.maxResults || null,
          type: (options.type === 'horizontalBar' ? 'bar' : options.type) || null,
        },
      }).then((response) => response.data);
    },
    getRepartition: (subject = 'merchants', startDate, endDate, options) => {
      const uniqueness = JSON.stringify({
        subject,
        groupBy: options.groupBy,
      });
      const requestHash = hash(`get_repartition_${uniqueness}`).toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/repartitions/${subject}`, {
        signal,
        params: {
          startDate,
          endDate,
          groupBy: options.groupBy || [],
          orderBy: options.orderBy || { value: 'DESC' },
          filters: options.filters || {},
          maxResults: options.maxResults || null,
        },
      }).then((response) => response.data);
    },
    getSingleStat: (operator, startDate, endDate, subject, options) => {
      const requestHash = hash(`get_single_stat_${operator}_${subject}`).toString(16);
      const signal = prepareAbortableRequest(requestHash);
      startDate = startDate || options.startDate;
      endDate = endDate || options.endDate;

      return axios.get(`/events/${eventId}/stats/${operator}/${subject}`, {
        signal,
        params: {
          startDate,
          endDate,
          aggregate: options.aggregate || [],
          filters: options.filters || [],
        },
      })
        .then((response) => response.data);
    },
  };
}
