<template>
  <div class="d-flex flex align-center justify-end">
    <VSheet
      class="izp_omnibar_search d-flex rounded-pill align-center pl-4"
      rounded
      @click="open"
    >
      <VExpandXTransition>
        <IzpOmnibar
          v-show="expanded"
          ref="omnibar"
          @close="close"
          @keydown.esc="close"
        >
          <template #prepend-inner>
            <PortalTarget
              v-if="expanded"
              name="expandable-omnibar-hotkey-target"
            />
          </template>
        </IzpOmnibar>
      </VExpandXTransition>
      <slot name="activator">
        <PortalTarget
          v-if="!expanded"
          class="mb-1"
          name="expandable-omnibar-hotkey-target"
        />
        <VBtn
          text
          icon
        >
          <VIcon>
            mdi-magnify
          </VIcon>
        </VBtn>
      </slot>
    </VSheet>

    <HotkeySpot
      :keymap="keymap"
      :helpers="hotkeyHelpers"
      tooltip="left"
      portal-target="expandable-omnibar-hotkey-target"
    />
  </div>
</template>

<script>
import AccessControl from '@/security/AccessControl';
import displayHelpers from '@/mixins/displayHelpers';
import filters from '@/mixins/filters';
import IzpOmnibar from '@/components/izp/omnibar/IzpOmnibar.vue';
import HotkeySpot from '@/components/izp/HotkeySpot.vue';
import { PortalTarget } from 'portal-vue';

export default {
  name: 'ExpandableOmnibar',
  components: {
    IzpOmnibar,
    HotkeySpot,
    PortalTarget,
  },
  mixins: [displayHelpers, filters],
  props: {
    alwaysVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    isAdmin() {
      return AccessControl.isAdmin();
    },
    keymap() {
      return this.expanded ? this.closeKeymap : this.openKeymap;
    },
    openKeymap() {
      return {
        '/': this.open,
        'shift+:': this.open,
        'ctrl+:': this.open,
      };
    },
    // This keymap only displays the good key
    // The real ESC event is handled by @keydown.esc
    closeKeymap() {
      return {
        // eslint-disable-next-line quote-props
        'esc': this.close,
      };
    },
    hotkeyHelpers() {
      return this.expanded ? this.closeHelpers : this.openHelpers;
    },
    openHelpers() {
      return [{
        keys: ['/'],
        text: 'Lancez une recherche avec la touche / (slash)',
      }];
    },
    closeHelpers() {
      return [{
        keys: ['ESC'],
        text: 'Echap. pour fermer',
      }];
    },
  },
  watch: {
    expanded(newVal) {
      if (newVal) {
        this.$refs.omnibar.focus();
      } else {
        this.$refs.omnibar.blur();
        this.$refs.omnibar.closeMenu({});
      }
    },
  },
  methods: {
    close() {
      this.expanded = false;
    },
    open() {
      this.expanded = true;
    },
  },
};
</script>

<style scoped>
  .izp_omnibar_search, .izp_omnibar_search * {
    cursor: pointer !important;
  }
</style>
