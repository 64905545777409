const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const AdminMerchantIndex = () => import('@/views/admin/merchant/MerchantIndex.vue');
const AdminMerchantView = () => import('@/views/admin/merchant/MerchantView.vue');
const MerchantNav = () => import('@/views/admin/merchant/MerchantNav.vue');
const AdminMerchantNav = () => import('@/views/admin/merchant/AdminMerchantNav.vue');
const AdminMerchantBankingConfig = () => import('@/views/admin/merchant/MerchantBankingConfigList.vue');
const MerchantScheduledTasksList = () => import('@/views/admin/merchant/MerchantScheduledTasksList.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'merchants',
    name: 'admin-merchants',
    props: {
      default: true,
      'admin-menu': false,
    },
    redirect: { name: 'admin-merchants-list' },
    components: {
      default: AdminMerchantNav,
      'admin-menu': AdminMenu,
    },
    meta: {
      h2: 'form.event.self',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
    children: [
      {
        path: '',
        name: 'admin-merchants-list',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminMerchantIndex,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.event.self',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
      {
        path: 'banking-configs',
        name: 'admin-banking-configs',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminMerchantBankingConfig,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.event.self',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
      {
        path: 'scheduled-tasks',
        name: 'scheduled-tasks',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: MerchantScheduledTasksList,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.event.self',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },

      // Keep this route group at the end, so that routes below take precedence
      {
        path: ':merchantId',
        name: 'admin-merchant-nav',
        props: {
          default: true,
          'admin-menu': false,
        },
        redirect: { name: 'admin-merchant-view' },
        components: {
          default: MerchantNav,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'admin-merchant-view',
            props: {
              default: false,
              'admin-menu': false,
            },
            components: {
              default: AdminMerchantView,
              'admin-menu': AdminMenu,
            },
            meta: {
              h2: 'form.event.self',
              title: izypayTitlePrefix,
              requiresAuth: true,
              layout: 'AdminManager',
            },
          },
        ],
      },
    ],
  },
];
