import enI18n from '@/translations/en.i18n.json';
import frI18n from '@/translations/fr.i18n.json';

import enErrorsI18n from '@/translations/errors/en.i18n.json';
import frErrorsI18n from '@/translations/errors/fr.i18n.json';

import enCashlessI18n from '@/translations/cashless/en.i18n.json';
import frCashlessI18n from '@/translations/cashless/fr.i18n.json';

import enDataI18n from '@/translations/data/en.i18n.json';
import frDataI18n from '@/translations/data/fr.i18n.json';

import enFormI18n from '@/translations/form/en.i18n.json';
import frFormI18n from '@/translations/form/fr.i18n.json';

import enStockI18n from '@/translations/stock/en.i18n.json';
import frStockI18n from '@/translations/stock/fr.i18n.json';

import enValidatorsI18n from '@/translations/validators/en.i18n.json';
import frValidatorsI18n from '@/translations/validators/fr.i18n.json';

import enClickAndCollectI18n from '@/translations/click-and-collect/en.i18n.json';
import frClickAndCollectI18n from '@/translations/click-and-collect/fr.i18n.json';

import enExports18n from '@/translations/exports/en.i18n.json';
import frExports18n from '@/translations/exports/fr.i18n.json';

const index = {
  en: {
    ...enI18n,
    ...enErrorsI18n,
    ...enCashlessI18n,
    ...enDataI18n,
    ...enFormI18n,
    ...enStockI18n,
    ...enValidatorsI18n,
    ...enClickAndCollectI18n,
    ...enExports18n,
  },
  fr: {
    ...frI18n,
    ...frErrorsI18n,
    ...frCashlessI18n,
    ...frDataI18n,
    ...frFormI18n,
    ...frStockI18n,
    ...frValidatorsI18n,
    ...frClickAndCollectI18n,
    ...frExports18n,
  },
};

export default index;
