import campaignApi from './campaign';
import requestsApi from './requests';

export default function(axios) {
  return {
    campaignOf: () => {
      return campaignApi(axios);
    },
    requestsOf: () => {
      return requestsApi(axios);
    },
  };
}
