export default function(axios, clientId) {
  return {
    list() {
      return axios.get(`/clients/${clientId}/service_accounts`)
        .then((response) => response.data);
    },
    post(serviceAccountData) {
      return axios.post(`/clients/${clientId}/service_accounts`, serviceAccountData)
        .then((response) => response.data);
    },
  };
}
