import type { List } from '@/types/stores';
import type { Event, EventRef } from '@/types/models';
import usePagination from '@/composables/pagination';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import api from '@/api';
import _debounce from 'lodash/debounce';
import vuexStore from '@/vuex/store';
import AccessControl from '@/security/AccessControl';

const useEventStore = defineStore('event', () => {
  const { paginationParams, search } = usePagination({
    sortBy: 'updatedAt',
    itemsPerPage: 10,
  });

  const events = ref<EventRef[]>([]);
  const listLoading = ref<boolean>(false);
  const listErrors = ref<string[]|null>(null);

  const adminEvents = ref<EventRef[]>([]);
  const adminListLoading = ref<boolean>(false);
  const adminListErrors = ref<string[]|null>(null);
  const currentUser = computed(() => vuexStore.getters['authModule/user']);

  const event = ref<Event|null>();
  const fetchingEvent = ref<boolean>(false);

  const eventsList = computed<List<EventRef>>(() => ({
    list: events.value,
    loading: listLoading.value,
    errors: listErrors.value,
  }));

  const adminEventsList = computed<List<EventRef>>(() => {
    const isAdmin = AccessControl.isAdmin();
    if (adminEvents.value.length === 0 && isAdmin) {
      fetchAdminList();
    }

    return {
      list: adminEvents.value,
      loading: adminListLoading.value,
      errors: adminListErrors.value,
    };
  });

  async function fetchList(): Promise<void> {
    if (!currentUser.value) {
      return;
    }

    listLoading.value = true;
    listErrors.value = null;
    try {
      const { data } = await api.users
        .eventsOf(currentUser.value.id)
        .list(paginationParams.value);
      events.value = data;
    } catch (err: unknown) {
      events.value = [];
      listErrors.value = [
        'Impossible de charger la liste des événements',
        (err as Error)?.message ?? null,
      ];
    } finally {
      listLoading.value = false;
    }
  }

  async function fetchAdminList(): Promise<void> {
    adminListLoading.value = true;
    try {
      const { data } = await api.events.list(paginationParams.value);
      adminEvents.value = data;
    } catch (err: unknown) {
      adminEvents.value = [];
      adminListErrors.value = [
        'Impossible de charger la liste complète des événements',
        (err as Error)?.message ?? null,
      ];
    } finally {
      adminListLoading.value = false;
    }
  }

  async function setCurrentEvent(code: string, edition: string): Promise<void> {
    try {
      fetchingEvent.value = true;
      listErrors.value = null;
      event.value = await api.events.getBySlug(code, edition);
    } catch (err: unknown) {
      event.value = null;
      listErrors.value = [
        'Impossible de charger l\'événement',
        (err as Error)?.message ?? null,
      ];
    } finally {
      fetchingEvent.value = false;
    }
  }

  function clear() {
    event.value = null;
    events.value = [];
    listLoading.value = false;
    listErrors.value = [];

    // @TODO remove when VueX stores are not used anymore
    vuexStore.dispatch('clearCurrentUserEvent', null);
  }

  const debouncedEventList = _debounce(fetchList, 500);

  watch(() => event.value, (newVal, oldVal) => {
    if (newVal && newVal.id !== oldVal?.id) {
      // @TODO remove when VueX stores are not used anymore
      vuexStore.dispatch('setCurrentUserEvent', newVal);
    }
  });

  watch(currentUser, (newVal, oldVal) => {
    if (newVal?.id && newVal !== oldVal) {
      clear();
    }
  });

  watch(search, (newVal, oldVal) => {
    if (!newVal && !oldVal) return;
    if (newVal && events.value.some((e) => e.name.includes(newVal))) return;
    debouncedEventList();
  });

  return {
    eventsList,
    adminEventsList,
    event,
    fetchingEvent,
    fetchList,
    fetchAdminList,
    setCurrentEvent,
    search,
  };
});

export default useEventStore;
