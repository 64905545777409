import AccessControl from '@/security/AccessControl';

const EventMenu = () => import('@/components/menu/EventMenu.vue');

const EventPaymentSettings = () => import('@/views/event/settings/EventPaymentSettings.vue');
const SettingsNav = () => import('@/views/event/settings/SettingsNav.vue');
const EventWifiSettings = () => import('@/views/event/settings/EventWifiSettings.vue');
const EventGeneralSettings = () => import('@/views/event/settings/EventGeneralSettings.vue');
const EventMerchantsSettings = () => import('@/views/event/settings/EventMerchantsSettings.vue');
const EventCashlessSettings = () => import('@/views/event/settings/CashlessGroupSettings.vue');

export default [
  {
    path: 'settings',
    name: 'event-settings',
    props: {
      default: true,
      'event-menu': true,
    },
    components: {
      default: SettingsNav,
      'event-menu': EventMenu,
    },
    redirect: { name: 'event-general-settings' },
    beforeEnter: AccessControl.isClientGuard,
    meta: {
      requiresAuth: true,
      visible: AccessControl.isClient,
      eventMenu: {
        order: 8,
        icon: 'mdi-tune',
        transcode: 'general.settings',
      },
    },
    children: [
      {
        path: 'general',
        name: 'event-general-settings',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventGeneralSettings,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'cashless',
        name: 'event-cashless-settings',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventCashlessSettings,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'payment',
        name: 'event-payment-settings',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventPaymentSettings,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'wifi',
        name: 'event-wifi-settings',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventWifiSettings,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'merchants',
        name: 'event-merchants-settings',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventMerchantsSettings,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
