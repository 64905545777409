export default function(axios, clientId, discountId) {
  return {
    list: () => {
      return axios.get(`/clients/${clientId}/discounts/${discountId}/pos`)
        .then((response) => response.data);
    },
    post: (posData) => {
      return axios.post(`/clients/${clientId}/discounts/${discountId}/pos`, posData)
        .then((response) => response.data);
    },
    delete: (posId) => {
      return axios.delete(`/clients/${clientId}/discounts/${discountId}/pos/${posId}`)
        .then((response) => response.data);
    },
  };
}
