<template>
  <div class="d-flex flex-row mb-8 pa-2 align-center justify-center">
    <img :src="logoURL" class="client-admin-logo-img">
    <div class="flex-grow-0 flex-shrink-1 border-sep mx-4 py-4" />
    <span class="logo-baseline">Paiement libre, <br>encaissement zen.</span>
  </div>
</template>

<script>
import logoURL from '@/assets/images/izypay-logo-gradient.svg';

export default {
  name: 'Logo',
  data: () => ({
    logoURL,
  }),
};
</script>

<style lang="scss" scoped>

  @import '@/sass/variables.scss';
  @import '@/sass/colors.scss';
  @import '@/sass/fonts.scss';

  .client-admin-logo-img {
    height: auto;
    text-align: center;
    position: relative;
    width: 6rem;
    max-width: 60%;
  }

  .border-sep {
    border-left: 2px solid $accent;
    width: 0;
  }

  .logo-baseline {
    display: inline-flex;
    font-family: $text-font;
    font-size: 0.8em;
    line-height: 1rem;
    color: #999;
  }
</style>
