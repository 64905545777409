<template>
  <div class="sidebar-content d-flex flex-column flex-grow-1">
    <UserMenu />

    <VList dense nav>
      <VListItem :to="{ name: 'dashboard' }" dense nav ripple>
        <VListItemAction>
          <VIcon>mdi-monitor-dashboard</VIcon>
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle>
            {{ t('general.dashboard') }}
          </VListItemTitle>
          <VListItemSubtitle>
            {{ t('msg_action.Overview_managed_event') }}
          </VListItemSubtitle>
        </VListItemContent>
      </VListItem>
      <VListItem v-if="isMerchant" :to="{ name: 'catalog'}" nav dense ripple>
        <VListItemAction>
          <VIcon>mdi-food</VIcon>
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle>
            {{ capitalize(t('catalog.self')) }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListItem v-if="canUseDiscount" :to="{ name: 'marketing'}" nav dense ripple>
        <VListItemAction>
          <VIcon>mdi-sale</VIcon>
        </VListItemAction>
        <VListItemContent>
          <VListItemTitle>
            {{ capitalize(t('form.marketing.self')) }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </VList>

    <VDivider />

    <RouterView name="event-menu" />
    <RouterView name="admin-menu" />

    <VSpacer />

    <div class="sidebar-footer">
      <VList nav dense>
        <VListItem nav @click="toggleMini()">
          <VListItemAction>
            <VIcon v-if="isDrawerMini">
              mdi-arrow-expand
            </VIcon>
            <VIcon v-else>
              mdi-arrow-collapse
            </VIcon>
          </VListItemAction>
          <VListItemContent>
            <VListItemTitle v-if="isDrawerMini">
              {{ t('general.expand') }}
            </VListItemTitle>
            <VListItemTitle v-else>
              {{ t('general.collapse') }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
      </VList>
      <VList>
        <VDivider />
        <VListItem class="align-content-space-between">
          <RouterLink :to="{ name: isAuthenticated ? 'dashboard' : 'auth-login' }">
            <img
              :class="{
                logo: true,
                custom: hasCustomLogo,
              }"
              :src="logoURL"
              alt="Logo"
            >
          </RouterLink>
          <VSpacer />
          <span
            :class="{'flex-column': hasCustomLogo}"
            class="grey--text caption align-self-center d-flex ml-4 text-end"
          >
            <span class="text-nowrap">{{ versionName }}</span>
            <span class="text-nowrap">&nbsp;&copy;&nbsp;{{ year }}</span>
          </span>
          <VSpacer />
        </VListItem>
      </VList>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { capitalize } from '@/util/Strings';
import UserMenu from '@/components/menu/UserMenu.vue';
import AccessControl from '@/security/AccessControl';
import izypayLogoUrl from '@/assets/images/logo.png';
import Meta from '@/Meta';
import store from '@/vuex/store';
import useEventStore from '@/stores/eventStore';
import { storeToRefs } from 'pinia';
import useAccessControl from '@/composables/accessControl';

const { event: currentEvent } = storeToRefs(useEventStore());

const { isAuthenticated, isClient, isMerchant } = useAccessControl();
const year = new Date().getFullYear();

const versionName = Meta.version ?? '-';

withDefaults(defineProps<{isDrawerMini?: boolean }>(), {
  isDrawerMini: false,
});

const emit = defineEmits<{ (e: 'collapse'): void }>();

const { t } = useI18n();

const currentUser = computed(() => store.getters['authModule/user']);
const hasCustomLogo = computed(() => !!currentUser.value?.custom_logo);
const logoURL = computed(() => hasCustomLogo.value ? currentUser.value.custom_logo : izypayLogoUrl);
const canUseDiscount = ref<boolean>(false);

watch(
  currentEvent,
  (ev) => (canUseDiscount.value = ev && isClient.value && AccessControl.eventHasFeatureFlag('discount.allow')),
  { immediate: true },
);

const toggleMini = () => emit('collapse');

</script>

<style lang="scss">
.sidebar-footer {
  img.logo {
    height: 1.2em;
    width: auto !important;

    &.custom {
      height: auto !important;
      width: 100% !important;
    }
  }
}

a {
  text-decoration: none;
}

.sidebar-content{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sidebar-footer {
  flex: 0 0 auto;
  margin: 0;
  bottom: 0;
  width: 100%;

  .v-list{
    padding-bottom: 0;

    .v-list__tile{
      padding-right: 0;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a3a3a3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3a3a3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}
</style>
