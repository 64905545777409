<template>
  <v-card class="floating-window" :class="{expanded: expanded}">
    <v-card-title>
      <v-btn small @click="toggleExpanded">
        Expand
      </v-btn>
    </v-card-title>
    <div v-if="expanded" class="floating-window-content">
      <v-card v-for="(session, i) in storedSessions" :key="session.id" class="session-card" :raised="i === currentSessionIndex">
        <v-card-title>#{{ i }} {{ (session.user && session.user.username) }}</v-card-title>
        <v-card-text>
          iat: {{ new Date(session.decodedToken.iat*1000)|date(false, "DD/MM/YYYY HH:mm:ss") }}<br>
          exp: {{ new Date(session.decodedToken.exp*1000)|date(false, "DD/MM/YYYY HH:mm:ss") }}
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SessionDebugger',
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapState('authModule', ['currentSessionIndex', 'storedSessions']),
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    forceExpireSession(session) {
      session.decodedToken.exp = 0;
    },
  },
};
</script>

<style scoped>
  .floating-window {
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 10000;
    height: 60px;
    overflow-y: hidden;
  }

  .floating-window.expanded {
    overflow-y: auto;
    height: 500px;
    max-width: 1500px;
    min-width: 250px;
  }

  .floating-window-content {
    padding: 15px;
  }

  .session-card {
    margin-bottom: 15px;
  }
</style>
