export function percentage(value: any): string {
  if (value === null || value === undefined) {
    return '-';
  }

  if (isNaN(value)) {
    return value as string;
  }

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function currency(amount: string | number) {
  const value = Number(amount);
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
}

export default {
  percentage,
};
