export default function(axios, eventId, merchantId) {
  return {
    list: (offset, limit) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('PointOfSales offset & limit parameters should be integers.'));
      }
      return axios.get(`/events/${eventId}/merchants/${merchantId}/pos`).then((response) => response.data);
    },
    get: (posId) => axios.get(`/events/${eventId}/merchants/${merchantId}/pos/${posId}`),
  };
}
