
export default function(axios, eventId) {
  return {
    /** @deprecated */
    list: () => {
      return axios.get(`/cashless/event/${eventId}/formulas`)
        .then((response) => response.data);
    },
    /** @deprecated */
    post: (formulaData) => {
      return axios.post(`/cashless/event/${eventId}/formulas`, formulaData)
        .then((response) => response.data);
    },
    /** @deprecated */
    put: (formulaId, formulaData) => {
      return axios.put(`/cashless/event/${eventId}/formulas/${formulaId}`, formulaData)
        .then((response) => response.data);
    },
    /** @deprecated */
    delete: (formulaId) => axios.delete(`/cashless/event/${eventId}/formulas/${formulaId}`),
  };
}
