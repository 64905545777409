import AccessControl from '@/security/AccessControl';

const EventMenu = () => import('@/components/menu/EventMenu.vue');
const ClickAndCollectIndex = () => import('@/views/event/click-and-collect/ClickAndCollectIndex.vue');
const ClickAndCollectNav = () => import('@/views/event/click-and-collect/ClickAndCollectNav.vue');
const ClickAndCollectDetails = () => import('@/views/event/click-and-collect/ClickAndCollectDetails.vue');
const ClickAndCollectSessions = () => import('@/views/event/click-and-collect/ClickAndCollectSessions.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'click-and-collect',
    name: 'click-and-collect',
    props: {
      default: true,
      'event-menu': true,
    },
    components: {
      default: ClickAndCollectIndex,
      'event-menu': EventMenu,
    },
    meta: {
      title: izypayTitlePrefix,
      requiresAuth: true,
      visible: () => (AccessControl.isClient() && AccessControl.eventHasFeatureFlag('feature_flag.click_and_collect')),
      eventMenu: {
        order: 6,
        icon: 'mdi-shopping-outline',
        transcode: 'click-and-collect.self',
      },
    },
  },
  {
    path: 'click-and-collect/:ccId',
    name: 'cc-nav',
    props: {
      default: true,
      'event-menu': true,
    },
    redirect: { name: 'cc-details' },
    components: {
      default: ClickAndCollectNav,
      'event-menu': EventMenu,
    },
    children: [
      {
        path: '',
        name: 'cc-details',
        props: {
          default: true,
          'event-menu': true,
        },
        meta: {
          requiresAuth: true,
        },
        components: {
          default: ClickAndCollectDetails,
          'event-menu': EventMenu,
        },
      },
      {
        path: 'sessions',
        name: 'cc-sessions',
        props: {
          default: true,
          'event-menu': true,
        },
        meta: {
          requiresAuth: true,
        },
        components: {
          default: ClickAndCollectSessions,
          'event-menu': EventMenu,
        },
      },
    ],
  },
];
