export default function(axios, merchantId) {
  return {
    list: (onlyForMerchant = false, offset = 0, limit = -1) => {
      return axios.get(`/merchants/${merchantId}/illustrations`, {
        params: {
          onlyForMerchant,
          offset,
          limit,
        },
      })
        .then((response) => response.data);
    },
    post: (illustrationData) => {
      return axios.post(`/merchants/${merchantId}/illustrations`, illustrationData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => response.data);
    },
    put: (illustrationId, illustrationData) => {
      return axios.put(`/merchants/${merchantId}/illustrations/${illustrationId}`, illustrationData)
        .then((response) => response.data);
    },
    delete(illustrationId) {
      return axios.delete(`/merchants/${merchantId}/illustrations/${illustrationId}`)
        .then((response) => response.data);
    },
  };
}
