import AccessControl from '@/security/AccessControl';

const EventMenu = () => import('@/components/menu/EventMenu.vue');

const CashlessIndex = () => import('@/views/event/cashless/CashlessIndex.vue');
const CashlessViewWallet = () => import('@/views/event/cashless/wallets/Wallet.vue');
const CashlessNav = () => import('@/views/event/cashless/CashlessNav.vue');
const CashlessRefund = () => import('@/views/event/cashless/CashlessRefund.vue');
const HolderNav = () => import('@/views/event/cashless/holder/HolderNav.vue');
const HolderView = () => import('@/views/event/cashless/holder/HolderView.vue');
const InvoiceList = () => import('@/views/event/cashless/invoice/InvoiceList.vue');
const MoneyWalletTransactionsIndex = () => import('@/views/event/cashless/transactions/MoneyWalletTransactionsIndex.vue');

export default [
  {
    path: 'cashless',
    name: 'event-cashless',
    props: {
      default: true,
      'event-menu': true,
    },
    redirect: { name: 'cashless-index' },
    components: {
      default: CashlessNav,
      'event-menu': EventMenu,
    },
    beforeEnter: AccessControl.isClientGuard,
    meta: {
      requiresAuth: true,
      visible: AccessControl.isClient,
      eventMenu: {
        order: 6,
        icon: 'izp-contactless-card',
        transcode: 'form.cashless.self',
      },
    },
    children: [
      {
        path: 'overview',
        name: 'cashless-index',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: CashlessIndex,
          'event-menu': EventMenu,
        },
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'transactions',
        name: 'cashless-transactions',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: MoneyWalletTransactionsIndex,
          'event-menu': EventMenu,
        },
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'refund',
        name: 'cashless-refund',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: CashlessRefund,
          'event-menu': EventMenu,
        },
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'wallet/:walletId',
        name: 'cashless-view-wallet',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: CashlessViewWallet,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'invoices',
        name: 'cashless-invoices',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: InvoiceList,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'holders/:holderId',
        name: 'holder-nav',
        props: {
          default: true,
          'event-menu': true,
        },
        redirect: { name: 'holder-view' },
        components: {
          default: HolderNav,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'details',
            name: 'holder-view',
            props: {
              default: true,
              'event-menu': true,
            },
            components: {
              default: HolderView,
              'event-menu': EventMenu,
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];
