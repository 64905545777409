export default {
  CLEAR(state) {
    state.stats = [];
  },
  SET_IS_MOBILE: (state, payload) => {
    // Set isMobile size.
    state.isMobile = payload;
  },
  SET_MENU_PINNED(state, pinned) {
    state.menu.pinned = pinned;
    // persist can't be used in mobile
    if (this.isMobile) {
      state.menu.pinned = false;
    }
  },
  SET_MENU_VISIBLE(state, visible) {
    state.menu.visible = visible;
  },

  SET_POS_PAYMENT_METHOD_SETTINGS_ALERT_OPENED(state, payload) {
    state.isPosPaymentMethodSettingsAlertOpened = payload;
  },
  SET_TABS_VISIBLE: (state, payload) => {
    state.tabsVisible = payload;
  },
  SET_APPBAR_EXTENSION_LEFT_COL: (state, payload) => {
    state.appBarExtensionLeftCol = (payload !== undefined) ? payload : false;
  },
  SET_TABLE_PROPERTY(state, { id, property, value }) {
    let table = state.tables[id];

    if (table === null || table === undefined) {
      state.tables[id] = {};

      table = state.tables[id];
    }

    table[property] = value;
  },
  SET_RECENT_ACTIVITY_DURATION: (state, payload) => {
    if (!state.recentActivity.durationChoices.includes(payload)) {
      return;
    }
    state.recentActivity.duration = payload;
  },
};
