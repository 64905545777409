import type { List } from '@/types/stores';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { Session } from '@/types/security';
import vuexStore from '@/vuex/store';
// TODO: Change for "import { useRouter } from 'vue-router" after update.
import router from '@/router';
import type { EventRef } from '@/types/models';

const sessions = ref<Array<Session>>([]);
// const session = ref<Session|null>(null);
const listLoading = ref<boolean>(false);
const listErrors = ref<Array<string>|null>(null);

const currentSession = computed<Session|null>(() => vuexStore.getters['authModule/session']);
const hasAdminSession = computed(() => vuexStore.getters['authModule/hasAdminSession']);

const currentAllowedEvents = computed<number[]>(() => currentSession.value?.user?.permissions.events ?? []);

async function setPendingImpersonation(eventId) {
  return vuexStore.dispatch('setPendingImpersonation', eventId);
}

const useSecurityStore = defineStore('security', () => {
  // TODO: Uncomment after update.
  // const router = useRouter();

  const sessionsList = computed<List<Session>>(() => ({
    list: sessions.value,
    loading: listLoading.value,
    errors: listErrors.value,
  }));

  const hasClient = computed(() => hasAdminSession.value || (currentSession.value && currentSession.value.user?.permissions.clients.length));
  const hasMerchant = computed(() => hasAdminSession.value || (currentSession.value && currentSession.value.user?.permissions.merchants.length));

  function getMerchants() {
    return hasMerchant.value ? currentSession.value?.user?.permissions.merchants : [];
  }

  function isAdmin(): boolean {
    return (sessions.value && sessions.value[0]?.user?.is_super_user) ?? false;
  }

  function allowedEvent(eventId) {
    return currentAllowedEvents.value.includes(eventId);
  }

  function canImpersonate() {
    const isSuperUser = currentSession.value?.user?.is_super_user ?? false;
    return hasAdminSession.value && !isSuperUser;
  }

  async function impersonateForEvent(event: EventRef) {
    if (!allowedEvent(event.id) && canImpersonate()) {
      await vuexStore.dispatch('authModule/switchToSession', { first: true });
      await setPendingImpersonation(event.id);
    } else {
      await setPendingImpersonation(null);
    }

    await router
      .push({ name: 'event-nav', params: { code: event.code, edition: event.edition } })
      // eslint-disable-next-line n/handle-callback-err
      .catch(() => { /** Silenced nav guard redirect */ })
    ;
  }

  return { sessionsList, currentSession, isAdmin, impersonateForEvent, hasClient, hasMerchant, getMerchants };
});

export default useSecurityStore;
