export default function(axios, eventId) {
  return {
    list: () => {
      return axios.get(`/cashless/event/${eventId}/wallets`)
        .then((response) => response.data);
    },
    listByIdentifiers: (identifiers) => {
      return axios.get(`/cashless/event/${eventId}/wallets/by-identifiers`, {
        params: {
          identifiers,
        },
      })
        .then((response) => response.data);
    },
    get: (walletId) => {
      return axios.get(`/cashless/event/${eventId}/wallets/${walletId}`)
        .then((response) => response.data);
    },
    payments: (walletId) => {
      return axios.get(`/cashless/event/${eventId}/wallets/${walletId}/payments`)
        .then((response) => response.data);
    },
    funds: (walletId) => {
      return axios.get(`/cashless/event/${eventId}/wallets/${walletId}/funds`)
        .then((response) => response.data);
    },
    refunds: (walletId) => {
      return axios.get(`/cashless/event/${eventId}/wallets/${walletId}/refunds`)
        .then((response) => response.data);
    },
    putTRCM: (walletId, walletData) => {
      return axios.put(`/cashless/wallets/${walletId}/trcm`, walletData)
        .then((response) => response.data);
    },
    putOverdraftMoneyWallet: (walletId, walletData) => {
      return axios.put(`/cashless/wallets/${walletId}/overdraft`, walletData)
        .then((response) => response.data);
    },
    postVoucher: (walletId, voucherData) => {
      return axios.post(`/cashless/event/${eventId}/wallets/${walletId}/vouchers`, voucherData)
        .then((response) => response.data);
    },
    tagWallet: (walletId, tagsData) => {
      return axios.post(`/cashless/wallets/${walletId}/tags`, tagsData)
        .then((response) => response.data);
    },
    untagWallet: (walletId, tagsData) => {
      return axios.delete(`/cashless/wallets/${walletId}/tags`, { data: tagsData })
        .then((response) => response.data);
    },
    createCoupon: (walletId, couponData) => {
      return axios.post(`/cashless/wallets/${walletId}/coupons`, couponData)
        .then((response) => response.data);
    },
  };
}
