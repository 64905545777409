export default {

  pushMessage({ commit }, { message, tone }) {
    commit('PUSH_MESSAGE', {
      message,
      tone,
    });
  },

  pushDialog({ commit }, { title, message, tone, callback }) {
    commit('PUSH_DIALOG', {
      title,
      message,
      tone,
      callback,
    });
  },

  clearDialog({ commit }) {
    commit('CLEAR_DIALOG');
  },

  removeHead({ commit }) {
    commit('REMOVE_HEAD');
  },

  clear: {
    root: true,
    handler({ commit }) {
      commit('CLEAR_ALL');
    },
  },
};
