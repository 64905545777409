import useAccessControl from '@/composables/accessControl';

const FleetNav = () => import('@/views/fleets/FleetNav.vue');
const FleetToolbar = () => import('@/views/fleets/FleetToolbar.vue');
const FleetDevices = () => import('@/views/fleets/FleetDevices.vue');
const FleetAffectations = () => import('@/views/fleets/FleetAffectations.vue');

const { isFleetManager, isFleetManagerGuard } = useAccessControl();

export default [
  {
    path: '/fleets',
    name: 'fleet-settings',
    props: {
      default: true,
      toolbar: true,
    },
    components: {
      default: FleetNav,
      toolbar: FleetToolbar,
    },
    beforeEnter: isFleetManagerGuard,
    meta: {
      requiresAuth: true,
      visible: () => isFleetManager.value,
      userMenu: {
        order: 3,
        icon: 'mdi-ballot-outline',
        transcode: 'userMenu.fleet',
      },
    },
    children: [
      {
        path: ':fleetId',
        name: 'fleet-index',
        redirect: { name: 'fleet-affectations', replace: true },
        props: true,
        children: [],
      },
      {
        path: ':fleetId/devices',
        name: 'fleet-devices',
        component: FleetDevices,
        props: true,
        beforeEnter: isFleetManagerGuard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':fleetId/affectations',
        name: 'fleet-affectations',
        props: true,
        component: FleetAffectations,
        beforeEnter: isFleetManagerGuard,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
