export default function(axios, eventId) {
  return {
    /** @deprecated */
    list: () => {
      return axios.get(`/cashless/event/${eventId}/models`)
        .then((response) => response.data);
    },
    /** @deprecated */
    post: (modelData) => {
      return axios.post(`/cashless/event/${eventId}/models`, modelData)
        .then((response) => response.data);
    },
    /** @deprecated */
    put: (modelId, modelData) => {
      return axios.put(`/cashless/event/${eventId}/models/${modelId}`, modelData)
        .then((response) => response.data);
    },
    /** @deprecated */
    delete: (modelId) => axios.delete(`/cashless/event/${eventId}/models/${modelId}`),
  };
}
