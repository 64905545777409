import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';
import colors from 'vuetify/es5/util/colors';
import en from 'vuetify/es5/locale/en';
import fr from 'vuetify/es5/locale/fr';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'mdi',
    values: {
      menu: 'fas fa-ellipsis-v',
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.lightBlue.darken4,
        secondary: colors.teal.lighten1,
        accent: '#eab651',
        gold: '#ac9456',
        blueGrey: colors.blueGrey.darken2,
        darkGrey: '#222222',
        error: colors.red.base,
        warning: colors.orange.accent3,
        info: colors.blue.base,
        success: colors.green.base,
        credit: colors.green.base,
        debit: colors.red.base,
      },
      dark: {
        primary: colors.lightBlue.darken4,
        secondary: colors.teal.lighten1,
        accent: '#eab651',
        gold: '#ac9456',
        blueGrey: colors.blueGrey.lighten2,
        darkGrey: '#222222',
        error: colors.red.lighten3,
        warning: colors.orange.accent3,
        info: colors.blue.base,
        success: colors.green.base,
        credit: colors.green.base,
        debit: colors.red.base,
      },
    },
  },
});
