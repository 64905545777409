import store from '@/vuex/store';

export default {

  /** @deprecated use "useAccessControl" composable instead */
  isAuthenticated: () => store.getters['authModule/authenticated'],
  /** @deprecated use "useAccessControl" composable instead */
  isAdmin: () => (store.getters['authModule/roles'] || []).includes('ROLE_SUPER_ADMIN'),
  /** @deprecated use "useAccessControl" composable instead */
  hasAdminSession: () => store.getters['authModule/hasAdminSession'],
  /** @deprecated use "useAccessControl" composable instead */
  isMerchant: () => (store.getters['authModule/roles'] || []).includes('ROLE_MERCHANT'),
  /** @deprecated use "useAccessControl" composable instead */
  isClient: () => (store.getters['authModule/roles'] || []).includes('ROLE_CLIENT'),
  /** @deprecated use "useAccessControl" composable instead */
  isDataViewer: () => (store.getters['authModule/roles'] || []).includes('ROLE_DATA_VIEWER'),

  isAdminGuard: (to, from, next) => {
    if (store.getters['authModule/roles'].includes('ROLE_SUPER_ADMIN')) {
      return next();
    }
    next(from);
  },

  isMerchantGuard: (to, from, next) => {
    if (
      store.getters['authModule/roles'].includes('ROLE_MERCHANT') ||
      store.getters['authModule/roles'].includes('ROLE_SUPER_ADMIN')
    ) {
      return next();
    }
    next(from);
  },

  isClientGuard: (to, from, next) => {
    if (store.getters['authModule/roles'].includes('ROLE_CLIENT')) {
      return next();
    }
    next(from);
  },

  impersonateIfAdmin: (to, from, next) => {
    try {
      if (
        (store.getters['authModule/roles'] || []).includes('ROLE_SUPER_ADMIN') &&
        from.name !== 'event-impersonate' &&
        to.name !== 'event-impersonate'
      ) {
        return next({
          name: 'event-impersonate',
          params: {
            code: to.params.code,
            edition: to.params.edition,
          },
        });
      } else next();
    } catch (e) {
      console.error(e);
    }
  },
  eventHasFeatureFlag: (flag) => {
    const configNames = store.getters.getCurrentEventConfigs?.map((c) => c.name) ?? [];
    return configNames.includes(flag);
  },
};
