export default function(axios, clientId) {
  return {
    list(includeSelf = false) {
      return axios.get(`/clients/${clientId}/merchants`, {
        params: {
          ...(includeSelf && { include_self: 'true' }),
        },
      })
        .then((response) => response.data);
    },
    post(merchantData) {
      return axios.post(`/clients/${clientId}/merchants`, merchantData)
        .then((response) => response.data);
    },
    delete(merchantId) {
      return axios.delete(`/clients/${clientId}/merchants/${merchantId}`)
        .then((response) => response.data);
    },
  };
}
