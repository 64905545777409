export default function(axios, eventId, stockId) {
  return {
    list: () => {
      return axios.get(`/events/${eventId}/stocks/${stockId}/items`)
        .then((response) => response.data);
    },
    get: (itemStockId) => {
      return axios.get(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}`)
        .then((response) => response.data);
    },
    post: (formData) => {
      return axios.post(`/events/${eventId}/stocks/${stockId}/items`, formData)
        .then((response) => response.data);
    },
    put: (itemStockId, formData) => {
      return axios.put(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}`, formData)
        .then((response) => response.data);
    },
    delete: (itemStockId) => {
      return axios.delete(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}`)
        .then((response) => response.data);
    },
    entries: (itemStockId) => {
      return axios.get(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}/entries`)
        .then((response) => response.data);
    },
    restock: (itemStockId, formData) => {
      return axios.post(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}/restock`, formData)
        .then((response) => response.data);
    },
    destock: (itemStockId, formData) => {
      return axios.post(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}/destock`, formData)
        .then((response) => response.data);
    },
    move: (itemStockId, formData) => {
      return axios.post(`/events/${eventId}/stocks/${stockId}/items/${itemStockId}/move`, formData)
        .then((response) => response.data);
    },
  };
}
