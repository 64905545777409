export default function(axios) {
  return {
    list: (offset = 0, limit = -1) => {
      return axios.get('/illustrations/', {
        params: {
          offset,
          limit,
        },
      })
        .then((response) => response.data);
    },
    post: (illustrationData) => {
      return axios.post('/illustrations', illustrationData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => response.data);
    },
    put: (illustrationId, illustrationData) => {
      return axios.put(`/illustrations/${illustrationId}`, illustrationData)
        .then((response) => response.data);
    },
    delete(illustrationId) {
      return axios.delete(`/illustrations/${illustrationId}`)
        .then((response) => response.data);
    },
  };
}
