import tags from './tags';
import { prepareAbortableRequest } from '@/api/cancellation';
import { hash } from '@/api/helpers/hash';

export default function(axios, eventId) {
  return {
    list: (
      limit = 10,
      offset = 0,
      search = null,
      filters = {},
    ) => {
      const requestHash = hash('list_holders').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/cashless/event/${eventId}/holders`, {
        params: {
          limit,
          offset,
          search,
          filters,
        },
        signal,
      })
        .then((response) => response.data);
    },
    get: (holderId, onlyEnabled = true) => {
      return axios.get(`/cashless/holder/${holderId}`, {
        params: {
          only_enabled: onlyEnabled,
          //  ^ This is not a typo
        },
      })
        .then((response) => response.data);
    },
    putProfile: (holderId, profileData) => {
      return axios.put(`/cashless/event/${eventId}/holders/${holderId}/profile`, profileData)
        .then((response) => response.data);
    },
    post: (holderData) => {
      return axios.post('/cashless/holders-with-profile', holderData)
        .then((response) => response.data);
    },
    delete: (holderId) => axios.delete(`/cashless/holders/${holderId}`),
    createWallet: (holderId, walletData) => {
      return axios.post(`/cashless/holders/${holderId}/wallets`, walletData)
        .then((response) => response.data);
    },
    import: (holdersData) => {
      return axios.post(`/cashless/event/${eventId}/holders/import`, holdersData, {
        timeout: 0,
        progress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            console.log(`${progressEvent.loaded} ${progressEvent.total}`);
          }
        },
      })
        .then((response) => response.headers['x-batch-id']);
    },
    tagsOf: (holderId) => tags(axios, eventId, holderId),
    createIdentifier: (holderId, identifierData) => {
      return axios.post(`/cashless/holders/${holderId}/identifiers`, identifierData)
        .then((response) => response.data);
    },
  };
}
