import _findIndex from 'lodash/findIndex';
import Vue from 'vue';

export default {
  STORE_OAUTH_CSRF_STATE(state, { oauthCsrfState }) {
    state.oauthCsrfState = oauthCsrfState;
  },

  STORE_OAUTH_PKCE(state, { oauthCodeVerifier }) {
    state.oauthCodeVerifier = oauthCodeVerifier;
  },

  STORE_PUBLIC_KEY(state, payload) {
    state.publicKey = payload;
  },

  STORE_SESSION(state, { decodedToken, token, refreshToken }) {
    if (decodedToken.sub === undefined) {
      return;
    }

    const newSession = {
      decodedToken,
      user: null,
      token,
      refreshToken,
      exp: decodedToken.exp || 0,
      id: decodedToken.sub,
    };

    let newStoredSession = [];
    // If we have more than one session, and that one is not a refreshing session
    if (state.storedSessions.length > 0 && state.storedSessions[0].id !== newSession.id) {
      newStoredSession = [state.storedSessions[0]];
    }

    newStoredSession.push(newSession);
    state.storedSessions = newStoredSession;
    state.currentSessionIndex = state.storedSessions.length - 1;
  },

  STORE_USER(state, { user }) {
    // This also replace the name in the decodedToken for BC, until we use a proper idToken for profile information
    const currentSession = state.storedSessions[state.currentSessionIndex];
    Vue.set(state.storedSessions, state.currentSessionIndex, {
      ...currentSession,
      user,
      decodedToken: { ...currentSession.decodedToken, name: user.full_name },
    });
  },

  SWITCH_SESSION(state, { session }) {
    const sessionIndex = _findIndex(state.storedSessions, (s) => s.id === session.id);
    if (sessionIndex !== -1) {
      state.currentSessionIndex = sessionIndex;
    }

    if (sessionIndex === 0) {
      // New: We clear all other sessions when switching back to first session
      state.storedSessions = [state.storedSessions[0]];
    }
  },

  SWITCH_TO_FIRST_SESSION(state) {
    state.currentSessionIndex = 0;
    state.storedSessions = [state.storedSessions[0]];
  },

  DELETE_SESSION(state, { session }) {
    const sessionIndex = _findIndex(state.storedSessions, (s) => s.id === session.id);
    if (sessionIndex !== -1 && sessionIndex !== state.currentSessionIndex) {
      Vue.delete(state.storedSessions, sessionIndex);
      if (state.currentSessionIndex >= sessionIndex) {
        state.currentSessionIndex--;
      }
    }
  },

  CLEAR_TOKENS(state) {
    Object.assign(state, {
      currentSessionIndex: -1,
      storedSessions: [],
      publicKey: state.publicKey,
    });
  },
};
