const EventMenu = () => import('@/components/menu/EventMenu.vue');

const DataExplore = () => import('@/views/event/data/DataExplore.vue');
const DataSales = () => import('@/views/event/data/DataSales.vue');
const DataRecap = () => import('@/views/event/data/DataRecap.vue');
const DataCashless = () => import('@/views/event/data/DataCashless.vue');
const DataNav = () => import('@/views/event/data/DataNav.vue');

const TransactionView = () => import('@/views/event/transaction/TransactionView');

export default [
  {
    path: 'data',
    name: 'event-data',
    props: {
      default: (route) => ({ date: route.query.date }),
      'event-menu': true,
    },
    redirect: { name: 'data-explore' },
    components: {
      default: DataNav,
      'event-menu': EventMenu,
    },
    meta: {
      requiresAuth: true,
      eventMenu: {
        order: 7,
        icon: 'mdi-finance',
        transcode: 'general.stats',
      },
    },
    children: [
      {
        path: 'explore',
        name: 'data-explore',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: DataExplore,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'sales',
        name: 'data-sales',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: DataSales,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'cashless',
        name: 'data-cashless',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: DataCashless,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'recap',
        name: 'data-recap',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: DataRecap,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'transactions/:transactionId',
        name: 'transaction-view',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: TransactionView,
          'event-menu': EventMenu,
        },
        meta: {
          h2: 'todo',
          requiresAuth: true,
        },
      },
    ],
  },
];
