import applicationRuleApi from './applicationRules';
import validityRuleApi from './validityRules';
import pointOfSalesApi from './pointsOfSales';
import automationRuleApi from './automationRules';

export default function(axios, clientId) {
  return {
    list: () => {
      return axios.get(`/clients/${clientId}/discounts`)
        .then((response) => response.data);
    },
    get(discountId) {
      return axios.get(`/clients/${clientId}/discounts/${discountId}`)
        .then((response) => response.data);
    },
    post: (discountData) => {
      return axios.post(`/clients/${clientId}/discounts`, discountData)
        .then((response) => response.data);
    },
    put: (discountId, discountData) => {
      return axios.put(`/clients/${clientId}/discounts/${discountId}`, discountData)
        .then((response) => response.data);
    },
    delete: (discountId) => {
      return axios.delete(`/clients/${clientId}/discounts/${discountId}`)
        .then((response) => response.data);
    },
    distribute: (discountId, discountData) => {
      return axios.put(`/clients/${clientId}/discounts/${discountId}/distribute`, discountData)
        .then((response) => response.headers['x-batch-id']);
    },
    distributeCoupons: (discountId, couponData) => {
      return axios.put(`/clients/${clientId}/discounts/${discountId}/distribute/coupons`, couponData)
        .then((response) => response.headers['x-batch-id']);
    },
    listSales: (discountId, offset = 0, limit = -1, sortBy = null, sortDesc = false) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(new Error('Discounts sales offset & limit parameters should be integers.'));
      }

      return axios.get(`/clients/${clientId}/discounts/${discountId}/sales`, {
        params: {
          offset,
          limit,
          sortBy,
          sortDesc,
        },
      })
        .then((response) => response.data);
    },
    listDistributions: (discountId) => {
      return axios.get(`/clients/${clientId}/discounts/${discountId}/distributions`)
        .then((response) => response.data);
    },
    applicationRulesOf(discountId) {
      return applicationRuleApi(axios, clientId, discountId);
    },
    validityRulesOf(discountId) {
      return validityRuleApi(axios, clientId, discountId);
    },
    pointsOfSalesOf(discountId) {
      return pointOfSalesApi(axios, clientId, discountId);
    },
    automationRulesOf(discountId) {
      return automationRuleApi(axios, clientId, discountId);
    },
  };
}
