export default {
  hasMessages(state) {
    return state.stack.length > 0;
  },

  stackLength(state) {
    return state.stack.length;
  },

  dialog(state) {
    return state.dialog;
  },

  head(state) {
    if (state.stack.length === 0) {
      return null;
    }

    return { ...state.stack[0] };
  },
};
