import _cloneDeep from 'lodash/cloneDeep';

export default {
  setTimespan({ commit }, payload) {
    commit('SET_TIMESPAN', _cloneDeep(payload));
  },

  clear: {
    root: true,
    handler({ commit }) {
      commit('RESET_DATA', null);
    },
  },
};
