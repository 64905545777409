<template>
  <VDialog v-model="dialog.open" width="525px">
    <VCard>
      <VCardTitle class="headline">
        {{ dialog.title }}
      </VCardTitle>
      <VCardText>
        {{ dialog.message }}
      </VCardText>
      <VCardActions>
        <VLayout justify-end>
          <VBtn text @click="setResponse(false)">
            {{ t('general.no') }}
          </VBtn>
          <VBtn text :color="dialog.color" @click="setResponse(true)">
            {{ t('general.yes') }}
          </VBtn>
        </VLayout>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import useConfirmDialog from '@/composables/confirmDialog';
import { useI18n } from 'vue-i18n-bridge';

const { dialog, setResponse } = useConfirmDialog();
const { t } = useI18n();
</script>
