import operatorsApi from './operators';

export default function(axios, merchantId) {
  return {
    export: (formData) => {
      return axios.post(`/merchants/${merchantId}/stocks/export`, formData)
        .then((response) => response.data);
    },
    operators() {
      return operatorsApi(axios, merchantId);
    },
  };
}
