export default function(axios, eventId) {
  return {
    list: () => {
      return axios.get(`/events/${eventId}/stocks/operator-users`)
        .then((response) => response.data);
    },
    get: (operatorId) => {
      return axios.get(`/events/${eventId}/stocks/operator-users/${operatorId}`)
        .then((response) => response.data);
    },
    post: (formData) => {
      return axios.post(`/events/${eventId}/stocks/operator-users`, formData)
        .then((response) => response.data);
    },
    delete: (operatorId) => {
      return axios.delete(`/events/${eventId}/stocks/operator-users/${operatorId}`)
        .then((response) => response.data);
    },
  };
}
