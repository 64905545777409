
import type { Item } from '@/types/catalog';
import type { PaginatedResult, PaginationParams } from '@/types/models';
import clientApi from '@/api/endpoints/merchants/clients';
import userApi from '@/api/endpoints/merchants/users';
import itemTagsApi from '@/api/endpoints/merchants/itemTags';
import bankingApi from '@/api/endpoints/merchants/banking';
import illustrationsApi from '@/api/endpoints/merchants/illustrations';
import stocksApi from '@/api/endpoints/merchants/stocks';
import scheduledTaksApi from '@/api/endpoints/merchants/scheduledTasks';

export default function(axios) {
  return {
    list: (params: PaginationParams) => {
      return axios.get('/merchants', { params })
        .then((response) => response.data);
    },
    get: (merchantId) => {
      return axios.get(`/merchants/${merchantId}`)
        .then((response) => response.data);
    },
    listItems: (merchantId: string | number, params: PaginationParams): PaginatedResult<Item> => {
      return axios.get(`/merchants/${merchantId}/item-models`, { params })
        .then((response) => response.data);
    },
    listArchivedItems: (merchantId: string | number, params: PaginationParams): PaginatedResult<Item> => {
      return axios.get(`/merchants/${merchantId}/item-models/archived`, { params })
        .then((response) => response.data);
    },
    massArchiveItemModels: (merchantId, items) => {
      return axios.post(`/merchants/${merchantId}/catalog/archive`, items);
    },
    massDeleteItems: (merchantId, items) => {
      return axios.post(`/merchants/${merchantId}/catalog/delete`, items);
    },
    listPoses: (merchantId, offset: number = 0, limit: number = 20) => {
      return axios.get(`/merchants/${merchantId}/poses?limit=${limit}&offset=${offset}`)
        .then((response) => response.data);
    },
    getByVATNumber: (VATNumber) => {
      return axios.get(`/merchants/vat/${VATNumber}`)
        .then((response) => response.data);
    },
    post: (merchantData) => {
      return axios.post('/merchants', merchantData)
        .then((response) => response.data);
    },
    put: (merchantId, merchantData) => {
      return axios.put(`/merchants/${merchantId}`, merchantData)
        .then((response) => response.data);
    },
    putLegalNotices: (merchantId, legalNoticesData) => {
      return axios.put(`/merchants/${merchantId}/legal-notices`, legalNoticesData)
        .then((response) => response.data);
    },
    clientsOf(merchantId) {
      return clientApi(axios, merchantId);
    },
    usersOf(merchantId) {
      return userApi(axios, merchantId);
    },
    itemsCount(merchantId) {
      return axios.get(`/merchants/${merchantId}/catalog/count`)
        .then((response) => response.data);
    },
    emptyCatalog(merchantId) {
      return axios.post(`/merchants/${merchantId}/catalog/empty`);
    },
    listComposedItems: (merchantId, offset = 0, limit = -1) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('Items offset & limit parameters should be integers.'));
      }

      return axios.get(`/merchants/${merchantId}/composed-items?limit=${limit}&offset=${offset}`)
        .then((response) => response.data);
    },
    itemTagsOf: (merchantId) => {
      return itemTagsApi(axios, merchantId);
    },
    bankingOf: (merchantId) => {
      return bankingApi(axios, merchantId);
    },
    illustrationsOf: (merchantId) => {
      return illustrationsApi(axios, merchantId);
    },
    stocksOf(merchantId) {
      return stocksApi(axios, merchantId);
    },
    scheduledTasksOf: (merchantId) => scheduledTaksApi(axios, merchantId),
    bankingConfig: {
      list: (params: PaginationParams) => {
        return axios.get('/admin/banking/configs', { params })
          .then((response) => response.data);
      },
      get(configId) {
        return axios.get(`/admin/banking/configs/${configId}`);
      },
    },
    listScheduledTasks: (params: PaginationParams) => {
      return axios.get('/admin/scheduled-tasks', { params })
        .then((response) => response.data);
    },
    executeTask: (taskId: number, data) => {
      return axios.post(`/admin/scheduled-tasks/${taskId}/execute`, data)
        .then((response) => response.data);
    },
  };
}
