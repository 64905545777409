export default function(axios) {
  return {
    list: () => {
      return axios.get('/cashless/codes/batches')
        .then((response) => response.data);
    },
    generateBatch: (batchData) => {
      return axios.post('/cashless/codes/batches', batchData)
        .then((response) => response.data);
    },
    sendBatch: (batchId) => {
      return axios.post(`/cashless/codes/batches/${batchId}/send`)
        .then((response) => response.data);
    },
    importCodes: (codes) => {
      return axios.post('/cashless/codes/import', codes, { timeout: 60000 })
        .then((response) => response.data);
    },
    listImportBatches: () => {
      return axios.get('/cashless/codes/import/batches')
        .then((response) => response.data);
    },
  };
}
