export default function(axios) {
  return {
    list: () => {
      return axios.get('/batches')
        .then((response) => response.data);
    },
    listDistributeCoupons: (clientId) => {
      return axios.get(`/batches/distribute-coupons/${clientId}`)
        .then((response) => response.data);
    },
    listDistributeDiscounts: (clientId) => {
      return axios.get(`/batches/distribute-discounts/${clientId}`)
        .then((response) => response.data);
    },
    listHolderImport: (eventId) => {
      return axios.get(`/batches/holder-import/${eventId}`)
        .then((response) => response.data);
    },
    get: (batchId, options) => {
      const signal = options?.signal;
      return axios.get(`/batches/${batchId}`, { signal })
        .then((response) => response.data);
    },
  };
}
