import useAccessControl from '@/composables/accessControl';

const ClientMerchantsList = () => import('@/views/organizer/ClientMerchantsList.vue');
const OrganizerNav = () => import('@/views/organizer/OrganizerNav.vue');
const OrganizerServiceAccounts = () => import('@/views/organizer/OrganizerServiceAccounts.vue');

const { isClient, isClientGuard } = useAccessControl();

export default [
  {
    path: '/organizer-settings',
    name: 'organizer-settings',
    props: true,
    redirect: { name: 'organizer-service-accounts' },
    component: OrganizerNav,
    beforeEnter: isClientGuard,
    meta: {
      requiresAuth: true,
      visible: () => isClient.value,
      userMenu: {
        order: 2,
        icon: 'mdi-domain',
        transcode: 'userMenu.organizer',
      },
    },
    children: [
      {
        path: 'service-accounts',
        name: 'organizer-service-accounts',
        props: true,
        component: OrganizerServiceAccounts,
        beforeEnter: isClientGuard,
        meta: {
          requiresAuth: true,

        },
      },
      {
        path: 'merchants',
        name: 'organizer-merchants',
        props: true,
        component: ClientMerchantsList,
        beforeEnter: isClientGuard,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
