import AccessControl from '@/security/AccessControl';

const EventMenu = () => import('@/components/menu/EventMenu.vue');

const StockList = () => import('@/views/event/stock/StockList.vue');
const StockView = () => import('@/views/event/stock/StockView.vue');
const StockNav = () => import('@/views/event/stock/StockNav.vue');
const StockOrdersList = () => import('@/views/event/stock/StockOrdersList.vue');
const OperatorList = () => import('@/views/event/stock/operator/OperatorList.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'stock',
    name: 'event-stock',
    props: {
      default: true,
      'event-menu': true,
    },
    components: {
      default: StockNav,
      'event-menu': EventMenu,
    },
    meta: {
      title: izypayTitlePrefix,
      requiresAuth: true,
      selectMerchant: true,
      visible: () => AccessControl.isClient() || AccessControl.isMerchant(),
      eventMenu: {
        order: 4,
        icon: 'mdi-warehouse',
        transcode: 'form.stock.self',
      },
    },
    children: [
      {
        path: 'm/:merchantId',
        name: 'stock-list',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: StockList,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
          selectMerchant: true,
        },
      },
      {
        path: 'm/:merchantId/warehouse/:stockId',
        name: 'stock-view',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: StockView,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
          selectMerchant: true,
        },
      },
      {
        path: 'm/:merchantId/orders',
        name: 'stock-orders',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: StockOrdersList,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
          selectMerchant: true,
        },
      },
      {
        path: 'm/:merchantId/operators',
        name: 'stock-operators',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: OperatorList,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
          selectMerchant: true,
        },
      },
    ],
  },
];
