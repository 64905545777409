export default function useTree() {
  const flatten = (tree) => {
    const traverse = (node) => {
      return (node.children ? node.children : [])
        .concat(...(node.children ? node.children : []).map((child) => traverse(child)));
    };
    return traverse(tree);
  };

  return {
    flatten,
  };
}
