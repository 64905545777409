export default function(axios) {
  return {
    get: (eventId) => {
      return axios.get(`/refund/campaigns/${eventId}`)
        .then((response) => response.data);
    },
    post(campaignData, redirectUri) {
      return axios.post(`/refund/campaigns?redirect_uri=${redirectUri}`, campaignData)
        .then((response) => response.data);
    },
    put(eventId, redirectUri, campaignData) {
      return axios.put(`/refund/campaigns/${eventId}?redirect_uri=${redirectUri}`, campaignData)
        .then((response) => response.data);
    },
    resentValidationEmail(eventId, redirectUri) {
      return axios.get(`/refund/campaigns/resend/${eventId}?redirect_uri=${redirectUri}`)
        .then((response) => response.data);
    },
    addBanner(eventId, campaignData) {
      return axios.post(`/refund/campaigns/${eventId}/banner`, campaignData)
        .then((response) => response.data);
    },
    deleteBanner(eventId) {
      return axios.delete(`/refund/campaigns/${eventId}/banner`)
        .then((response) => response.data);
    },
  };
}
