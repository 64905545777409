import ColorHelper from '@/util/ColorHelper';
import Strings from '@/util/Strings';

const displayHelpers = {
  data: () => ({
    placeholder: [],
  }),
  methods: {
    /**
     * Returns a random color string (made of a color code and a variant code and a text color constrasted with BG)
     * Example : 'deep-purple lighten-4 white--text'
     *
     * @param index Integer value to modulo, for pseudo-random color resolution
     * @returns {string}
     */
    randomColor(index) {
      return ColorHelper.randomColor(index);
    },

    getHexColor(event) {
      return ColorHelper.classNameToHexString(this.getColor(event));
    },

    lighten(color) {
      return this.getColor(event);
    },

    getColor(obj) {
      if (!obj || typeof obj.name === 'undefined') {
        const objString = JSON.stringify(obj);
        obj = {
          id: objString.length,
          name: Math.floor(this.hashCode(objString)).toString(2),
        };
      }
      const length = obj.name.length;
      let id = obj.id;
      if (isNaN(id)) {
        // id is not a number
        const objString = JSON.stringify(obj);
        id = objString.length;
      }
      const idx = id + length;
      return this.randomColor(idx);
    },

    usePlaceholder($event, eventId) {
      this.placeholder[eventId] = true;
    },
    hasPlaceholder(eventId) {
      return this.placeholder[eventId] === true;
    },
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      document.querySelectorAll('.copy-content').forEach((elem) => (elem.textContent = 'Copié'));
    },
    hashCode(str) {
      return str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
    },
  },
};

// Export.
export default displayHelpers;
