const abortMap = new Map();

export const prepareAbortableRequest = (hash: string): AbortSignal => {
  abortMap.get(hash)?.abort();
  abortMap.set(hash, new AbortController());

  return abortMap.get(hash).signal;
};

export default function(axiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async(error) => {
      if (error?.name === 'CanceledError') {
        return new Promise(() => {});
      }

      throw error;
    },
  );
}
