import Qs from 'qs';

export default function(axios, eventId) {
  return {
    fund: (startDate, endDate, options) => {
      return axios.get(`/data/events/${eventId}/cashless/fund`, {
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    refund: (startDate, endDate, options) => {
      return axios.get(`/data/events/${eventId}/cashless/refund`, {
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      })
        .then((response) => response.data);
    },
    activations: (startDate, endDate, options) => {
      return axios.get(`/data/events/${eventId}/cashless/activations`, {
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      })
        .then((response) => response.data);
    },
    blacklist: (startDate, endDate, options) => {
      return axios.get(`/data/events/${eventId}/cashless/blacklist`, {
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      })
        .then((response) => response.data);
    },
    flow: (startDate, endDate, options) => {
      return axios.get(`/data/events/${eventId}/cashless/flow`, {
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      })
        .then((response) => response.data);
    },
  };
}
