
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/vuex/store';

import authRoutes from './auth';
import adminRoutes from './admin/';
import eventsRoutes from './events/';
import organizerRoutes from './organizer';
import merchantRoutes from './merchant';
import catalogRoutes from './catalog';
import marketingRoutes from './marketing';
import fleetRoutes from './fleet';

const UserMenu = () => import('@/components/menu/UserMenu.vue');
const IzpLayout = () => import('@/components/layout/IzpLayout.vue');

const Dashboard = () => import('@/views/Dashboard.vue');
const AccountPage = () => import('@/views/AccountPage.vue');
const PageNotFound = () => import('@/views/PageNotFound.vue');
const DashboardToolbar = () => import('@/components/layout/toolbar/DashboardToolbar.vue');

Vue.use(Router);

// Prevent navigation failures to bubble up to calls
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const router = new Router({
  base: import.meta.env.BASE_URL,
  mode: 'history',
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'root',
      component: IzpLayout,
      redirect: { name: 'dashboard' },
      meta: {
        title: 'Izypay Hub',
      },
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          props: {
            default: true,
          },
          components: {
            default: Dashboard,
            toolbar: DashboardToolbar,
          },
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/account',
          name: 'account',
          components: {
            default: AccountPage,
          },
          meta: {
            requiresAuth: true,
            userMenu: {
              order: 1,
              icon: 'mdi-account-circle-outline',
              transcode: 'userMenu.account',
            },
          },
        },
        ...eventsRoutes,
        ...catalogRoutes,
        ...adminRoutes,
        ...fleetRoutes,
        ...merchantRoutes,
      ],
    },
    ...authRoutes,
    // The next 2 groups of routes should be nested under 'root' to benefit of the base Layout
    ...organizerRoutes, // TODO Move to children of 'root'
    ...marketingRoutes, // TODO Move to children of 'root'
    {
      path: '*',
      name: 'catch-all',
      components: {
        default: PageNotFound,
        'user-menu': UserMenu,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const toRequiresAuth = to.meta.requiresAuth === undefined ? true : to.meta.requiresAuth;

  if (to.name === 'catch-all') {
    return next();
  }

  if (!store.getters['authModule/authenticated']) {
    if (toRequiresAuth) {
      return next({ name: 'auth-login', replace: true });
    }
    return next();
  }

  // Prevent NavigationDuplicated errors
  if (to.path === from.path) {
    return false;
  }

  next();
});

export default router;
