import itemsApi from './items';
import { hash } from '@/api/helpers/hash';
import operatorsApi from './operators';
import { prepareAbortableRequest } from '@/api/cancellation';

export default function(axios, eventId) {
  return {
    list: () => {
      return axios.get(`/events/${eventId}/stocks`)
        .then((response) => response.data);
    },
    get: (stockId) => {
      return axios.get(`/events/${eventId}/stocks/${stockId}`)
        .then((response) => response.data);
    },
    history: (stockId) => {
      return axios.get(`/events/${eventId}/stocks/${stockId}/history`)
        .then((response) => response.data);
    },
    post: (formData) => {
      return axios.post(`/events/${eventId}/stocks`, formData)
        .then((response) => response.data);
    },
    duplicate: (formData) => {
      return axios.post(`/events/${eventId}/stocks/duplicate`, formData)
        .then((response) => response.data);
    },
    delete: (stockId) => {
      return axios.delete(`/events/${eventId}/stocks/${stockId}`)
        .then((response) => response.data);
    },
    update: (stockId, formData) => {
      return axios.put(`/events/${eventId}/stocks/${stockId}`, formData)
        .then((response) => response.data);
    },
    itemsOf(stockId) {
      return itemsApi(axios, eventId, stockId);
    },
    export: (formData) => {
      return axios.post(`/events/${eventId}/stocks/export`, formData)
        .then((response) => response.data);
    },
    importForMerchant: (merchantId, stockMovements) => {
      return axios.post(`/events/${eventId}/stocks/merchants/${merchantId}/import`, stockMovements, { timeout: 60000 })
        .then((response) => response.data);
    },
    movementsPerInterval: (stockId, startDate, endDate, options) => {
      const requestHash = hash('get_stock_movements').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/events/${eventId}/stocks/${stockId}/item-stocks/movements-per-interval`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      })
        .then((response) => response.data);
    },
    operatorsOf() {
      return operatorsApi(axios, eventId);
    },
  };
}
