export default function(axios) {
  return {
    list: (offset = 0, limit = -1) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('Items offset & limit parameters should be integers.'));
      }
      return axios.get(`/composed-items?limit=${limit}&offset=${offset}`)
        .then((response) => response.data);
    },
    get(itemId) {
      return axios.get(`/composed-items/${itemId}`)
        .then((response) => response.data);
    },
    post(itemData) {
      return axios.post('/composed-items', itemData)
        .then((response) => response.data);
    },
    put(itemId, itemData) {
      return axios.put(`/composed-items/${itemId}`, itemData)
        .then((response) => response.data);
    },
    delete(itemId) {
      return axios.delete(`/composed-items/${itemId}`)
        .then((response) => response.data);
    },
    history: (itemId) => {
      return axios.get(`/composed-items/${itemId}/history`)
        .then((response) => response.data);
    },
  };
}
