import _filter from 'lodash/filter';

export default {
  currentSessionIndex(state) {
    if (!state.currentSessionIndex || state.currentSessionIndex === -1) {
      return 0;
    }

    return state.currentSessionIndex;
  },
  session(state) {
    if (state.currentSessionIndex === -1) {
      return null;
    }

    return state.storedSessions[state.currentSessionIndex] || null;
  },
  authenticated(state, getters) {
    return getters.session && getters.user;
  },
  token(state, getters) {
    const sess = getters.session;
    return !sess ? null : sess.token;
  },
  refreshToken: (state, getters) => getters.session?.refreshToken,
  permissions: (state, getters) => getters?.user?.permissions,
  roles: (state, getters) => getters.permissions?.roles,
  user(state, getters) {
    return getters.session?.user;
  },
  httpAuthorizationHeader(state, getters) {
    return `Bearer ${getters.token}`;
  },
  hasAdminSession(state) {
    return state.storedSessions &&
      state.storedSessions[0] &&
      state.storedSessions[0].user &&
      state.storedSessions[0].user.is_super_user;
  },
  availableSessions(state) {
    return _filter(state.storedSessions, (value, index) => index !== state.currentSessionIndex);
  },
};
