<template>
  <div v-hotkey="keymap">
    <component :is="wrapper" :to="portalTarget">
      <template v-if="isTooltip">
        <v-tooltip
          v-for="{keys, text} in helperKeys"
          :key="keys.join('')"
          color="rgba(255, 255, 255, 0.9)"
          :right="tooltipRight"
          :left="tooltipLeft"
        >
          <template #activator="{on}">
            <Key
              v-for="key in keys"
              :key="key"
              :key-text="key"
              v-on="on"
            />
          </template>
          <span v-if="text">
            <v-icon small left color="grey">
              mdi-keyboard-variant
            </v-icon>
            <small class="black--text">{{ text }}</small>
          </span>
        </v-tooltip>
      </template>
      <template v-else>
        <div
          v-for="{keys, text} in helperKeys"
          :key="keys.join('')"
        >
          <Key
            v-for="key in keys"
            :key="key"
            :key-text="key"
          />
          <span v-if="text">
            <v-icon small left color="grey">
              mdi-keyboard-variant
            </v-icon>
            <small class="black--text">{{ text }}</small>
          </span>
        </div>
      </template>
    </component>
    <slot name="default" />
  </div>
</template>

<script>

import { Portal } from 'portal-vue';
import Key from '@/components/izp/Key.vue';

export default {
  name: 'HotkeySpot',
  components: {
    Key,
    Portal,
  },
  props: {
    keymap: {
      type: Object,
      required: true,
    },
    helpers: {
      type: Array,
      required: false,
      default: () => [],
    },
    portalTarget: {
      type: String,
      required: false,
      default: null,
    },
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    wrapper() {
      return this.portalTarget !== null ? 'Portal' : 'div';
    },
    helperKeys() {
      if (this.keymap.length === 0) {
        return [];
      }

      if (this.helpers.length > 0) {
        return this.customHelpers;
      }

      return Object.keys(this.keymap).map((keys) => ({
        keys: keys.split('+').map((k) => k.toUpperCase()),
        text: '',
      }));
    },
    customHelpers() {
      return this.helpers;
    },
    isTooltip() {
      return this.tooltip !== null;
    },
    tooltipLeft() {
      return this.tooltip === 'left';
    },
    tooltipRight() {
      return this.tooltip === 'right';
    },
  },
};
</script>

<style scoped>

</style>
