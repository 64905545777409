const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const CashlessNav = () => import('@/views/admin/cashless/CashlessNav.vue');
const CashlessCodeIndex = () => import('@/views/admin/cashless/CashlessCodeIndex.vue');
const RefundCampaignIndex = () => import('@/views/admin/cashless/RefundCampaignIndex.vue');

export default [
  {
    path: 'cashless',
    name: 'admin-cashless',
    props: {
      default: true,
      'admin-menu': false,
    },
    components: {
      default: CashlessNav,
      'admin-menu': AdminMenu,
    },
    redirect: { name: 'admin-cashless-codes' },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'codes',
        name: 'admin-cashless-codes',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: CashlessCodeIndex,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'refund-campaign',
        name: 'admin-cashless-refund-campaign',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: RefundCampaignIndex,
          'admin-menu': AdminMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
