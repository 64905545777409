<template>
  <VContainer light class="px-0">
    <VRow v-if="currentSession && currentSession.user" class="pa-3">
      <VCol class="flex-grow-0 flex-shrink-1">
        <VAvatar size="40px" :color="getColor(currentSession.user.id)">
          <img v-if="photoOrFallback(currentSession.user) !== null" :src="photoOrFallback(currentSession.user)" alt="">
          <span v-else class="white--text pa-2">{{ upper(initialize(currentSession.user.name)) }}</span>
        </VAvatar>
      </VCol>
      <VCol class="pa-2">
        <h1 class="title mb-0">
          {{ currentSession.user.name }}
        </h1>
        <h3 class="subtitle-2 grey--text mt-0">
          {{ currentSession.user.email }}
        </h3>
      </VCol>
    </VRow>
    <VList class="pa-0">
      <template v-for="(entry, i) in menuEntries">
        <VDivider :key="`divider-${i}`" />
        <VListItem
          :key="`item-list-${i}`"
          :to="{ name: entry.name }"
          dense
          ripple
        >
          <VListItemAction>
            <VIcon>
              {{ entry.meta.userMenu.icon }}
            </VIcon>
          </VListItemAction>
          <VListItemContent>
            <VListItemTitle>
              {{ t(entry.meta.userMenu.transcode) }}
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
      </template>
    </VList>
    <VDivider />

    <template v-if="availableSessions.length > 0">
      <VList two-line class="pa-0">
        <VListItem
          v-for="session in availableSessions" :key="session.id"
          :class="{'bg-highlight': session.refreshToken !== undefined }"
          ripple
          @click="switchUser(session)"
        >
          <VListItemAvatar size="36" :color="getColor(session.user.id)">
            <VAvatar size="36">
              <img v-if="photoOrFallback(session.user) !== null" :src="photoOrFallback(session.user)" alt="">
              <span v-else class="subtitle-2 white--text">{{ initialize(session.user?.name ?? '') }}</span>
            </VAvatar>
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle class="subtitle-2">
              {{ (session.user && session.user.name)||'Invalid session' }}
            </VListItemTitle>
            <VListItemSubtitle class="caption">
              {{ (session.user && session.user.email)||'invalid session' }}
            </VListItemSubtitle>
          </VListItemContent>

          <VListItemIcon v-if="session.refreshToken !== undefined" class="my-6">
            <VIcon
              color="accent darken-2"
            >
              mdi-security
            </VIcon>
          </VListItemIcon>
        </VListItem>
      </VList>

      <VDivider />
    </template>

    <VRow class="grey lighten-3 mt-0 pa-1 px-4">
      <VBtn v-if="hasAdminSession" text small color="accent darken-2" @click="goToAdmin">
        <VIcon left small>
          mdi-security
        </VIcon>
        Administration
      </VBtn>
      <VSpacer />
      <VBtn text small color="primary" @click="doLogout">
        {{ t('general.logout') }}
      </VBtn>
    </VRow>

    <VDialog v-model="locked" width="100%" persistent no-click-animation>
      <VOverlay
        :value="locked"
        color="transparent"
        light
      >
        <div class="flex flex-column text-center">
          <VProgressCircular color="accent" width="3" indeterminate size="64" /><br>
          <span>{{ t('general.loading') }}</span>
        </div>
      </VOverlay>
    </VDialog>
  </VContainer>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import useColors from '@/composables/colors';
import { useRouter } from 'vue-router/composables';
import { initialize, upper } from '@/util/Strings';
import useAccessControl from '@/composables/accessControl';
import { storeToRefs } from 'pinia';
import useSecurityStore from '@/stores/securityStore';
import store from '@/vuex/store';
import useTree from '@/composables/useTree';
import useEventStore from '@/stores/eventStore';

type Props = {
  value?: boolean,
};

type MenuEntry = {
  name: string,
  meta: {
    userMenu: {
      icon: string,
      transcode: string,
    }
  }
}

withDefaults(defineProps<Props>(), {
  value: false,
});

const { getColor } = useColors();
const { hasAdminSession } = useAccessControl();
const { t } = useI18n();
const { currentSession } = storeToRefs(useSecurityStore());
const { event: currentEvent } = storeToRefs(useEventStore());

const { flatten } = useTree();
const router = useRouter();

const availableSessions = computed(() => store.getters['authModule/availableSessions']);

const locked = ref(false);
const menuEntries = ref<MenuEntry[]>([]);

watch(currentEvent, (event, prevEvent) => {
  if (!event || event.id === prevEvent?.id) return;

  menuEntries.value = flatten({ children: router.options.routes })
    .filter((route) => {
      let visible = true;
      if (route.meta && route.meta.visible) {
        visible = route.meta.visible();
      }
      return route.meta && route.meta.userMenu && visible;
    })
    .sort((a, b) => {
      if (a.meta.userMenu.order === b.meta.userMenu.order) {
        return 0;
      }
      return a.meta.userMenu.order > b.meta.userMenu.order ? 1 : -1;
    });
}, { immediate: true });

const doLogout = async() => {
  await store.dispatch('authModule/logout');
  await router.push({ name: 'auth-login' });
};

const photoOrFallback = (user) => user?.profile?.photo?.url['150'] ?? null;

const goToAdmin = async() => {
  if (!hasAdminSession.value) return;

  // session 0 is Admin
  await store.dispatch('authModule/switchToSession', { first: true });
  await router.push({ name: 'admin-index' });
};

const switchUser = async(session) => {
  const isUnavailable = await store.dispatch('authModule/isUnavailable', session); // session.refreshToken !== undefined;
  if (isUnavailable && session.refreshToken === undefined) {
    await store.dispatch('authModule/deleteSession', { session });
  }
  locked.value = true;
  try {
    await store.dispatch('authModule/switchToSession', { session });
    const time = +new Date();
    await router.push({ name: 'dashboard', query: { t: time.toString() } });
  } finally {
    locked.value = false;
  }
};

</script>

<style scoped>
v-container, .container {
  background: white;
  color: #000;
}

.unavailable-session {
  opacity: 0.5;
}

#user-switch-mask {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background: red;
}

.delete-session-btn {
  margin-right: -10px !important;
}
</style>
