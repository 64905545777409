export default function(axios, merchantId) {
  return {
    listPermissions() {
      return axios.get(`/merchants/${merchantId}/users/permissions`)
        .then((response) => response.data);
    },
    link(userData) {
      return axios.post(`/merchants/${merchantId}/users`, userData)
        .then((response) => response.data);
    },
    deletePermission(permissionId) {
      return axios.delete(`/merchants/${merchantId}/users/permissions/${permissionId}`)
        .then((response) => response.data);
    },
  };
}
