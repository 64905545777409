import { hash } from '@/api/helpers/hash';
import { prepareAbortableRequest } from '@/api/cancellation';

export default function(axios) {
  return {
    getSales: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_sales').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/sales`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    getPaymentsRepartition: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_payments_repartition').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/payments`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    getTaxes: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_taxes').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/taxes`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    getTotals: (eventId, startDate, endDate, options) => {
      const requestHash = hash(`get_event_totals_${JSON.stringify(options)}`).toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/totals`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    getFundsRepartition: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_funds_repartition').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/funds`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    getRefundsRepartition: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_refunds_repartition').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/refunds`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    getSalesByDate: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_sales').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/sales-by-date`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
    datesBoundariesOf(eventId) {
      return axios.get(`/data/events/${eventId}/boundaries`)
        .then((response) => response.data);
    },
    getGrantedDiscounts: (eventId, startDate, endDate, options) => {
      const requestHash = hash('get_event_discounts').toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/data/events/${eventId}/discounts`, {
        signal,
        params: {
          startDate,
          endDate,
          filters: options.filters || {},
        },
      }).then((response) => response.data);
    },
  };
}
