import UsersRoutes from '@/router/admin/user';
const AdminMenu = () => import('@/components/menu/AdminMenu.vue');
const AdminIndex = () => import('@/views/admin/AdminIndex.vue');

export default [
  ...UsersRoutes,
  {
    path: '',
    name: 'admin-index',
    props: {
      default: true,
      'event-menu': true,
      'admin-menu': false,
    },
    components: {
      default: AdminIndex,
      'admin-menu': AdminMenu,
    },
    meta: {
      requiresAuth: true,
    },
  },
];
