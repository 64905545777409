export default function(axios) {
  return {
    get: (eventId, pending = true, accepted = false, declined = false, refunded = false) => {
      return axios.get(`/refund/requests/${eventId}?pending=${pending}&accepted=${accepted}&declined=${declined}&refunded=${refunded}`)
        .then((response) => response.data);
    },
    generateSepa: (eventId, automaticSend = true) => {
      return axios.post(`/refund/requests/${eventId}/sepa?automaticSend=${automaticSend}`)
        .then((response) => response.data);
    },
    sendSepa: (eventId, batchId, formData) => {
      return axios.post(`/refund/requests/${eventId}/sepa/send/${batchId}`, formData)
        .then((response) => response.data);
    },
  };
}
