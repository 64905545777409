import useAccessControl from '@/composables/accessControl';
import MerchantSettingsToolbar from '@/components/layout/toolbar/MerchantSettingsToolbar.vue';

const MerchantNav = () => import('@/views/merchant/MerchantNav.vue');
const MerchantLegalSettings = () => import('@/views/merchant/MerchantLegalSettings.vue');
const MerchantReceiptSettings = () => import('@/views/merchant/MerchantReceiptSettings.vue');

const { isMerchant } = useAccessControl();

export default [
  {
    path: '/merchant-settings',
    name: 'merchant-settings',
    components: {
      default: MerchantNav,
      toolbar: MerchantSettingsToolbar,
    },
    props: {
      default: true,
      toolbar: true,
    },
    redirect: { name: 'merchant-legal-settings' },
    meta: {
      requiresAuth: true,
      visible: () => isMerchant.value,
      userMenu: {
        order: 4,
        icon: 'izp-merchant',
        transcode: 'userMenu.merchant',
      },
    },
    children: [
      {
        path: 'legal',
        name: 'merchant-legal-settings',
        components: {
          default: MerchantLegalSettings,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'receipts',
        name: 'merchant-receipt-settings',
        components: {
          default: MerchantReceiptSettings,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
