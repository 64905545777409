<template>
  <div :key="route.fullPath" class="col-sm-9 col-md-5 px-0">
    <VAutocomplete
      v-model="currentMerchant"
      :loading="merchantsList.loading"
      hide-details
      :label="capitalize(t('form.merchant.self', 1))"
      :items="merchantsListWithCurrent"
      :search-input.sync="search"
      no-data-text="Select a merchant ..."
      item-text="name"
      item-value="id"
      return-object
      no-filter
      outlined
      dense
      class="merchant-selector ml-2"
    >
      <template v-for="slotName in ['selection', 'item']" #[slotName]="data">
        <VListItemAvatar :key="`avatar_${slotName}`" :class="{'my-0': slotName === 'selection', 'mr-2': true}">
          <VAvatar :color="getColor(data.item.id)" size="24px">
            <VIcon color="white" small>
              izp-merchant
            </VIcon>
          </VAvatar>
        </VListItemAvatar>
        <VListItemContent :key="`content_${slotName}`" :class="{'py-0': slotName === 'selection'}">
          <VListItemTitle class="subtitle-2">
            {{ data.item.name }}
          </VListItemTitle>
          <VListItemSubtitle v-if="slotName === 'item'" class="grey--text lighten-4 font-weight-regular">
            {{ data.item.organization.vat_number }}
          </VListItemSubtitle>
        </VListItemContent>
      </template>
    </VAutocomplete>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router/composables';
import useMerchantStore from '@/stores/merchantStore';
import { storeToRefs } from 'pinia';

import { capitalize } from '@/util/Strings';
import useColors from '@/composables/colors';
import { useI18n } from 'vue-i18n-bridge';
import store from '@/vuex/store';
import type { PaginationParams } from '@/types/models';
import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';

const { t } = useI18n();
const route = useRoute();
const { getColor } = useColors();
const { merchant, merchantsList } = storeToRefs(useMerchantStore());
const { fetchList, setCurrentMerchant } = useMerchantStore();

const search = ref<string>('');

const currentMerchant = computed({
  get: () => merchant.value,
  set(val) {
    if (!val?.id) {
      return;
    }
    setCurrentMerchant(val.id);
  },
});

// Ensure currentMerchant is in autocomplete list
const merchantsListWithCurrent = computed(
  () => _uniqBy([...merchantsList.value.list, ...([merchant.value] ?? [])], 'id'),
);

const paginationParams = computed<PaginationParams>(() => ({
  offset: 0,
  limit: 10,
  search: search.value,
}));

onMounted(() => {
  store.dispatch('preferencesModule/setAppBarExtensionSettings', { tabsVisible: true });
  fetch();
});

const fetch = async() => fetchList(paginationParams.value);
const debouncedFetch = _debounce(fetch, 500, { trailing: true });

// Wait for list to be loaded
// to determine target MerchantID based on the current route
watch([route, merchantsList], ([route, list]) => {
  if (list.list.length === 0 || list.loading || list.errors) {
    return;
  }

  // Ignore merchantId route param if already currentMerchant
  if (route.params.merchantId && route.params.merchantId.toString() === currentMerchant.value?.id.toString()) {
    return;
  }

  // If merchantId route param is specified but differs from currentMerchant, use route param as currentMerchant
  if (route.params.merchantId &&
    (!currentMerchant.value?.id || currentMerchant.value?.id.toString() !== route.params.merchantId)
  ) {
    setCurrentMerchant(route.params.merchantId);
    return;
  }

  setCurrentMerchant(currentMerchant.value?.id ?? list?.list[0].id);
}, { deep: true });

watch(search, (val) => {
  if (val && val.length >= 2) {
    debouncedFetch();
  }
});

onBeforeUnmount(() => {
  store.dispatch('preferencesModule/setAppBarExtensionSettings', { tabsVisible: false });
});

</script>
