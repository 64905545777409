import type { List } from '@/types/stores';
import type { EventRef, Merchant, PaginationParams } from '@/types/models';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import api from '@/api';
import vuexStore from '@/vuex/store';

const merchants = ref<Merchant[]>([]);
const listLoading = ref<boolean>(false);
const merchantLoading = ref<boolean>(false);
const errors = ref<string[]|null>(null);

const useMerchantStore = defineStore('merchant', () => {
  const merchantsList = computed<List<Merchant>>(() => ({
    list: merchants.value,
    loading: listLoading.value,
    errors: errors.value,
  }));

  const currentUser = computed(() => vuexStore.getters['authModule/user']);

  const merchant = ref<Merchant|null>(null);

  async function fetchList(params: PaginationParams): Promise<void> {
    listLoading.value = true;
    errors.value = null;
    try {
      const { data } = await api.users.merchantsOf(currentUser.value.id).list(params);
      merchants.value = data;
    } catch (err: unknown) {
      errors.value = [
        'Impossible de charger la liste des commerçants',
        (err as Error)?.message ?? null,
      ];
    } finally {
      listLoading.value = false;
    }
  }

  async function fetchListForEvent(event: EventRef): Promise<void> {
    listLoading.value = true;
    errors.value = null;
    try {
      const { data } = await api.users.eventsOf(currentUser.value.id).listMerchants(event.id);
      merchants.value = data;
    } catch (err: unknown) {
      merchants.value = [];
      errors.value = [
        'Impossible de charger la liste des commerçants',
        (err as Error)?.message ?? null,
      ];
    } finally {
      listLoading.value = false;
    }
  }

  async function setCurrentMerchant(merchantId: string|number|null): Promise<void> {
    errors.value = null;
    if (!merchantId) {
      merchant.value = null;
      return;
    }

    merchantLoading.value = true;
    try {
      merchant.value = await api.merchants.get(merchantId);
    } catch (err: unknown) {
      merchant.value = null;
      errors.value = [
        'Impossible de charger le commerçant',
        (err as Error)?.message ?? null,
      ];
    } finally {
      merchantLoading.value = false;
    }
  }

  watch(() => merchant.value, (newVal, oldVal) => {
    if (newVal && newVal.id !== oldVal?.id) {
      // @TODO remove when VueX stores are not used anymore
      vuexStore.dispatch('setCurrentUserMerchant', newVal);
    }
  });

  return { merchantsList, merchant, fetchList, fetchListForEvent, setCurrentMerchant, listLoading, merchantLoading };
});

export default useMerchantStore;
