import i18n from '@/plugins/vue-i18n';

// eslint-disable-next-line camelcase
import { confirmed, required, email, between, is_not, regex, oneOf, image, alpha_num as alphaNum, max } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import IBAN from 'iban';
import Numbers from '@/util/Type/Numbers';

extend('confirmed', confirmed);
extend('between', between);
extend('is_not', is_not);
extend('image', image);
extend('alpha_num', alphaNum);

extend('required', {
  ...required,
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.required', { value: placeholders._value_ }) as string;
  },
});

extend('color', {
  ...regex,
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.color', { value: placeholders._value_ }) as string;
  },
});

extend('numeric', {
  validate: (value, args) => !isNaN(Numbers.parseLocaleNumber(value)),
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.numeric', { value: placeholders._value_ }) as string;
  },
});

extend('oneOf', {
  ...oneOf,
  message: (fieldName, placeholders) => {
    const validValues = Object.entries(placeholders)
      .filter(([index, _]) => !isNaN(parseInt(index)))
      .map(([_, val]) => val)
      .join(', ')
    ;
    return i18n.t('constraints.one_of', { value: placeholders._value_, validValues }) as string;
  },
});

extend('iban', {
  validate: (value, args) => IBAN.isValid(value),
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.iban', { value: placeholders._value_ }) as string;
  },
});

extend('percent', {
  validate: (value, args) => !isNaN(Numbers.parsePercentNumber(value)),
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.percent', { value: placeholders._value_ }) as string;
  },
});

extend('price', {
  validate: (value, args) => !isNaN(Numbers.parseLocaleNumber(value)),
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.price', { value: placeholders._value_ }) as string;
  },
});

extend('ean', {
  ...alphaNum,
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.ean', { value: placeholders._value_ }) as string;
  },
});

extend('email', {
  ...email,
  message: (fieldName, placeholders) => {
    return i18n.t('constraints.email', { value: placeholders._value_ }) as string;
  },
});

extend('max', {
  ...max,
  message: (fieldname, placeholders) => {
    return i18n.t('constraints.max', { value: placeholders.length }) as string;
  },
});
