import Qs from 'qs';

export default function(axios, eventId, posId) {
  return {
    list: (offset, limit, sort, filters) => {
      if (isNaN(limit) || isNaN(offset)) {
        throw new Error('Transactions offset & limit parameters should be integers.');
      }

      return axios.get(`/events/${eventId}/pos/${posId}/transactions`, {
        params: {
          offset,
          limit,
          sortBy: sort,
          filters,
        },
      })
        .then((response) => response.data);
    },
  };
}
