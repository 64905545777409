import AccessControl from '../security/AccessControl';
import CatalogToolbar from '@/components/layout/toolbar/CatalogToolbar.vue';

const MerchantCatalogNav = () => import('@/views/merchant/catalog/MerchantCatalogNav.vue');
const MerchantIllustration = () => import('@/views/merchant/catalog/illustration/MerchantIllustration.vue');
const MerchantItemModel = () => import('@/views/merchant/catalog/item/MerchantItemModel.vue');
const MerchantItemNav = () => import('@/views/merchant/catalog/item/MerchantItemNav.vue');
const MerchantItemView = () => import('@/views/merchant/catalog/item/MerchantItemView.vue');
const MerchantComposedItems = () => import('@/views/merchant/catalog/item/MerchantComposedItems.vue');
const MerchantItemStats = () => import('@/views/merchant/catalog/item/MerchantItemStats.vue');
const MerchantArchivedItems = () => import('@/views/merchant/catalog/item/MerchantArchivedItems.vue');

export default [
  {
    path: '/catalog',
    name: 'catalog',
    components: {
      default: MerchantCatalogNav,
      toolbar: CatalogToolbar,
    },
    props: {
      default: true,
      toolbar: true,
    },
    meta: {
      requiresAuth: true,
      visible: AccessControl.isMerchant,
    },
    children: [
      {
        path: 'merchants/:merchantId',
        name: 'catalog-merchant-index',
        redirect: { name: 'merchant-items', replace: true },
        component: MerchantItemModel,
        props: true,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isMerchant,
        },
      },
      {
        path: 'merchants/:merchantId/items',
        name: 'merchant-items',
        component: MerchantItemModel,
        props: true,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isMerchant,
        },
      },
      {
        path: 'merchants/:merchantId/composed-items',
        name: 'merchant-composed-items',
        component: MerchantComposedItems,
        props: true,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isMerchant,
        },
      },
      {
        path: 'merchants/:merchantId/items/:itemId',
        name: 'merchant-items-nav',
        props: true,
        redirect: { name: 'merchant-items-view' },
        component: MerchantItemNav,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isMerchant,
        },
        children: [
          {
            path: '',
            name: 'merchant-items-view',
            props: true,
            component: MerchantItemView,
            meta: {
              requiresAuth: true,
              visible: AccessControl.isMerchant,
            },
          },
          {
            path: 'sales',
            name: 'merchant-items-sales',
            props: true,
            component: MerchantItemStats,
            meta: {
              requiresAuth: true,
              visible: AccessControl.isMerchant,
            },
          },
        ],
      },
      {
        path: 'merchants/:merchantId/illustrations',
        name: 'merchant-illustrations',
        component: MerchantIllustration,
        props: true,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isMerchant,
        },
      },
      {
        path: 'merchants/:merchantId/archives',
        name: 'merchant-archived-items',
        component: MerchantArchivedItems,
        props: true,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isMerchant,
        },
      },
    ],
  },
];
