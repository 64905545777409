import moment from 'moment-timezone';
import translations from '@/translations';
import { isValidNumber, parse } from 'libphonenumber-js';
import { mapGetters } from 'vuex';

// Add mixin.
const globalMixins = {
  data: () => ({
    currentEventImage: false,
    i18n: null,
    confirmDialog: {
      show: true,
      message: '',
    },
  }),
  computed: {
    ...mapGetters('authModule', ['authenticated', 'permissions', 'roles']),
  },
  methods: {
    capitalizeHeaders(headers) {
      headers.forEach(function(y) {
        y.text = y.text.charAt(0).toUpperCase() + y.text.slice(1);
      });
      return headers;
    },
    getTrad(namespace, plural) {
      // Check if trad namespace/mapping exist before try to parse to avoid warnings and eventually errors.
      const trad = namespace;
      const cl = (window.localStorage.getItem('hubLocale') || 'fr');

      // If translation doesnt exist return namespace value.
      if (translations[cl] && translations[cl][namespace]) {
        return plural ? this.$tc(namespace, plural) : this.$t(namespace);
      }

      return trad;
    },
    confirm: function(message) {
      this.confirmDialog.message = message;
      this.confirmDialog.show = true;
    },
    getDescendantProp(obj, desc) {
      const arr = desc.split('.');
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    },
    arrayToText(list, joinOn = null, separator = '<br>') {
      if (!Array.isArray(list) || (Array.isArray(list) && list.length === 0)) {
        return '-';
      }

      if (joinOn) {
        let value = '';
        let result = '';
        list.forEach((element) => {
          value = this.getDescendantProp(element, joinOn);
          result += value ? `${value}${separator}` : '-';
        });

        return result;
      }

      return list.join(separator);
    },
    // is Valid Phone Number
    isValidPhoneNumber: (phoneObject) => {
      return phoneObject.country && phoneObject.phone
        ? isValidNumber(phoneObject.phone, phoneObject.country)
        : false;
    },
    // parse Phone Number
    parsePhoneNumber: (phone, country = null) => {
      return country ? parse(phone, country) : parse(phone);
    },
    // Return a boolean indicating user connection status.
    isAuth: function() {
      return this.authenticated;
    },
    hasClient: function() {
      // TODO: Add special condition to Admin users!
      return (
        this.authenticated &&
        this.permissions.clients &&
        this.permissions.clients.length >= 1
      );
    },
    hasMerchant: function() {
      // TODO: Add special condition to Admin users!
      return (
        this.authenticated &&
        this.permissions.merchants &&
        this.permissions.merchants.length >= 1
      );
    },
    getMerchants: function() {
      return this.hasMerchant() ? this.permissions.merchants : [];
    },
    getGenders(key) {
      const male = {
        id: 'm',
        name: this.$i18n.t('form.generic.male'),
      };

      const female = {
        id: 'f',
        name: this.$i18n.t('form.generic.female'),
      };

      if (key === 'm') {
        return male;
      }

      if (key === 'f') {
        return female;
      }

      return [male, female];
    },
    // Format date ...
    formatDate: (isoUtcDate, format) => {
      return moment(isoUtcDate).format(format || 'DD/MM/YYYY');
    },
    // Lang switcher ...
    langSwitcher: function(locale) {
      this.$i18n.locale = locale;
      this.$vuetify.lang.current = locale;
      window.localStorage.setItem('hubLocale', locale);
      window.localStorage.setItem('Language', locale);

      moment.locale(locale);
    },
    getCurrentLang: function() {
      return this.$i18n.locale === 'en' ? 'gb' : 'fr';
    },
    // Convert currency codes to currency symbols ...
    currencyCodeConverter(currencyName) {
      // Available currency code -> symbols ...
      const currencySymbols = {
        USD: '$', // US Dollar
        EUR: '€', // Euro
      };

      // Return the right symbol ...
      return currencySymbols[currencyName] || '';
    },
    safeParse(json, defaultReturn = null) {
      try {
        return JSON.parse(json);
      } catch (e) {
        return defaultReturn;
      }
    },
    maxNumber(a, b) {
      if (isNaN(a) || isNaN(b)) {
        return null;
      }

      return a > b ? a : b;
    },
  },
};

// Export.
export default globalMixins;
