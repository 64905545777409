import type { PlatformType } from '@/util/DeviceType/platforms';

export default function(axios) {
  return {
    get: (serial: string) => axios.get(`/terminals/${serial}`)
      .then((response) => response.data),
    list: (
      limit: number = -1,
      offset: number = 0,
      search: string|null = null,
      sortBy: string|null = 'serial',
      sortDesc: boolean = false,
      hideAffected: boolean = false,
      hideDisabled: boolean = true,
      platformFilters: PlatformType[] = [],
    ) => {
      return axios.get('/terminals', {
        params: {
          limit,
          offset,
          search,
          sortBy,
          sortDesc,
          hideAffected,
          hideDisabled,
          platforms: platformFilters,
        },
      }).then((response) => response.data);
    },
    create: (terminalData) => {
      return axios.post('/terminals', terminalData)
        .then((response) => response.data);
    },
    edit: (serial, terminalData) => {
      return axios.put(`/terminals/${serial}`, terminalData)
        .then((response) => response.data);
    },
    affect: (terminalSerial, constraints) => {
      return axios.post(`/terminals/${terminalSerial}/affect`, constraints)
        .then((response) => response.data);
    },
    stock: (terminalSerial) => {
      return axios.post(`/terminals/${terminalSerial}/stock`)
        .then((response) => response.data);
    },
  };
}
