const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const IllustrationIndex = () => import('@/views/admin/illustration/IllustrationIndex.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'illustrations',
    name: 'admin-illustrations',
    props: {
      default: true,
      'admin-menu': false,
    },
    components: {
      default: IllustrationIndex,
      'admin-menu': AdminMenu,
    },
    meta: {
      h2: 'form.user.self.2',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
  },
];
