<template>
  <span>
    <VTooltip
      v-if="canCopyId"
      v-bind="finalTooltipOptions"
      content-class="izp_tooltip"
    >
      <template #activator="{ on }">
        <span
          class="izp_clipboard-helper"
          v-on="on"
          @click.stop="copyToClipboard()"
        >
          <slot name="default" />
        </span>
      </template>
      <span class="copy-content black--text d-flex align-center">
        <VIcon color="accent" left x-small>
          mdi-security
        </VIcon>
        <slot name="copy-content">
          <span class="grey--text text--darken-2 px-2">{{ copyText }}:</span><b>{{ copyId }}</b>
        </slot>
        <VChip
          class="ml-2"
          :color="copied ? 'green' : 'white'"
          label
          :outlined="copied"
          x-small
        >
          <template v-if="copied">
            {{ 'Copié' }}&nbsp;&check;
          </template>
          <template v-else>
            <small class="grey--text text--darken-2">{{ t('general.copy_id') }}</small>
          </template>
        </VChip>
      </span>
    </VTooltip>
    <template v-else>
      <slot name="default" />
    </template>
  </span>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import _merge from 'lodash/merge';
import { useI18n } from 'vue-i18n-bridge';
import AccessControl from '@/security/AccessControl';

type Props = {
  copyId?: string | number,
  copyText?: string | number,
  tooltipOptions?: any,
};

const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  copyId: '',
  copyText: 'ID',
  tooltipOptions: null,
});

const baseTooltipOptions = {
  color: 'white',
  right: true,
  middle: true,
};

const copied = ref(false);
const canCopyId = computed(() => AccessControl.hasAdminSession() && props.copyId !== null);

const finalTooltipOptions = computed(() => _merge({}, baseTooltipOptions, props.tooltipOptions));

const copyToClipboard = async() => {
  if (!AccessControl.hasAdminSession()) {
    return;
  }

  await navigator.clipboard.writeText(props.copyId.toString());
  copied.value = true;

  await setTimeout(() => {
    copied.value = false;
  }, 2000);
};

</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
@import '@/sass/colors.scss';

.izp_tooltip {
  padding: 0.5rem;
  opacity: 1 !important;
  border: 1px solid $accent !important;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}

.izp_clipboard-helper:hover {
  display:inline-block;
  cursor: copy;
}
</style>
