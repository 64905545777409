export default function(axios, merchantId) {
  return {
    list() {
      return axios.get(`/merchants/${merchantId}/scheduled-tasks`)
        .then((response) => response.data);
    },
    get(taskId) {
      return axios.get(`/merchants/${merchantId}/scheduled-tasks/${taskId}`)
        .then((response) => response.data);
    },
    listTransportConfigs(params) {
      return axios.get(`/merchants/${merchantId}/transport-configs`, { params })
        .then((response) => response.data);
    },
    create(taskData) {
      return axios.post(`/merchants/${merchantId}/scheduled-tasks`, taskData)
        .then((response) => response.data);
    },
    edit(taskId, taskData) {
      return axios.put(`/merchants/${merchantId}/scheduled-tasks/${taskId}`, taskData)
        .then((response) => response.data);
    },
    deleteConfig(taskId) {
      return axios.delete(`/merchants/${merchantId}/scheduled-tasks/${taskId}`)
        .then((response) => response.data);
    },
  };
}
