<template>
  <IzpClipboardTooltip
    :copy-id="copyId"
  >
    <v-avatar
      :color="color"
      :size="size"
    >
      <slot name="default">
        <img
          v-if="src"
          :class="centerAlt"
          :src="src"
          :alt="alt|initialize|upper"
        >
        <span v-else>{{ alt|initialize|upper }}</span>
      </slot>
    </v-avatar>
  </IzpClipboardTooltip>
</template>

<script>
import globalMixins from '@/mixins';
import IzpClipboardTooltip from '@/components/izp/IzpClipboardTooltip.vue';

export default {
  name: 'IzpAvatar',
  components: { IzpClipboardTooltip },
  mixins: [globalMixins],
  props: {
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#999999',
    },
    size: {
      type: String,
      default: '32px',
    },
    copyId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    centerAlt() {
      return {
        'line-height': this.size,
        'text-align': 'center',
      };
    },
  },
};
</script>

<style scoped>

</style>
