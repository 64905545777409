import AccessControl from '@/security/AccessControl';
import adminTerminalRoute from '@/router/admin/terminal';
import adminClientRoute from '@/router/admin/client';
import adminOrganizationRoute from '@/router/admin/organization';
import adminMerchantRoute from '@/router/admin/merchant';
import adminEventRoute from '@/router/admin/event';
import adminCashlessRoute from '@/router/admin/cashless';
import adminBatchRoute from '@/router/admin/batch';
import adminIllustrationRoute from '@/router/admin/illustration';
import adminTransactionRoute from '@/router/admin/transaction';
import adminRoutes from '@/router/admin/admin';

const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const AdminManager = () => import('@/views/AdminManager.vue');

const AdminToolbar = () => import('@/components/layout/toolbar/AdminToolbar.vue');

export default [
  {
    path: '/admin',
    name: 'admin-manager',
    props: {
      default: true,
      'admin-menu': false,
    },
    redirect: { name: 'admin-events' },
    components: {
      default: AdminManager,
      toolbar: AdminToolbar,
      'admin-menu': AdminMenu,
    },
    beforeEnter: AccessControl.isAdminGuard,
    hidden: AccessControl.isAdmin(),
    meta: {
      requiresAuth: true,
    },
    children: [
      ...adminRoutes,
      ...adminTerminalRoute,
      ...adminClientRoute,
      ...adminOrganizationRoute,
      ...adminMerchantRoute,
      ...adminEventRoute,
      ...adminCashlessRoute,
      ...adminBatchRoute,
      ...adminIllustrationRoute,
      ...adminTransactionRoute,
    ],
  },
];
