import Meta from '@/Meta';
import axios from 'axios';
import Qs from 'qs';

import merchantsApi from './endpoints/merchants';
import eventsApi from './endpoints/events';
import transactionsApi from './endpoints/transactions';
import terminalsDeviceApi from './endpoints/terminal';
import securityApi from './endpoints/security';
import usersApi from './endpoints/users';
import clientsApi from './endpoints/clients';
import cashlessApi from './endpoints/cashless';
import dataApi from './endpoints/data';
import campaignsApi from './endpoints/campaigns';
import organizationsApi from './endpoints/organizations';
import batchesApi from './endpoints/batches';
import paymentsApi from './endpoints/payments';
import catalogApi from './endpoints/catalog';
import fleetApi from './endpoints/fleet';
import rolesApi from './endpoints/roles';

import security from './security';
import cancellation from './cancellation';

// Default axios client.
const axiosInstance = axios.create({
  baseURL: Meta.env.VITE_API_BASE_URL,
  timeout: 30000,
  paramsSerializer: {
    serialize: (params): string => Qs.stringify(params, { arrayFormat: 'brackets' }),
  },
});

security(axiosInstance);
cancellation(axiosInstance);

const api = {
  plainRequest: (config) => axiosInstance.request(config),
  transactions: transactionsApi(axiosInstance),
  merchants: merchantsApi(axiosInstance),
  terminals: terminalsDeviceApi(axiosInstance),
  events: eventsApi(axiosInstance),
  security: securityApi(axiosInstance),
  users: usersApi(axiosInstance),
  fleets: fleetApi(axiosInstance),
  clients: clientsApi(axiosInstance),
  cashless: cashlessApi(axiosInstance),
  data: dataApi(axiosInstance),
  campaigns: campaignsApi(axiosInstance),
  organizations: organizationsApi(axiosInstance),
  batches: batchesApi(axiosInstance),
  payments: paymentsApi(axiosInstance),
  catalog: catalogApi(axiosInstance),
  roles: rolesApi(axiosInstance),
};

export default api;
