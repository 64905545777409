const AuthCallback = () => import('@/views/auth/AuthCallback.vue');
const AuthLogin = () => import('@/views/auth/AuthLogin.vue');
const AuthPasswordReset = () => import('@/views/auth/AuthPasswordReset.vue');

export default [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: AuthLogin,
    meta: {
      title: 'Login - Izypay',
      requiresAuth: false,
    },
  },
  {
    path: '/auth/callback',
    name: 'auth-callback',
    component: AuthCallback,
    meta: {
      title: 'Login callback - Izypay',
      requiresAuth: false,
    },
  },
  {
    path: '/lost-password',
    name: 'auth-password-reset',
    component: AuthPasswordReset,
    meta: {
      requiresAuth: false,
    },
  },

  // Soft 301, Moved permanently
  {
    path: '/reset-password',
    redirect: { name: 'auth-password-reset' },
    meta: {
      requiresAuth: false,
    },
  },
];
