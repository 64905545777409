export default function(axios) {
  return {
    get(itemId) {
      return axios.get(`/item-models/${itemId}`)
        .then((response) => response.data);
    },
    getSales: (itemId) => {
      return axios.get(`/item-models/${itemId}/stats`)
        .then((response) => response.data);
    },
    post(itemData) {
      return axios.post('/item-models', itemData)
        .then((response) => response.data);
    },
    put(itemId, itemData) {
      return axios.put(`/item-models/${itemId}`, itemData)
        .then((response) => response.data);
    },
    archive(itemId, itemData) {
      return axios.put(`/item-models/${itemId}/archive`, itemData)
        .then((response) => response.data);
    },
    delete(itemId) {
      return axios.delete(`/item-models/${itemId}`)
        .then((response) => response.data);
    },
    history: (itemId) => {
      return axios.get(`/item-models/${itemId}/history`)
        .then((response) => response.data);
    },
  };
}
