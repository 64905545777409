export default function(axios, clientId, discountId) {
  return {
    list: () => {
      return axios.get(`/clients/${clientId}/discounts/${discountId}/application-rules`)
        .then((response) => response.data);
    },
    post: (applicationRuleData) => {
      return axios.post(`/clients/${clientId}/discounts/${discountId}/application-rules`, applicationRuleData)
        .then((response) => response.data);
    },
    put: (applicationRuleId, applicationRuleData) => {
      return axios.put(`/clients/${clientId}/discounts/${discountId}/application-rules/${applicationRuleId}`, applicationRuleData)
        .then((response) => response.data);
    },
    delete: (applicationRuleId) => {
      return axios.delete(`/clients/${clientId}/discounts/${discountId}/application-rules/${applicationRuleId}`)
        .then((response) => response.data);
    },
  };
}
