import { base64urlEncode, sha256 } from '@/util/Random';
import qs from 'qs';
import axios from 'axios';
import Meta from '@/Meta';

async function encodeCodeChallenge(codeVerifier) {
  return base64urlEncode(btoa(String.fromCharCode(...new Uint8Array(await sha256(codeVerifier)))));
}

export default class OAuthClient {
  constructor(options) {
    this.domain = options.domain;
    this.client_id = options.client_id;
    this.configuration = null;
  }

  async getConfiguration() {
    if (this.configuration !== null) {
      return this.configuration;
    }

    const response = await axios.get('.well-known/openid-configuration', {
      baseURL: this.domain,
    });
    this.configuration = response.data;

    return this.configuration;
  }

  async generateAuthorizeUrl({ redirectUri, state, codeVerifier }) {
    const config = await this.getConfiguration();
    const data = qs.stringify({
      response_type: 'code',
      client_id: Meta.env.VITE_OAUTH_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: '',
      state,
      code_challenge: await encodeCodeChallenge(codeVerifier),
      code_challenge_method: 'S256',
    });

    return `${config.authorization_endpoint}?${data}`;
  }

  async getToken({ redirectUri, code, codeVerifier }) {
    const config = await this.getConfiguration();
    const data = qs.stringify({
      grant_type: 'authorization_code',
      client_id: Meta.env.VITE_OAUTH_CLIENT_ID,
      redirect_uri: redirectUri,
      code,
      code_verifier: codeVerifier,
    });
    const response = await axios.post(config.token_endpoint, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data;
  }

  async getTokenFromRefreshToken({ refreshToken }) {
    const config = await this.getConfiguration();
    const data = qs.stringify({
      grant_type: 'refresh_token',
      client_id: Meta.env.VITE_OAUTH_CLIENT_ID,
      refresh_token: refreshToken,
      scope: '',
    });
    const response = await axios.post(config.token_endpoint, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    return response.data;
  }
}
