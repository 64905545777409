export default function(axios, merchantId) {
  return {
    listConfigs() {
      return axios.get(`/merchants/${merchantId}/banking/configs`)
        .then((response) => response.data);
    },
    get(configId) {
      return axios.get(`/merchants/${merchantId}/banking/configs/${configId}`)
        .then((response) => response.data);
    },
    createConfig(configData) {
      return axios.post(`/merchants/${merchantId}/banking/configs`, configData)
        .then((response) => response.data);
    },
    editConfig(configId, configData) {
      return axios.put(`/merchants/${merchantId}/banking/configs/${configId}`, configData)
        .then((response) => response.data);
    },
    deleteConfig(configId) {
      return axios.delete(`/merchants/${merchantId}/banking/configs/${configId}`)
        .then((response) => response.data);
    },
  };
}
