import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  timespan: {
    min: 0,
    max: 0,
    chartId: undefined,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
