import Qs from 'qs';

export default function(axios, eventId) {
  return {
    list: (offset = 0, limit = -1) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(
          new Error('Items offset & limit parameters should be integers.'));
      }
      return axios.get(`/events/${eventId}/items`, {
        params: {
          offset,
          limit: limit || -1,
        },
      }).then((response) => response.data);
    },
    get: (itemId) => axios.get(`/events/${eventId}/items/${itemId}`),
    history: (itemId) => {
      return axios.get(`/events/${eventId}/items/${itemId}/history`)
        .then((response) => response.data);
    },
    getStats: (itemId) => axios.get(`/events/${eventId}/items/${itemId}/stats`),
    delete: (itemId) => axios.delete(`/events/${eventId}/items/${itemId}`),
  };
}
