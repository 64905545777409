export default {
  toggleMenuPinned({ commit, state }, payload) {
    commit('SET_MENU_PINNED', payload !== undefined ? !!payload : !state.menu.pinned);
    if (state.menu.pinned) {
      commit('SET_MENU_VISIBLE', true);
    } else {
      commit('SET_MENU_VISIBLE', false);
    }
  },
  toggleMenuVisible({ commit, state }, payload) {
    commit('SET_MENU_VISIBLE', payload !== undefined ? !!payload : !state.menu.visible);
  },
  updateMobileState({ commit, getters, dispatch }, { screenWidth }) {
    if (screenWidth < 600 && !getters.isMobile) {
      commit('SET_IS_MOBILE', true);
      dispatch('toggleMenuPinned', false);
    } else if (screenWidth > 600 && getters.isMobile) {
      commit('SET_IS_MOBILE', false);
      dispatch('toggleMenuPinned', true);
    }
  },

  setPosPaymentMethodSettingsAlertOpened({ commit }, payload) {
    commit('SET_POS_PAYMENT_METHOD_SETTINGS_ALERT_OPENED', payload);
  },
  setAppBarExtensionSettings: ({ commit }, { tabsVisible, extensionHasLeftCol }) => {
    commit('SET_TABS_VISIBLE', tabsVisible);
    commit('SET_APPBAR_EXTENSION_LEFT_COL', extensionHasLeftCol);
  },
  setTableProperty({ commit }, { id, property, value }) {
    commit('SET_TABLE_PROPERTY', { id, property, value });
  },
  setRecentActivityDuration: ({ commit }, payload) => commit('SET_RECENT_ACTIVITY_DURATION', payload),
};
