<template>
  <div>
    <VMenu
      v-model="activateMenu"
      offset-y
      :open-on-click="false"
      :close-on-content-click="false"
      :loading="loading"
      class="elevation-1"
      rounded="t-0 b-xl"
      max-height="75vh"
    >
      <template #activator="{on}">
        <VTextField
          ref="textfield"
          v-model="search"
          :loading="loading"
          :outlined="alwaysVisible"
          :shaped="shaped"
          :append-icon="appendIcon"
          :dense="dense"
          :persistent-hint="alwaysVisible"
          hide-details
          placeholder="Rechercher un événement, un article ou un point de vente"
          persistent-placeholder
          clearable
          @keydown.esc="emitEsc"
          @focus="onFocus"
          v-on="on"
        >
          <template #prepend-inner>
            <slot name="prepend-inner" />
          </template>
        </VTextField>
      </template>

      <VProgressLinear
        v-if="loading"
        absolute
        color="primary"
        indeterminate
        height="2"
      />
      <VList dense>
        <template v-if="loading === false && events.length === 0 && items.length === 0 && pointsOfSales.length === 0">
          <div class="empty-placeholder d-flex px-4 flex-column align-content-center">
            <VIcon
              class="transparentize-60"
              color="gold"
              size="100"
            >
              izp-empty
            </VIcon>
            <span class="text-subtitle-2 text-center">{{ t('form.omnibar.no_result') }}</span>
            <span class="text-caption grey--text lighten-2 text-center">{{ t('form.omnibar.no_result_suggestion') }}</span>
          </div>
        </template>

        <template v-if="events.length > 0">
          <VSubheader>{{ capitalize(t('form.event.self', 2)) }}</VSubheader>

          <VListItem
            v-for="event in events"
            :key="event.id + event.name"
            :to="{ name: 'event-nav', params: { code: event.code, edition: event.edition } }"
            @click="closeMenu"
          >
            <VListItemAvatar class="mr-2">
              <VAvatar :color="getColor(event.id)" size="24px">
                <span class="caption">{{ initialize(event.name) }}</span>
              </VAvatar>
            </VListItemAvatar>

            <VListItemContent>
              <VListItemTitle v-html="partialEmphasis(event.name, search)" />
            </VListItemContent>
          </VListItem>
        </template>

        <template v-if="items.length > 0">
          <VDivider v-if="events.length > 0" />
          <VSubheader>{{ capitalize(t('form.item.self', 2)) }}</VSubheader>

          <VListItem
            v-for="item in items"
            :key="item.id + item.name"
            :to="{name: 'merchant-items-view', params: { itemId: item.id, merchantId: item.merchant.id }}"
            @click="closeMenu"
          >
            <VListItemAvatar class="mr-2">
              <template v-if="item.illustration === null">
                <VAvatar :color="item.color || '#F0F0F0'" size="24px">
                  <span class="caption">{{ initialize(item.name) }}</span>
                </VAvatar>
              </template>

              <VAvatar v-else :color="item.color || '#FAFAFA'" size="24px">
                <img :src="item.illustration?.path" :alt="item.name">
              </VAvatar>
            </VListItemAvatar>

            <VListItemContent>
              <VListItemTitle v-html="partialEmphasis(item.name, search)" />

              <VListItemSubtitle class="grey--text lighten-4 font-weight-regular white-space-normal">
                <IzpChipsGroup
                  :list="extractEventNames(item)"
                  :max-visible="1"
                />
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </template>

        <template v-if="pointsOfSales.length > 0">
          <VDivider v-if="items.length > 0 || events.length > 0" />

          <VSubheader>{{ capitalize(t('form.point_of_sales.self', 2)) }}</VSubheader>

          <VListItem
            v-for="pos in pointsOfSales"
            :key="pos.id + pos.name"
            :to="{ name: 'pos-nav', params: { code: pos.event.code, edition: pos.event.edition, posId: pos.id.toString() }}"
            @click="closeMenu"
          >
            <VListItemAvatar class="mr-2">
              <VAvatar :color="getColor(pos.id)" size="24px">
                <span class="caption">{{ initialize(pos.name) }}</span>
              </VAvatar>
            </VListItemAvatar>

            <VListItemContent>
              <VListItemTitle v-html="partialEmphasis(pos.name, search)" />

              <VListItemSubtitle class="grey--text lighten-4 font-weight-regular">
                {{ pos.event.name }}
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
        </template>
      </VList>
    </VMenu>
  </div>
</template>

<script setup lang="ts">
import type { Event, PointOfSales } from '@/types/models';
import type { Item } from '@/types/catalog';
import { computed, ref, watch } from 'vue';
import _debounce from 'lodash/debounce';
import { useI18n } from 'vue-i18n-bridge';
import { capitalize, initialize, partialEmphasis } from '@/util/Strings';
import api from '@/api';
import useColors from '@/composables/colors';
import IzpChipsGroup from '@/components/izp/IzpChipsGroup.vue';

type Props = {
  appendIcon?: string,
  dense?: boolean,
  alwaysVisible?: boolean,
};

type OmnibarItem = Item & {
  illustration?: {
    path: string,
  },
  merchant: {
    id: string,
  }
  pos_items: {
    event: Event,
  }[]
};

type POS = PointOfSales & { event: Event };

const { t } = useI18n();
const { getColor } = useColors();

withDefaults(defineProps<Props>(), {
  appendIcon: '',
  dense: false,
  alwaysVisible: false,
});

const emit = defineEmits<{(e: 'close'): void, (e: 'keydown', value: KeyboardEvent): void}>();

const events = ref<Event[]>([]);
const items = ref<OmnibarItem[]>([]);
const pointsOfSales = ref<POS[]>([]);
const loading = ref(false);
const search = ref('');
const activateMenu = ref(false);
const textfield = ref<HTMLInputElement>();

const shaped = computed<boolean>(() => activateMenu.value && search.value !== '');

const extractEventNames = (item: OmnibarItem) => {
  const names = [] as string[];

  item.pos_items.forEach((posItem) => {
    names[posItem.event.id] = posItem.event.name;
  });

  return Object.values(names);
};

const focus = () => {
  setTimeout(() => {
    textfield.value?.focus();
  }, 100);
};

const blur = () => {
  setTimeout(() => {
    textfield.value?.blur();
  }, 100);
};

const closeMenu = () => {
  setTimeout(() => {
    activateMenu.value = false;
    emit('close');
  }, 100);
};

const emitEsc = (ev: KeyboardEvent) => {
  emit('keydown', ev);
};

const onFocus = () => {
  if (search.value) {
    activateMenu.value = true;
  }
};

defineExpose({ blur, focus, closeMenu });

const fetchObjects = async() => {
  if (!activateMenu.value) {
    activateMenu.value = true;
  }

  if (!search.value || search.value.length < 2) {
    events.value = [];
    items.value = [];
    pointsOfSales.value = [];
    return;
  }

  loading.value = true;

  try {
    const response = await api.users.omnibar(search.value);
    events.value = response.events;
    items.value = response.items;
    pointsOfSales.value = response.points_of_sales;
  } catch (e) {
    console.error(e);
  }

  loading.value = false;
};

const debouncedOmnibar = _debounce(fetchObjects, 500, { leading: true });

watch(search, () => debouncedOmnibar());

</script>

<style lang="scss" scoped>
.white-space-normal {
  white-space: normal;
}

$menu-content-elevation: 4;

.empty-placeholder {
  max-width: 100%;
}
</style>
