<template>
  <span>
    <template v-for="(event, index) in list">
      <v-chip
        v-if="index < maxVisible || othersVisible"
        :key="`${index}-chip`"
        class="mr-1 my-1"
        pill
        small
        outlined
        :label="label"
        :link="!!linkList[index]"
        :to="linkList[index]"
        @click.native="linkList[index] ? $event.stopImmediatePropagation() : null"
      >
        <v-icon v-if="iconList[index]" class="mr-1 my-1" :color="iconColorList[index] ? iconColorList[index] : iconColor" small left>
          {{ iconList[index] }}
        </v-icon>

        <v-icon v-if="icon" class="mr-1 my-1" :color="iconColor" small>
          {{ icon }}
        </v-icon>

        <span>{{ event }}</span>

        <small v-if="secondaryList[index]" class="note">{{ secondaryList[index] }}</small>
      </v-chip>
    </template>

    <v-btn
      v-if="list.length > maxVisible"
      x-small
      plain
      text
      class="izypay-chip-group"
      @click.stop="toggleOthersVisibility"
    >
      <span v-if="othersVisible" class="primary--text">
        <span v-t="'form.item_model.mask_others'" />
      </span>

      <span v-else>(+{{ list.length - maxVisible }} <span v-t="'others'" />)</span>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'IzpChipsGroup',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    secondaryList: {
      type: Array,
      default: () => [],
    },
    linkList: {
      type: Array,
      default: () => [],
    },
    avatarList: {
      type: Array,
      default: () => [],
    },
    maxVisible: {
      type: Number,
      default: 3,
    },
    iconList: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'grey',
    },
    iconColorList: {
      type: Array,
      default: () => [],
    },
    label: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    othersVisible: false,
  }),
  methods: {
    toggleOthersVisibility() {
      this.othersVisible = !this.othersVisible;
    },
  },
};
</script>

<style scoped>
:deep(.v-chip--pill .v-avatar) {
  height: 24px !important;
  width: 24px !important;
}
</style>
