export default function(axios, eventId, posId) {
  return {
    put(name, value) {
      return axios.put(`/events/${eventId}/pos/${posId}/config`, { name, value })
        .then((response) => response.data);
    },
    delete(name) {
      return axios.delete(`/events/${eventId}/pos/${posId}/config`, { data: { name } })
        .then((response) => response.data);
    },
  };
}
