
export default function(axios) {
  return {

    impersonate(newUser) {
      return axios.get(`/users/${newUser}/token`)
        .then((response) => response.data);
    },

    resetPassword(username) {
      if (!username) {
        return Promise.reject(new Error('`username`should be set'));
      }

      return axios.post('/password/reset/request', {
        username,
      }, {
        noAuth: true,
      }).then((response) => response.data);
    },

    resetPasswordConfirm(token) {
      if (!token) {
        return Promise.reject(new Error('`token`should be set'));
      }

      return axios.get(`/password/reset/confirm?token=${token}`, {
        noAuth: true,
      })
        .then((response) => response.data);
    },

    passwordResetUpdate(token, firstPassword, secondPassword) {
      if (!token) {
        return Promise.reject(new Error('`token`should be set'));
      }
      return axios.post('/password/reset/confirm', {
        token,
        plainPassword: {
          first: firstPassword,
          second: secondPassword,
        },
      }, {
        noAuth: true,
      })
        .then((response) => response.data);
    },

    listGrants() {
      return axios.get('/grants').then((response) => response.data);
    },
  };
}
