import { ref } from 'vue';

type Dialog = {
  title: string,
  message: string,
  color: string,
  open: boolean,
  resolve: (value?: unknown) => void,
  reject: () => void,
};

const dialog = ref<Dialog>({
  title: '',
  message: '',
  color: 'primary',
  open: false,
  resolve: () => {},
  reject: () => {},
});

const showConfirm = ({ title, message, color }) => {
  return new Promise((resolve, reject) => {
    dialog.value = {
      title,
      message,
      color,
      open: true,
      resolve,
      reject,
    };
  });
};

const setResponse = (response: boolean) => {
  dialog.value.open = false;
  if (response) {
    dialog.value.resolve();
  } else {
    dialog.value.reject();
  }
};

export default function useDialog() {
  return {
    showConfirm,
    dialog,
    setResponse,
  };
}
