export default function(axios, clientId, discountId) {
  return {
    list: () => {
      return axios.get(`/clients/${clientId}/discounts/${discountId}/automation-rules`)
        .then((response) => response.data);
    },
    post: (automationRuleData) => {
      return axios.post(`/clients/${clientId}/discounts/${discountId}/automation-rules`, automationRuleData)
        .then((response) => response.data);
    },
    put: (automationRuleId, automationRuleData) => {
      return axios.put(`/clients/${clientId}/discounts/${discountId}/automation-rules/${automationRuleId}`, automationRuleData)
        .then((response) => response.data);
    },
    delete: (automationRuleId) => {
      return axios.delete(`/clients/${clientId}/discounts/${discountId}/automation-rules/${automationRuleId}`)
        .then((response) => response.data);
    },
  };
}
