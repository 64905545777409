import { prepareAbortableRequest } from '@/api/cancellation';
import { hash } from '@/api/helpers/hash';

export default function(axios, eventId) {
  return {
    list: (offset, limit, sort, filters, startDate, endDate, types = ['item_transaction'], min = null, max = null) => {
      if (isNaN(limit) || isNaN(offset)) {
        return Promise.reject(new Error('Transactions offset & limit parameters should be integers.'));
      }
      const requestHash = hash(`list_transactions_${JSON.stringify(types)}`).toString(16);
      const signal = prepareAbortableRequest(requestHash);

      return axios.get(`/events/${eventId}/transactions`, {
        signal,
        params: {
          startDate,
          endDate,
          offset,
          limit,
          sortBy: sort,
          filters,
          types,
          min,
          max,
        },
      })
        .then((response) => response.data);
    },
    getCountByAmount: (types) => {
      return axios.get(`/events/${eventId}/transactions/count-by-amount`, {
        params: {
          types,
        },
      })
        .then((response) => response.data);
    },
  };
}
