const AdminMenu = () => import('@/components/menu/AdminMenu.vue');

const AdminOrganizationIndex = () => import('@/views/admin/organization/OrganizationIndex.vue');
const AdminOrganizationView = () => import('@/views/admin/organization/OrganizationView.vue');
const OrganizationNav = () => import('@/views/admin/organization/OrganizationNav.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'organizations',
    name: 'admin-organizations',
    props: {
      default: true,
      'admin-menu': false,
    },
    components: {
      default: AdminOrganizationIndex,
      'admin-menu': AdminMenu,
    },
    meta: {
      h2: 'form.event.self',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
  },
  {
    path: 'organizations/:organizationId',
    name: 'admin-organization-nav',
    props: {
      default: true,
      'admin-menu': false,
    },
    redirect: { name: 'admin-organization-view' },
    components: {
      default: OrganizationNav,
      'admin-menu': AdminMenu,
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'admin-organization-view',
        props: {
          default: false,
          'admin-menu': false,
        },
        components: {
          default: AdminOrganizationView,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.event.self',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
    ],
  },
];
