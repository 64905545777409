import type { PaginationParams, PaginatedResult, Client } from '@/types/models';
import merchantApi from './merchants';
import userApi from './users';
import posTagsApi from './posTags';
import serviceAccountApi from './service-accounts';
import discountApi from './discounts';

export default function(axios) {
  return {
    list: (params: PaginationParams): PaginatedResult<Client> => {
      return axios.get('/clients', { params })
        .then((response) => response.data, { params });
    },
    get(clientId) {
      return axios.get(`/clients/${clientId}`)
        .then((response) => response.data);
    },
    async getClientEvents(clientId) {
      return axios.get(`/clients/${clientId}/events`)
        .then((response) => response.data);
    },
    getCashlessGroups(clientId) {
      return axios.get(`/clients/${clientId}/cashless_groups`)
        .then((response) => response.data);
    },
    merchantsOf(clientId) {
      return merchantApi(axios, clientId);
    },
    usersOf(clientId) {
      return userApi(axios, clientId);
    },
    posTagsOf(clientId) {
      return posTagsApi(axios, clientId);
    },
    listPoses: (clientId) => {
      return axios.get(`/clients/${clientId}/pos`)
        .then((response) => response.data);
    },
    listItems: (clientId) => {
      return axios.get(`/clients/${clientId}/items`)
        .then((response) => response.data);
    },
    serviceAccounts(clientId) {
      return serviceAccountApi(axios, clientId);
    },
    post: (clientData) => {
      return axios.post('/clients', clientData)
        .then((response) => response.data);
    },
    put: (clientId, clientData) => {
      return axios.put(`/clients/${clientId}`, clientData)
        .then((response) => response.data);
    },
    editLogo: (clientId, clientData) => {
      return axios.post(`/clients/${clientId}/custom-logo`, clientData)
        .then((response) => response.data);
    },
    discountsOf(clientId) {
      return discountApi(axios, clientId);
    },
    eventsTemplates: {
      list: () => {
        return axios.get('/admin/event-templates')
          .then((response) => response.data);
      },
      get: (templateId: number) => {
        return axios.get(`/admin/event-templates/${templateId}`)
          .then((response) => response.data);
      },
      create: (templateData) => {
        return axios.post('/admin/event-templates', templateData);
      },
      put: (templateId: number, templateData) => {
        return axios.put(`/admin/event-templates/${templateId}`, templateData);
      },
      delete: (templateId: number) => {
        return axios.delete(`/admin/event-templates/${templateId}`);
      },
    },
  };
}
