import merchantApi from '@/api/endpoints/organizations/merchants';
import clientApi from '@/api/endpoints/organizations/clients';

export default function(axios) {
  return {
    list: () => {
      return axios.get('/organizations')
        .then((response) => response.data);
    },
    get(organizationId) {
      return axios.get(`/organizations/${organizationId}`)
        .then((response) => response.data);
    },
    post: (organizationData) => {
      return axios.post('/organizations', organizationData)
        .then((response) => response.data);
    },
    put: (organizationId, organizationData) => {
      return axios.put(`/organizations/${organizationId}`, organizationData)
        .then((response) => response.data);
    },
    delete: (organizationId) => {
      return axios.delete(`/organizations/${organizationId}`)
        .then((response) => response.data);
    },
    merchantsOf(organizationId) {
      return merchantApi(axios, organizationId);
    },
    clientsOf(organizationId) {
      return clientApi(axios, organizationId);
    },
  };
}
