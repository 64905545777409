import AccessControl from '@/security/AccessControl';

const EventMenu = () => import('@/components/menu/EventMenu.vue');

const PosIndex = () => import('@/views/event/pos/PosIndex.vue');
const PosList = () => import('@/views/event/pos/PosList.vue');
const PosGrid = () => import('@/views/event/pos/PosGrid.vue');
const PosNav = () => import('@/views/event/pos/PosNav.vue');
const PosPaymentConfig = () => import('@/views/event/pos/PosPaymentConfig.vue');
const PosHome = () => import('@/views/event/pos/PosHome.vue');

export default [
  {
    path: 'pos',
    name: 'event-pos',
    redirect: { name: 'pos-list' },
    props: {
      default: true,
      'event-menu': true,
    },
    components: {
      default: PosIndex,
      'event-menu': EventMenu,
    },
    meta: {
      requiresAuth: true,
      visible: () => AccessControl.isClient() || AccessControl.isMerchant(),
      eventMenu: {
        order: 3,
        icon: 'mdi-storefront',
        transcode: 'form.point_of_sales.self',
      },
    },
    children: [
      {
        path: '',
        name: 'pos-list',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: PosList,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':posId',
        name: 'pos-nav',
        props: {
          default: true,
          'event-menu': true,
        },
        redirect: { name: 'pos-layout' },
        components: {
          default: PosNav,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'detail',
            name: 'pos-home',
            props: {
              default: true,
              'event-menu': true,
            },
            components: {
              default: PosHome,
              'event-menu': EventMenu,
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '',
            name: 'pos-layout',
            props: {
              default: true,
              'event-menu': true,
            },
            components: {
              default: PosGrid,
              'event-menu': EventMenu,
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'payments',
            name: 'pos-payment',
            props: {
              default: true,
              'event-menu': true,
            },
            components: {
              default: PosPaymentConfig,
              'event-menu': EventMenu,
            },
            beforeEnter: AccessControl.isClientGuard,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];
