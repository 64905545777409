export default function(axios) {
  return {
    accept: (paymentId) => {
      return axios.post(`/payments/${paymentId}/accept`).then((response) => response.data);
    },
    decline: (paymentId) => {
      return axios.post(`/payments/${paymentId}/decline`).then((response) => response.data);
    },
  };
}
