export default function(axios, eventId) {
  return {
    list() {
      return axios.get(`/events/${eventId}/service_accounts`)
        .then((response) => response.data);
    },
    post(serviceAccountData) {
      return axios.post(`/events/${eventId}/service_accounts`, serviceAccountData)
        .then((response) => response.data);
    },
    delete(serviceAccountId) {
      return axios.delete(`/events/${eventId}/service_accounts/${serviceAccountId}`)
        .then((response) => response.data);
    },
  };
}
