import posRoutes from '@/router/events/pos/';
import cashlessRoutes from '@/router/events/cashless/';
import stockRoutes from '@/router/events/stocks/';
import dataRoutes from '@/router/events/data/';
import settingsRoutes from '@/router/events/settings/';
import clickAndCollectRoutes from '@/router/events/click-and-collect';
import AccessControl from '@/security/AccessControl';
import EventToolbar from '@/components/layout/toolbar/EventToolbar.vue';

const EventMenu = () => import('@/components/menu/EventMenu.vue');
const IzpImpersonator = () => import('@/components/izp/IzpImpersonator.vue');

const ClientEvents = () => import('@/views/Events.vue');
const EventNav = () => import('@/views/EventNav.vue');
const EventActivity = () => import('@/views/event/EventActivity.vue');
const EventTerminalAffectations = () => import('@/views/event/EventTerminalAffectations.vue');

export default [
  {
    path: '/events/list',
    name: 'events',
    component: ClientEvents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events/:code/:edition',
    name: 'event-nav',
    redirect: { name: 'event-activity' },
    props: {
      default: true,
      toolbar: true,
      'event-menu': true,
    },
    components: {
      default: EventNav,
      toolbar: EventToolbar,
      'event-menu': EventMenu,
    },
    beforeEnter(to, from, next) {
      return AccessControl.impersonateIfAdmin(to, from, next);
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      ...posRoutes,
      ...cashlessRoutes,
      ...clickAndCollectRoutes,
      ...stockRoutes,
      ...dataRoutes,
      ...settingsRoutes,
      {
        path: 'activity',
        name: 'event-activity',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventActivity,
          'event-menu': EventMenu,
        },
        meta: {
          requiresAuth: true,
          eventMenu: {
            order: 1,
            icon: 'mdi-pulse',
            transcode: 'event.activity.title',
          },
        },
      },
      {
        path: 'terminals',
        name: 'event-terminal-affectations',
        props: {
          default: true,
          'event-menu': true,
        },
        components: {
          default: EventTerminalAffectations,
          'event-menu': EventMenu,
        },
        beforeEnter: AccessControl.isClientGuard,
        meta: {
          requiresAuth: true,
          visible: AccessControl.isClient,
          eventMenu: {
            order: 5,
            icon: 'izp-devices',
            transcode: 'form.terminal.self',
          },
        },
      },
      {
        path: 'impersonate',
        name: 'event-impersonate',
        beforeEnter: AccessControl.isAdminGuard,
        props: {
          default: false,
          'event-menu': true,
          impersonator: true,
        },
        components: {
          default: null,
          'event-menu': EventMenu,
          impersonator: IzpImpersonator,
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
