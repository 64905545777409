import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import dataModule from './modules/data';
import authModule from './modules/auth';
import snackbarModule from './modules/snackbar';
import preferencesModule from './modules/preferences';

// Password schema.
const ResetPasswordSchema = {
  steps: {
    active: 'username',
    username: false,
    token: false,
    password: false,
    success: false,
  },
  token: null,
};

const EventsSchema = {
  references: [],
  current: null,
};

const state = {
  resetPasswordState: ResetPasswordSchema,
  events: EventsSchema,
  currentMerchant: null,
  isLoadingMerchants: false,
  pendingImpersonation: null,
};

const mutations = {
  setEventsReferences(state, payload) {
    state.events.references = payload;
  },
  setCurrentUserEvent: (state, payload) => {
    if (payload?.id) {
      state.events.current = payload;
      return;
    }

    // Payload contains route params.
    const currentRouteParams = payload;
    const currentEvent = state.events.references.filter((event) => {
      // Get only the event that matches the current event code AND edition.
      if (
        currentRouteParams.code === event.code &&
        currentRouteParams.edition === event.edition
      ) {
        return event;
      }

      return false;
    });

    state.events.current = currentEvent ? currentEvent[0] : null;
  },
  clearCurrentUserEvent: (state, payload) => {
    state.events.current = null;
  },
  setCurrentUserMerchant: (state, payload) => {
    state.currentMerchant = payload;
  },
  setResetPasswordState: (state, payload) => {
    state.resetPasswordState = payload || state.resetPasswordState;
  },
  setIsLoadingMerchants: (state, payload) => {
    state.isLoadingMerchants = payload;
  },

  CLEAR: (state) => {
    state.resetPasswordState = ResetPasswordSchema;
    state.currentMerchant = null;
  },

  SET_CURRENT_EVENT_CONFIG: (state, payload) => {
    state.events.current.configs = payload;
  },

  SET_CURRENT_EVENT_NAME: (state, payload) => {
    state.events.current.name = payload;

    const currentEvent = state.events.current;
    const eventRefs = state.events.references;

    eventRefs.forEach((ref) => {
      if (ref.code === currentEvent.code && ref.edition === currentEvent.edition) {
        ref.name = payload;
      }
    });
  },

  SET_CURRENT_EVENT_DATES: (state, payload) => {
    state.events.current.dates = payload;

    const currentEvent = state.events.current;
    const eventRefs = state.events.references;

    eventRefs.forEach((ref) => {
      if (ref.code === currentEvent.code && ref.edition === currentEvent.edition) {
        ref.dates = payload;
      }
    });
  },

  SET_PENDING_IMPERSONATION: (state, payload) => {
    state.pendingImpersonation = payload;
  },
};

const actions = {
  setEventsReferences: ({ commit }, payload) => commit('setEventsReferences', payload),
  setResetPasswordState: ({ commit }, payload) => commit('setResetPasswordState', payload),
  setCurrentUserEvent: ({ commit }, payload) => {
    commit('setCurrentUserEvent', payload);
  },
  clearCurrentUserEvent: ({ commit }, payload) => {
    commit('clearCurrentUserEvent', payload);
  },
  setCurrentUserMerchant: ({ commit }, payload) => {
    commit('setCurrentUserMerchant', payload);
  },
  setIsLoadingMerchants: ({ commit }, payload) => {
    commit('setIsLoadingMerchants', payload);
  },
  clearAll({ commit, dispatch }, payload) {
    commit('CLEAR', null);
    return dispatch('clear', payload);
  },
  setCurrentEventConfig: ({ commit }, payload) => commit('SET_CURRENT_EVENT_CONFIG', payload),
  setCurrentEventName: ({ commit }, payload) => commit('SET_CURRENT_EVENT_NAME', payload),
  setCurrentEventDates: ({ commit }, payload) => commit('SET_CURRENT_EVENT_DATES', payload),
  setPendingImpersonation: ({ commit }, payload) => commit('SET_PENDING_IMPERSONATION', payload),
};

const getters = {
  getState: (state) => state,
  getResetPasswordState: (state) => state.resetPasswordState,
  getCurrentEvent: (state) => state.events.current,
  getCurrentMerchant: (state) => state.currentMerchant,
  isLoadingMerchants: (state) => state.isLoadingMerchants,
  getEventsReferences: (state) => state.events.references || [],
  getCurrentEventConfigs: (state) => state.events.current && state.events.current.configs,
  getPendingImpersonation: (state) => state.pendingImpersonation,
};

const plugins = [createPersistedState({
  key: 'izp',
  paths: ['authModule', 'preferencesModule'],
})];

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  actions,
  plugins,
  mutations,
  modules: {
    dataModule,
    authModule,
    snackbarModule,
    preferencesModule,
  },
  strict: import.meta.env.NODE_ENV !== 'production',
});
