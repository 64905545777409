const AdminMenu = () => import('@/components/menu/AdminMenu.vue');
const AdminTerminalToolbar = () => import('@/views/admin/terminal/TerminalToolbar.vue');
const AdminTerminalIndex = () => import('@/views/admin/terminal/TerminalIndex.vue');
const AdminTerminalView = () => import('@/views/admin/terminal/TerminalView.vue');
const TerminalNav = () => import('@/views/admin/terminal/TerminalNav.vue');
const AdminFleetIndex = () => import('@/views/admin/fleet/FleetsIndex.vue');
const AdminFleetView = () => import('@/views/admin/fleet/FleetView.vue');

const izypayTitlePrefix = 'izypay.title.baseline';

export default [
  {
    path: 'terminals',
    name: 'admin-terminals',
    props: {
      default: true,
      'admin-menu': false,
    },
    redirect: { name: 'admin-terminals-list' },
    components: {
      default: TerminalNav,
      toolbar: AdminTerminalToolbar,
      'admin-menu': AdminMenu,
    },
    meta: {
      h2: 'form.user.self,2',
      title: izypayTitlePrefix,
      requiresAuth: true,
      layout: 'AdminManager',
    },
    children: [
      {
        path: '',
        name: 'admin-terminals-list',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminTerminalIndex,
          toolbar: AdminTerminalToolbar,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.user.self,2',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
      {
        path: 'fleets',
        name: 'admin-terminals-fleets',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminFleetIndex,
          toolbar: AdminTerminalToolbar,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.user.self,2',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
      {
        path: 'fleets/:fleetId',
        name: 'admin-fleet-view',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminFleetView,
          toolbar: AdminTerminalToolbar,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.user.self,2',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
      {
        path: ':serial',
        name: 'admin-terminal-view',
        props: {
          default: true,
          'admin-menu': false,
        },
        components: {
          default: AdminTerminalView,
          toolbar: AdminTerminalToolbar,
          'admin-menu': AdminMenu,
        },
        meta: {
          h2: 'form.user.self,2',
          title: izypayTitlePrefix,
          requiresAuth: true,
          layout: 'AdminManager',
        },
      },
    ],
  },
];
